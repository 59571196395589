import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { timekeepingAction } from '../_core/actions/timekeeping';
import hearted from '../_templates/heart_red.png';
import heart from '../_templates/heart_grey.png';
import nocomment from '../_templates/comment_grayed.png';
import comments from '../_templates/comment_icon.png';

// import heart from '../_templates/heart.png'
// import comments from '../_templates/message.png'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { If, Then, Else, Switch, Case, When, Default } from 'react-if';
import Avatar from '../Components/Avatar/Avatar';
import './TimekeepingWidget.scss';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import useEffectWithPrevious from 'use-effect-with-previous';
// import { timekeepingServices } from "../_core/services/timekeeping";
import cc from 'classcat';
import { useMeasure } from 'react-use';
import { history } from '../_core/helpers/history';
// import { debounce } from '../_core/helpers/debounce';
import TimekeepingModal from './TimekeepingModal';
import { Route } from 'react-router-dom';

// import { postActions } from '../_core/actions/post';
import { commentService } from '../_core/services/comment';
import { reactionService } from '../_core/services/reaction';
import { debounce } from '../_core/helpers/debounce';
import { convertToObjectId } from './convertToObjectId';


// Remove Comment for pooling timesheet api
// const useRecursiveTimeout = (callback, delay) => {
//     const savedCallback = React.useRef(callback)

//     React.useEffect(() => {
//       savedCallback.current = callback
//     }, [callback])

//     React.useEffect(() => {
//       let id
//       function tick() {
//         const ret = savedCallback.current()

//         if (ret instanceof Promise) {
//           ret.then(() => {
//             if (delay !== null) {
//               id = setTimeout(tick, delay)
//             }
//           })
//         } else {
//           if (delay !== null) {
//             id = setTimeout(tick, delay)
//           }
//         }
//       }
//       if (delay !== null) {
//         id = setTimeout(tick, delay)
//         return () => id && clearTimeout(id)
//       }
//     }, [delay])
//   }

/**
 * 0 - Initial status
 * 1 - Error
 * 2 - Loading
 * 3 - Not registered
 * 4 - Not pragged in
 * 5 - Progress is < 50
 * 6 - Progress is >= 50 and <= 58
 * 7 - Progress is > 58
 * 8 - Complete and on time
 * 9 - Complete and under time
 * 10 - Complete and over time
 */
const statuses = {
    initial: 0,
    error: 1,
    loading: 2,
    notRegistered: 3,
    praggedOut: 4,
    progress_50: 5,
    progress_50_58: 6,
    progress_58: 7,
    onTime: 8,
    underTime: 9,
    overTime: 10,

    get inProgressStatuses() {
        return [
            this.progress_50,
            this.progress_50_58,
            this.progress_58
        ]
    },
    get completeStatuses() {
        return [
            this.onTime,
            this.underTime,
            this.overTime
        ]
    }
}

const formatDurationInSeconds = (seconds = 0) => (
    isNaN(seconds) ? '00:00:00' :
        new Date(1000 * seconds).toISOString().substr(11, 8)
);

const useProgressBasedOnDuration = (dependencies) => {
    useEffectWithPrevious(([previousDependencies]) => {
        if (
            dependencies.durationInSeconds === 0 ||
            isEmpty(dependencies.prag) ||
            previousDependencies.durationInSeconds === dependencies.durationInSeconds
        ) {
            return;
        }

        let progress = 0;

        if (statuses.inProgressStatuses.includes(dependencies.status)) {
            progress = (
                (
                    dependencies.durationInSeconds /
                    (
                        dependencies.policy.hoursRequired * 3600
                    )
                ) * 100
            );

            if (progress < 0) {
                return;
            }

            if (progress <= 100) {
                dependencies.setProgress(progress);
            } else {
                dependencies.setProgress(100);
            }
        } else {

        }
    }, [dependencies]);

    return null;
}

const useAvatarMargin = ({ avatarSize, progress, progressWidth, progressContainerWidth }) => {
    const [avatarMarginLeft, setAvatarMarginLeft] = React.useState(0);
    const [avatarMarginRight, setAvatarMarginRight] = React.useState(0);

    React.useEffect(() => {
        let marginLeft = progressWidth - (avatarSize / 2);
        let marginRight = progressContainerWidth - progressWidth - (avatarSize / 2);

        marginLeft = marginLeft > 0 ? marginLeft : 0;
        marginRight = marginRight > 0 ? marginRight : 0;

        setAvatarMarginLeft(marginLeft);
        setAvatarMarginRight(marginRight);
    }, [avatarSize, progress, progressWidth, progressContainerWidth]);

    return {
        avatarMarginLeft,
        avatarMarginRight
    }
}

const useAvatarPosition = ({ avatarSize, progress, progressWidth }) => {
    const [avatarPosition, setAvatarPosition] = React.useState(0);

    React.useEffect(() => {
        setAvatarPosition(progressWidth - (avatarSize / 2));
    }, [avatarSize, progress, progressWidth]);

    return avatarPosition;
}

const useDurationInSeconds = ({ prag, policy, status }) => {
    const [durationInSeconds, setDurationInSeconds] = React.useState(0);

    // Get the policy prag in datetime
    const policyPragInDateTime = React.useMemo(() => {
        if (!policy || !policy.pragInTime) return null;

        const currentTime = moment();
        const [hours, minutes, seconds] = policy.pragInTime.split(':');

        return currentTime.clone().set({
            hours: parseInt(hours, 10),
            minutes: parseInt(minutes, 10),
            seconds: parseInt(seconds, 10)
        });
    }, [policy]);

    // Set initial duration in seconds
    React.useEffect(() => {
        if (!prag || !prag.pragInAt || !policyPragInDateTime) return;

        if (moment().isSameOrBefore(policyPragInDateTime)) {
            // If current time is before or same as policy prag in datetime, don't set initial duration
            return;
        }

        let initialDurationInSeconds = moment().diff(moment(prag.pragInAt), 'seconds') - prag.earlyPragInDurationInSeconds;
        initialDurationInSeconds = Math.max(0, initialDurationInSeconds);

        setDurationInSeconds(initialDurationInSeconds);
    }, [prag, policyPragInDateTime, prag.earlyPragInDurationInSeconds]);

    // Set duration in seconds while in progress
    React.useEffect(() => {
        if (!prag || !prag.pragInAt || !statuses.inProgressStatuses.includes(status)) {
            return;
        }

        const interval = setInterval(() => {
            setDurationInSeconds(duration => duration + 1);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [prag, status]);

    // Reset duration in seconds after completion
    React.useEffect(() => {
        if (statuses.completeStatuses.includes(status)) {
            setDurationInSeconds(0);
        }
    }, [status]);

    return durationInSeconds;
}

const useProgress = (props) => {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        setProgress(props.progress);
    }, [props.progress]);

    return [progress, setProgress];
}

// Remove comment for pooling timesheet api
// const useFetchLatestPrag = ({ pragId, pragUpdatedAt, status, username}) => {  // eslint-disable-next-line
//     const dispatch = useDispatch();

//     // Timekeeping API polling. If there's a change on latest prag, dispatch an action to save the data
//     const fetchLatestPrag = React.useCallback(() => {
//         if([statuses.initial, statuses.loading].includes(status)){
//             return;
//         }

//         const fetch = async () => {
//             try {
//                 // Use "1" if username is undefined or empty string
//                 let result = await timekeepingServices.getByUser(username || "1");
//                 result = result?.allPrags[0] ?? {};

//                 if (
//                     result.updated_at !== pragUpdatedAt ||
//                     result.id !== pragId
//                 ) {
//                     dispatch(timekeepingAction.savePrag(result))
//                 }

//             } catch (error) {

//             }
//         }

//         fetch();
//     }, [dispatch, pragId, pragUpdatedAt, status, username]);
    
//     // fetchLatestPrag()
//     // console.log("fetch api")
//     useRecursiveTimeout(fetchLatestPrag, 60000);
// }

const useStatus = ({ progress, status: statusFromProps }) => {
    const [status, setStatus] = React.useState(0);

    // Set status on initial run
    React.useEffect(() => {
        setStatus(statusFromProps)
    }, [statusFromProps]);

    // Set status while in progress
    React.useEffect(() => {
        if (statuses.inProgressStatuses.includes(statusFromProps))
            switch (true) {
                case progress < 50:
                    setStatus(statuses.progress_50);
                    break;
                case (
                    progress >= 50 &&
                    progress <= 58
                ):
                    setStatus(statuses.progress_50_58);
                    break;
                case progress > 58:
                    setStatus(statuses.progress_58);
                    break;
                default:
                    break;
            }
    }, [setStatus, progress, statusFromProps]);

    return status;
}

const Complete = (props) => {
    const PragInAvatar = React.useCallback(() => (
        <div className="avatar-container">
            <Avatar
                border="solid 4px white"
                size={props.avatarSize}
                src={[process.env.REACT_APP_PRAGGER_API, props.prag.pragInImg].join('/')}
            />
        </div>
    ), [props.avatarSize, props.prag.pragInImg]);

    const PragOutAvatar = React.useCallback(() => (
        <div className="avatar-container">
            <Avatar
                border="solid 4px white"
                size={props.avatarSize}
                src={[process.env.REACT_APP_PRAGGER_API, props.prag.pragOutImg].join('/')}
            />
        </div>
    ), [props.avatarSize, props.prag.pragOutImg])

    return (
        <>
            <PragInAvatar />
            <div className="time">
                <p>
                    <span>
                        {
                            formatDurationInSeconds(props.prag.hoursWorkedDurationInSeconds)
                        }
                    </span>
                    {' of '}
                    {
                        formatDurationInSeconds(props.policy.hoursRequired * 3600)
                    }
                </p>
                <p
                    className={cc({
                        'red': [statuses.overTime, statuses.underTime].includes(props.status),
                        'green': props.status === statuses.onTime
                    })}
                >
                    {
                        formatDurationInSeconds(
                            props.prag.underTimeDurationInSeconds ||
                            props.prag.overTimeDurationInSeconds
                        )
                    }
                    <If condition={props.status === statuses.underTime}>
                        <Then>
                            {` UT`}
                        </Then>
                        <Else>
                            {` OT`}
                        </Else>
                    </If>
                </p>
            </div>
            <PragOutAvatar />
        </>
    );
}

const InProgress = (props) => {
    const PragInAvatar = React.useCallback(({ style }) => (
        <div className="avatar-container" style={style}>
            <Avatar
                border={
                    [
                        'solid',
                        '4px',
                        props.status === statuses.progress_50 ?
                            'white' :
                            '#009900'
                    ].join(' ')
                }
                size={props.avatarSize}
                src={[process.env.REACT_APP_PRAGGER_API, props.prag.pragInImg].join('/')}
            />
        </div>
    ), [props.avatarSize, props.prag.pragInImg, props.status]);

    return (
        <Switch>
            <Case condition={props.status === statuses.progress_50}>
                <PragInAvatar style={{ marginLeft: `${props.avatarMarginLeft}px` }} />
                <div className="time ss">
                    <p className="duration">
                        {
                            formatDurationInSeconds(props.durationInSeconds)
                        }
                    </p>
                    <p>
                        {
                            'of '
                        }
                        {
                            formatDurationInSeconds(props.policy.hoursRequired * 3600)
                        }
                    </p>
                </div>
            </Case>
            <Case condition={props.status === statuses.progress_50_58}>
                <div className="time ff">
                    <p className="duration">
                        {
                            formatDurationInSeconds(props.durationInSeconds)
                        }
                    </p>
                </div>
                <PragInAvatar style={{ transform: `translateX(${props.avatarPosition}px)` }} />
                <div className="time ll">
                    <p>
                        {'of '}
                        {
                            formatDurationInSeconds(props.policy.hoursRequired * 3600)
                        }
                    </p>
                </div>
            </Case>
            <Case condition={props.status === statuses.progress_58}>
                <div className="time jj">
                    <p className="duration">
                        {
                            formatDurationInSeconds(props.durationInSeconds)
                        }
                    </p>
                    <p>
                        {'of '}
                        {
                            formatDurationInSeconds(props.policy.hoursRequired * 3600)
                        }
                    </p>
                </div>
                <PragInAvatar style={{ marginRight: props.avatarMarginRight }} />
            </Case>
        </Switch>

    )
}

const viewPrag = (props) => {
    const pragObjectId = convertToObjectId(props.prag);
    if(window.location.pathname.includes('/profile')){
        history.push(`/profile/${props.prag.pragOutImg ? "clockedOut" : "clockedIn"}/${pragObjectId}`);
    }    
};
    
const PublicView = (props) => {
    // const dispatch = useDispatch();
    const checkRC = props.getReactComment;
    const [isReacted, setIsReacted] = React.useState(false);
    const [reactCount, setReactCount] = React.useState(0);
    const [isCommented, setIsCommented] = React.useState(false);
    const [commentCount, setCommentCount] = React.useState(0);
    const pragObjectId = convertToObjectId(props?.prag || "");
    var updateReact = checkRC?.checkReact;
    // var updateComment = checkRC?.checkComment;

    const getReactCommentCounts = () => {
        const getOriginId = pragObjectId;
		try{
			commentService.getByOrigin(getOriginId, 1, 999999).then(res=>{
                
				if(res.page){
					setCommentCount(res.total); 
					const comments = res.items;
					const user = JSON.parse(localStorage.getItem('userCredentials'));
					const currentUserId = user && user.user._id;
					const userCommented = comments.filter(c=> c.poster === currentUserId);
					if(userCommented.length > 0){
                        setIsCommented(true);
                        // updateComment = undefined;
					}
				}	
			});
			reactionService.getByOrigin(getOriginId, 1, 999999).then(res=>{
				if(res.page){
					setReactCount(res.total);
                    const reactions = res.items;
					const user = JSON.parse(localStorage.getItem('userCredentials'));
					const currentUserId = user && user.user._id;
					const userReacted = reactions.filter(c=> c.poster === currentUserId);
					if(userReacted.length > 0){
                        setIsReacted(true);
                        updateReact = undefined;
					}else{
                        setIsReacted(false);
                        updateReact = undefined;
					}
				}
			}).catch(error=>{console.log(error)});
		}catch(err){
			console.log(err);
		}
    }
    
    React.useEffect(()=>{
        getReactCommentCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    if(updateReact !== undefined){
        getReactCommentCounts();
    }

    const sendReact = (e) => {
		setIsReacted(isReacted => !isReacted);
        // todo: update react count after clicked

        const getOriginId = pragObjectId;

		const submitReaction = {
			originId : getOriginId,
			type: "1"
        };
        
        try{
            reactionService.postReaction(submitReaction).then((res)=>{
                if(res.success){
                    getReactCommentCounts();
                }
            }).catch(err=>{console.log(err)})
        }catch(err){
            console.log(err);
        }

	};

    return(
        <div className="details-container">
            <div className="clocked-in">
                <div className="avatar-container">
                    <Avatar
                        border="solid 4px #009900"
                        size={props.avatarSize}
                        src={
                            [
                                process.env.REACT_APP_PRAGGER_API,
                                (
                                    statuses.completeStatuses.includes(props.status) ?
                                        props.prag.pragOutImg :
                                        props.prag.pragInImg
                                )
                            ].join('/')
                        }
                    />
                </div>
                <div className="details">
                    <h6>
                        <b>CLOCKED {statuses.completeStatuses.includes(props.status) ? 'OUT' : 'IN'}</b>
                    </h6>
                    <div className="stats">
                        <div onClick={debounce((e)=> sendReact(e, props), 1000)}>
                            <img src={isReacted ? hearted : heart} alt="heart" />
                            <p>{reactCount || 0}</p>
                        </div>
                        <div onClick={ ()=> window.location.pathname.includes("/profile") ? viewPrag(props) : ""}>
                            <img src={isCommented ? comments : nocomment} alt="comments"/>
                            <p>{commentCount || 0}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const TimekeepingWidget = (props) => {
    const dispatch = useDispatch();
    const [progressRef, { width: progressWidth }] = useMeasure();
    const [containerRef, { width: progressContainerWidth }] = useMeasure();
    const [getReactComment, setReactComment] = React.useState({});

    const [progress, setProgress] = useProgress({
        progress: props.progress
    });

    const status = useStatus({
        progress,
        status: props.status
    });

    const durationInSeconds = (
        useDurationInSeconds({
            currentDateAndTime: props.currentDateAndTime,
            policy: props.policy,
            prag: props.prag,
            status
        })
    );

    const avatarSize = React.useMemo(() => {
        // console.log(progressContainerWidth)
        switch(true) {
            case progressContainerWidth <= 240:
                return 35;
            case progressContainerWidth > 240 &&  progressContainerWidth <= 260:
                return 50;
            case progressContainerWidth > 260 && progressContainerWidth <= 320:
                return 60;
            default:
                return 70;
        }
    }, [progressContainerWidth]);

    const avatarPosition = useAvatarPosition({ avatarSize, progress, progressWidth });

    const { avatarMarginLeft, avatarMarginRight } = useAvatarMargin({
        avatarSize,
        progress,
        progressWidth, progressContainerWidth
    })

    useProgressBasedOnDuration({
        durationInSeconds,
        currentDateAndTime: props.currentDateAndTime,
        prag: props.prag,
        policy: props.policy,
        setProgress,
        status
    });

    // Remove pooling calling API
    // useFetchLatestPrag({ pragId: props.prag.id, pragUpdatedAt: props.prag.updated_at, username: props.username, status });

    React.useEffect(() => {
        // Use "1" if username is undefined or empty string
        dispatch(timekeepingAction.get(props.username || "1"));
    }, [dispatch, props.username]);

    const reactCommentStats = (rc) => {
        setReactComment(rc);
    }

    return (
        <div className="timekeeping widget">
            <div className="header">
                <If condition={[statuses.loading, statuses.initial].includes(status)}>
                    <Then>
                        <SkeletonTheme color="rgb(225, 225, 225)" highlightColor="rgb(240, 240, 240)">
                            <Skeleton width={110} height={17} />
                        </SkeletonTheme>
                    </Then>
                    <Else>
                        <h6>Timekeeping</h6>
                    </Else>
                </If>
                <div style={{ height: 40 }} />
            </div>
            <div className="body card z-depth-0">
                <div className="container" ref={containerRef} avatar-size={avatarSize}>
                    <If condition={[statuses.loading, statuses.initial].includes(status)}>
                        <Then>
                            {/* TODO: Create loading skeleton */}
                        </Then>
                        <Else>
                            <Switch>
                                <Case condition={status === statuses.error}>
                                    <p>Something went wrong</p>
                                </Case>
                                <Case condition={status === statuses.praggedOut}>
                                    <p>
                                        <If condition={props.public === true}>
                                            <Then>
                                                User is currently pragged out
                                            </Then>
                                            <Else>
                                                You are currently pragged out
                                            </Else>
                                        </If>
                                    </p>
                                </Case>
                                <Case condition={status === statuses.notRegistered}>
                                    <p>
                                        <If condition={props.public === true}>
                                            <Then>
                                                User is currently not registered in Pragger
                                            </Then>
                                            <Else>
                                                You are currently not registered in Pragger
                                            </Else>
                                        </If>
                                    </p>
                                </Case>
                                <Default>
                                    <If condition={props.public === true || window.location.pathname.includes('/profile')}>
                                        <Then>
                                            <If
                                                condition={
                                                    [
                                                        ...statuses.inProgressStatuses,
                                                        ...statuses.completeStatuses
                                                    ].includes(status)
                                                }>
                                                <Then>
                                                    <PublicView {...props}  {...{ avatarSize, status}} getReactComment={getReactComment}/>
                                                </Then>
                                                <Else>
                                                    <p>
                                                        User is currently pragged out
                                                    </p>
                                                </Else>
                                            </If>
                                        </Then>
                                        <Else>
                                            <div className="progress-bar">
                                                <div
                                                    className={cc([
                                                        'container',
                                                        {
                                                            'under-time': status === statuses.underTime
                                                        }
                                                    ])}
                                                >
                                                    <div className="value" ref={progressRef} style={{ width: `${progress}%` }} />
                                                </div>
                                            </div>
                                            <div className="details-container">
                                                <div data-status={status}>
                                                    <Switch>
                                                        <Case condition={statuses.inProgressStatuses.includes(status)}>
                                                            <InProgress
                                                                {...props}
                                                                avatarMarginLeft={avatarMarginLeft}
                                                                avatarMarginRight={avatarMarginRight}
                                                                avatarPosition={avatarPosition}
                                                                avatarSize={avatarSize}
                                                                durationInSeconds={durationInSeconds}
                                                                status={status}
                                                            />
                                                        </Case>
                                                        <Case condition={statuses.completeStatuses.includes(status)}>
                                                            <Complete
                                                                {...props}
                                                                avatarSize={avatarSize}
                                                                durationInSeconds={durationInSeconds}
                                                                status={status}
                                                            />
                                                        </Case>
                                                    </Switch>
                                                </div>
                                            </div>
                                        </Else>
                                    </If>

                                </Default>
                            </Switch>
                        </Else>
                    </If>
                </div>
                <When
                    condition={
                        [
                            ...statuses.completeStatuses,
                            ...statuses.inProgressStatuses
                        ].includes(status)
                    }
                >
                    <div className="time-details" style={{marginLeft: window.location.pathname.includes('/profile') ? "90px" : "0"}}>
                        <p>
                            Clocked in at:&nbsp;
                        <span>
                                {
                                    moment(props.prag.pragInAt).format('hh:mm:ss A')
                                }
                            </span>
                        </p>
                        <When condition={!!props.prag.pragOutAt}>
                            <p>
                                Clocked out at:&nbsp;
                            <span>
                                    {
                                        moment(props.prag.pragOutAt).format('hh:mm:ss A')
                                    }
                                </span>
                            </p>
                        </When>
                    </div>
                </When>

            </div>
            <Route
				exact
				path={ `/profile/${props.prag.pragOutAt ? "clockedOut" : "clockedIn"}/:pragId` }
				render={ () => <TimekeepingModal getClocks={props} checkReactComment={reactCommentStats}/> }
			/>
        </div>
    );
}

const mapStateToProps = (state) => {
    const timekeeping = state.timekeeping.self;
    const policyPrags = timekeeping?.data?.[0]?.prags[0] ?? {};
    console.log(policyPrags)

    // TODO: Change to server-side moment
    const currentDateAndTime = moment().toISOString();

    // TODO: Get from Policy API
    const policy = {
        pragInTime: policyPrags.pragFlexiInStart !== "" ? policyPrags.pragFlexiInStart : policyPrags.pragStartTime,
        hoursRequired: policyPrags.requiredrt
    };

    console.log(policy)

    const timekeepingWidgetProps = {
        currentDateAndTime,
        prag: {},
        policy,
        status: 0,
    };

    // Very hacky
    if (
        (state.timekeeping.error && state.timekeeping.error.includes('404')) ||
        state.timekeeping.timekeeping?.data.length === 0
    ) {
        timekeepingWidgetProps.status = statuses.notRegistered;

        return timekeepingWidgetProps;
    } else if (state.timekeeping.error) {
        timekeepingWidgetProps.status = statuses.error;

        return timekeepingWidgetProps;
    }

    if (state.timekeeping.loading) {
        timekeepingWidgetProps.status = statuses.loading;

        return timekeepingWidgetProps;
    }

    timekeepingWidgetProps.prag = timekeeping?.data?.[0]?.prags[0] ?? {};
    timekeepingWidgetProps.teamName = timekeeping?.team?.name || '';

    const dateOfPrag = moment(timekeepingWidgetProps.prag.pragInAt).format('YYYY-MM-DD');

    // Used for checking if actual prag in time is EARLIER than policy prag in time, results to 0 if otherwise
    let earlyPragInDurationInSeconds = (
        moment(`${dateOfPrag} ${timekeepingWidgetProps.policy.pragInTime}`)
            .diff(moment(timekeepingWidgetProps.prag.pragInAt), 'seconds')
    );

    earlyPragInDurationInSeconds = Math.sign(earlyPragInDurationInSeconds) === 1 ? earlyPragInDurationInSeconds : 0;

    timekeepingWidgetProps.prag.earlyPragInDurationInSeconds = earlyPragInDurationInSeconds;

    const hoursRequiredInSeconds = timekeepingWidgetProps.policy.hoursRequired * 3600;

    // Check if pragged out, on time, under time, or over time
    if (timekeepingWidgetProps.prag.pragOutAt) {
        const hoursWorkedDurationInSeconds = (
            moment(timekeepingWidgetProps.prag.pragOutAt)
                .diff(timekeepingWidgetProps.prag.pragInAt, 'seconds') -
                earlyPragInDurationInSeconds
        );

        timekeepingWidgetProps.prag.hoursWorkedDurationInSeconds = hoursWorkedDurationInSeconds;

        let overTimeDurationInSeconds = hoursWorkedDurationInSeconds - hoursRequiredInSeconds;
        overTimeDurationInSeconds = Math.sign(overTimeDurationInSeconds) === 1 ? overTimeDurationInSeconds : 0;

        switch (true) {
            // If prags are empty, or user's last prag in is before the day today
            case moment(dateOfPrag).isSame(currentDateAndTime, 'day') === false:
                timekeepingWidgetProps.status = statuses.praggedOut;
                break;

            // If under time
            case hoursRequiredInSeconds > hoursWorkedDurationInSeconds:
                timekeepingWidgetProps.prag.underTimeDurationInSeconds = hoursRequiredInSeconds - hoursWorkedDurationInSeconds;

                timekeepingWidgetProps.status = statuses.underTime;

                break;

            // If over time
            case overTimeDurationInSeconds > 0:
                timekeepingWidgetProps.prag.overTimeDurationInSeconds = overTimeDurationInSeconds;
                timekeepingWidgetProps.status = statuses.overTime;

                break;
            default:
                timekeepingWidgetProps.status = statuses.onTime;

                break;
        }

        timekeepingWidgetProps.progress = (hoursWorkedDurationInSeconds / hoursRequiredInSeconds) * 100

        if(timekeepingWidgetProps.progress > 100 ) {
            timekeepingWidgetProps.progress = 100;
        }

        return timekeepingWidgetProps;
    }

    /**
     * To get percentage of hours worked / required working hours:
     * (moment(current_time).diff(moment(prag_in_at), 'seconds') /
     * moment(prag_in_at).add(hoursRequired, 'hours').diff(moment(prag_in_at), 'seconds')) *
     * 100
     */
    if (timekeepingWidgetProps.prag.pragInAt) {

        const hoursWorkedDurationInSeconds = (
            moment(currentDateAndTime)
                .diff(timekeepingWidgetProps.prag.pragInAt, 'seconds') -
                earlyPragInDurationInSeconds
        );

        timekeepingWidgetProps.progress = (hoursWorkedDurationInSeconds / hoursRequiredInSeconds) * 100

        if(timekeepingWidgetProps.progress > 100 ) {
            timekeepingWidgetProps.progress = 100;
        } else if(timekeepingWidgetProps.progress < 0 ) {
            timekeepingWidgetProps.progress = 0;
        }

        switch (true) {
            case timekeepingWidgetProps.progress < 50:
                timekeepingWidgetProps.status = statuses.progress_50;
                break;
            case (
                timekeepingWidgetProps.progress >= 50 &&
                timekeepingWidgetProps.progress <= 58
            ):
                timekeepingWidgetProps.status = statuses.progress_50_58;
                break;
            case timekeepingWidgetProps.progress > 58:
                timekeepingWidgetProps.status = statuses.progress_58;
                break;
            default:
                break;
        }
    } else {

        timekeepingWidgetProps.status = statuses.praggedOut;
    }

    return timekeepingWidgetProps;
}

export default connect(mapStateToProps)(React.memo(TimekeepingWidget, (prevProps, nextProps) => {
    console.log(prevProps)
    console.log(nextProps)
    return !(
        prevProps.username !== nextProps.username ||
        prevProps.prag?.id !== nextProps.prag?.id ||
        prevProps.status !== nextProps.status ||
        prevProps.prag?.updatedAt !== nextProps.prag?.updatedAt
    )
}));
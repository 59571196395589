import React from 'react';
import cc from 'classcat';
import { connect, useDispatch } from 'react-redux';

import { expActions } from '../_core/actions/exp';
// import { pageActions } from '../_core/actions/page';

import { UserDashboardContext } from '../UserDashboard/UserDashboard';

import AttributesWidget from '../AttributesWidget/AttributesWidget';
import CurrentLevelWidget from '../CurrentLevelWidget/CurrentLevelWidget';
import { CurrentStats } from '../CurrentStats/CurrentStats'
import TimekeepingWidget from '../TimekeepingWidget/TimekeepingWidget';
import Feeds from '../Feeds/Feeds';
import ProfileWidget from '../ProfileWidget/ProfileWidget';
import TeamWidget from '../TeamWidget/TeamWidget';
import WorkstatusWidget from '../WorkStatusWidget/WorkstatusWidget';
import SelfServiceWidget from '../SelfServiceWidget/SelfServiceWidget'
import { PostWidget } from '../PostWidget/PostWidget'
import Footer from '../Footer/Mainfooter.js';

import "./dashboard.scss";

const Dashboard = (props) => {
    const dispatch = useDispatch();

    const device = React.useContext(UserDashboardContext).device;
    const secret = JSON.parse(localStorage.getItem("secretCredentials"));
    
    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));

        dispatch(expActions.getExp(secret ? secret._id : user.user_id));
        // dispatch(pageActions.getAttributes(undefined, true, false));
    }, [dispatch]);// eslint-disable-line 


    return (
        <div className={device === 'desktop' ? "row dashboard desktop-footer" : "row dashboard"}>
            <div
                className={cc([
                    'sub-panel',
                    'col',
                    's12',
                    'l4',
                    device
                ])}
            >
                <div >
                    <ProfileWidget />
                    <WorkstatusWidget 
                        userId={secret ? secret._id : props.authentication.user?.user?._id}
                    />
                    <TimekeepingWidget username={secret ? secret.praggerUsername : props.authentication?.user?.user?.praggerUsername} />
                    <AttributesWidget
                        userId={secret ? secret._id : props.authentication.user?.user?._id}
                    />
                    <TeamWidget 
							team={secret ? secret.team : props.authentication?.user?.user?.team} 
							userId={secret ? secret._id : props.authentication.user?.user?._id} 
					/>
                    
                </div>
                {device === 'desktop' &&
					<div><Footer /></div>
				}
            </div>
            <div
                className={cc([
                    'main-panel',
                    'col',
                    's12',
                    'l8',
                    device
                ])}
            >
                <div>
                    <div className="row">
                        <div className="col s5 l5">
                            <CurrentLevelWidget exp={props.exp} />
                        </div>
                        <div className="col s7 l7">
                            <SelfServiceWidget render="dashboard" />

                        </div>
                    </div>
                    <CurrentStats />
                    <PostWidget />
                    <Feeds />
                </div>
            </div>
            {device === 'tablet' &&
                <div><Footer /></div>
            }
        </div>
    );
};

function mapStateToProps(state) {
    return {
        exp: state.exps.exp?.[0]?.exp || 0,
        authentication: state.authentication,
    };
};

export default connect(mapStateToProps)(Dashboard);

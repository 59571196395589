import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { history } from '../_core/helpers/history';
import { alertActions } from '../_core/actions/alert';
import PrivateRoute from '../_core/components/PrivateRoute';
//import { HomePage } from '../HomePage/HomePage';
import { NotFound } from '../Components/ERRORPAGE/NotFound';
import { NoPermission } from '../Components/ERRORPAGE/NoPermission';
import { TokenExpired } from '../Components/ERRORPAGE/TokenExpired';
//import LoginPage from '../LoginPage/LoginPage';
import  NewLogin from '../LoginPage/NewLogin';
import { RegisterPage } from '../RegisterPage/RegisterPage';
// import { UserProfilePage } from '../UserProfilePage/UserProfilePage';
import { UserEditorProfile } from '../UserProfilePage/UserEditorProfile';
import { UserProfileEditor } from '../UserProfilePage/UserProfileEditor';
import { UserProfileDetail } from '../UserProfilePage/UserProfileDetail';
import { ChangePassPage } from '../ChangePasswordPage/ChangePassPage';
import { ResetPassPage } from '../ResetPassPage/ResetPassPage';
import './App.sass'
//import  AnnouncementPage  from '../Admin/Announcement/DarkMode/CreateAnnouncementPage';
import  ListAnnouncementPage  from '../Admin/Announcement/DarkMode/ListAnnouncementDark';
import { Dashboard } from '../Admin/DashBoard/Dashboard';
import DashboardPage from '../Admin/DashBoard/DashboardDarkmode/dashboard';
import  UserManagementDarkPage  from '../Admin/UserManagement/UserManagementDark';
// import { UserEditor } from '../Admin/UserManagement/UserEditor';
//import UserEditorDark from '../Admin/UserManagement/DarkMode/UserEditor';
// import { AddUser } from '../Admin/UserManagement/AddUser';
import { AddUserAttribute } from '../Admin/UserManagement/AddUserAttribute';
import RolesPageDark from '../Admin/RolesPage/DarkMode/RolesPageDark';
// import { RolesAdd } from '../Admin/RolesPage/RolesAdd';
//import { RolesPage} from '../Admin/RolesPage/RolesPage';
import { AssignRolePage } from '../Admin/RolesPage/AssignRolePage';
import  AttributeDark  from '../Admin/AttributesPage/DarkMode/AttributeDark';
// import  EditAttributeDark from '../Admin/AttributesPage/DarkMode/EditAttributeDark';
// import  CreateAttributeDark  from '../Admin/AttributesPage/DarkMode/CreateAttributeDark';
import { QuestPage } from '../QuestPage/QuestPage';
import { QuestIndex } from '../QuestPage/QuestIndex';
import PeoplesPage from '../PeoplesPage/PeoplesPage';
import { PeoplesView } from '../PeoplesPage/PeoplesView';
import { PeopleDetails } from '../PeoplesPage/PeopleDetails';
import { UserCreateChallengePage } from '../ChallengesPage/UserCreateChallengePage';
import { UserChallengesPage } from '../ChallengesPage/UserChallengesPage';
import { RequestCoePage } from '../COEPage/RequestCoePage';
import { SendSupportTicket } from '../SupportTicketPage/SendSupportTicket';
import { SubmitHrConcern } from '../HRConcernPage/SubmitHrConcern';
//import { LeavePage } from '../LeavePages/LeavePage';
import { RequestTimeOff } from '../LeavePages/RequestTimeOff';
//import { TechnicalSupportPage } from '../Admin/TechnicalSupportPage/TechnicalSupportPage';
import TechnicalSupportPage from '../Admin/TechnicalSupportPage/DarkMode/TechnicalSuportDark';

// import { UpdateTechnicalSupportPage } from '../Admin/TechnicalSupportPage/UpdateTechnicalSupportPage';
// import { RequestTimeOff1 } from '../LeavePages/RequestTimeOff1';
import { UserOvertimePage } from '../OvertimePages/OvertimePage';
import { SendRequestOvertime } from '../OvertimePages/SendRequestOvertime';
import TimesheetPage from '../TimesheetPage/TimesheetPage';
import { CreateQuestPage } from '../_templates/CreateQuestAdmin';
import { TemplatesIndex } from '../_templates/TemplatesIndex.js';
import { NewHomepage } from '../HomePage/NewHomepage.js';
import { Challengespage } from '../_templates/Challengespage';
import { CreateChallengePage } from '../_templates/CreateChallengeAdmin';
import { Feedpage } from '../_templates/Feedpage';
import { QuestManagement } from '../_templates/QuestManagement';
import { NewUserEditor } from '../_templates/NewUserEditor';
import { QuestMission } from '../_templates/QuestMission';
import { Location } from '../_templates/Location';
import { QuestMissionLandingPage } from '../_templates/QuestMissionLandingPage';
import { QuestCompletedLandingPage } from '../_templates/QuestCompletedLandingPage';
import { ChallengesCompletedLandingPage } from '../_templates/ChallengesCompletedLandingPage';
import { LeaderboardsPage } from '../_templates/LeaderboardsPage';
import { EmailTemplate } from '../Components/EmailTemplate';
import { RewardsManagement } from '../_templates/RewardsManagement';
import { RewardRedemptionManagement } from '../_templates/RewardRedemptionManagement';
import  LeavePageDark  from '../Admin/LeavesPage/DarkMode/LeavePageDark';
import OvertimeDark from '../Admin/TimekeepingPage/DarkMode/OvertimeDark';
import UnderTime from '../Admin/TimekeepingPage/DarkMode/UnderTime';
//import { TodayPage } from '../Admin/TimekeepingPage/TodayPage';
// import  UpdateOvertimeDark from '../Admin/TimekeepingPage/DarkMode/UpdateOvertimeDark';
//import { ViewLeaveRequest } from '../Admin/LeavesPage/ViewLeaveRequest';
// import  CreateLeaveType from '../Admin/LeaveType/DarkMode/CreateLeaveTypePage';
//import { CreateLeaveType } from '../Admin/LeaveType/CreateLeaveTypePage';
//import { AddLeaveCreditsPage } from '../Admin/LeaveCreditsPage/AddLeaveCreditsPage';
//import  AddCreditsPageDark from '../Admin/LeaveCreditsPage/DarkMode/CreateCreditsPage';
//import  LeaveCreditsPage  from '../Admin/LeaveCreditsPage/DarkMode/LeaveCreditsDark';
//import { ReportsPage } from '../Admin/TimekeepingPage/Report';
//mport { UserTimesheetPage } from '../Admin/TimekeepingPage/UserTimesheet';
import { SelfServicePage } from '../SelfServicePage/SelfService';
// import EditLeaveTypePage from '../Admin/LeaveType/EditLeaveTypePage';
import LeaveTypesPage from '../Admin/LeaveType/DarkMode/LeaveTypePage';
//import ManageLeaveCredit from '../Admin/LeaveCreditsPage/ManageLeaveCredit';
import { OfficesPage } from '../OfficesPage/OfficesPage';
import Leaderboards from '../LeaderBoards/LeaderBoards';
import { OfficeEmployeesPage } from '../OfficesPage/OfficeEmployees';
import  PermissionsPageDark  from '../Admin/PermissionsPage/DarkMode/PermissionsPageDark';
// import  AddPermissionDark from '../Admin/PermissionsPage/DarkMode/AddPermissionDark';
// import  EditPermissionDark  from '../Admin/PermissionsPage/DarkMode/EditPermissionDark';
//import { HRPage } from '../Admin/HRPage/HRPage';
import HRPageDark from '../Admin/HRPage/DarkMode/HRPageDark';
//import { COEPage } from '../Admin/COEPage/COEPage';
//import { UpdateHRPage } from '../Admin/HRPage/UpdateHRPage';
// import UpdateHRPageDark from '../Admin/HRPage/DarkMode/UpdateHRPageDark';
//import { UpdateCOEPage } from '../Admin/COEPage/UpdateCOEPage';
import TeamManagementPage from '../Admin/TeamManagementPage/TeamManagementPage';
import PageManagementPage from '../Admin/PageManagementPage/PageManagementPage';
import { roleActions } from '../_core/actions/role'
import { userActions } from '../_core/actions/user';
import { userService } from '../_core/services/user';
import moment from 'moment';
//import SideMenu from '../Admin/Components/SideMenu';
import UserDashboard from '../UserDashboard/UserDashboard';
// import { matchPath } from "react-router";
import useEffectWithPrevious from 'use-effect-with-previous';
import M from 'materialize-css/dist/js/materialize.min.js'
import isEmpty from 'lodash/isEmpty';
import DocumentTitle from 'react-document-title';
import AppStoreRedirect from '../AppStoreRedirect/AppStoreRedirect';
// import  RolesActionDark  from '../Admin/RolesPage/DarkMode/RolesActionDark';
// import UpdateCOEDark from '../Admin/COEPage/DarkMode/UpdateCOEDark';
import COEPageDark from '../Admin/COEPage/DarkMode/COEPageDark';

import  EvaluationPage  from '../Admin/AppraisalManagement/EvaluationPage/EvaluationPage';
import TenuresPage from '../Admin/AppraisalManagement/TenuresPage/TenuresPage';
import QuestionnairePage from '../Admin/AppraisalManagement/QuestionnairePage/QuestionnairePage';
import LeaveCreditsDark from '../Admin/LeaveCreditsPage/DarkMode/LeaveCreditsDark';
import { ReportsPageDark } from '../Admin/TimekeepingPage/DarkMode/ReportsDark';
import {UserTimesheetPageDark} from '../Admin/TimekeepingPage/DarkMode/UserTimesheetDark'
import  TodayPageDark  from '../Admin/TimekeepingPage/DarkMode/TodayPageDark';


import  QuestsPage  from '../Admin/Engagement/Quests/QuestsPage';
import  ChallengesPage  from '../Admin/Engagement/Challenges/ChallengePage';
import  BadgesPage  from '../Admin/Engagement/Badges/BadgesPage';
import  Credits  from '../Admin/Engagement/Credits/Credits';
import  RewardsPage  from '../Admin/Engagement/Rewards/RewardsPage';
import  SettingsDark  from '../Admin/Settings/SettingsDark/SettingsDark';
import  Feedback  from '../Admin/Feedback/FeedbackDark/Feedback';
import  {ResetEnergyPage}  from '../Admin/Engagement/ResetEnergy/ResetEnergyPage';


import  AchievementsPage  from '../Admin/Achievements/AchievementsPage';

//import { RolesEdit } from '../Admin/RolesPage/RolesEdit';


// class OldApp extends React.Component {
//     constructor(props) {
//         super(props);

//         const { dispatch } = this.props;
//         history.listen((location, action) => {
//             // clear alert on location change
//             dispatch(alertActions.clear());
//         });
//     }

//     adminNav() {
//         var url = window.location.pathname.toString();
//         const match = matchPath(url, {
//             path: "/admin",
//             exact: false,
//             strict: false
//         });

//         if (match !== null) {
//             return (
//                 <SideMenu />
//             )
//         }
//     }

//     render() {
//         let roleSelected = JSON.parse(localStorage.getItem("userRole"));

//         if (roleSelected !== null && roleSelected[0] && roleSelected[0].roleAll === 1) {
//             return (
//                 <Router history={history}>
//                     <React.Fragment>
//                         {this.adminNav()}
//                         <Switch>
//                             {/* <PrivateRoute exact path="/dashboard" authed={true} redirectTo="/login" component={UserProfilePage} /> */}
//                             <Route exact path="/" component={LoginPage} />
//                             <Route path="/login" component={LoginPage} />
//                             <Route path="/register" component={RegisterPage} />
//                             <Route path='/pragmanila' component={() => { window.location.href = 'https://cloud.pragmahr.com/register'; return null; }} />

//                             <PrivateRoute authed={true} path="/resetpassword" component={ResetPassPage} />
//                             <PrivateRoute authed={true} exact path="/dashboard" component={UserProfilePage} />
//                             <PrivateRoute authed={true} exact path="/profile" component={UserProfileDetail} />
//                             <PrivateRoute authed={true} path="/profile/edit" component={UserEditorProfile} />
//                             <PrivateRoute authed={true} path="/profile/edit2" component={UserProfileEditor} />
//                             <PrivateRoute authed={true} path="/timesheet" component={TimesheetPage} />
//                             <PrivateRoute authed={true} exact path="/changepassword" component={ChangePassPage} />

//                             <PrivateRoute authed={true} exact path="/admin" component={Dashboard} />
//                             <PrivateRoute authed={true} path="/admin/users" component={UserManagementPage} />
//                             <PrivateRoute authed={true} path="/admin/user/create" component={AddUser} />
//                             <PrivateRoute authed={true} path="/admin/user/edit" component={UserEditor} />
//                             <PrivateRoute authed={true} path="/admin/user/add/attributes" component={AddUserAttribute} />
//                             <PrivateRoute authed={true} path="/admin/roles/page=:id" component={RolesPage} />
//                             <PrivateRoute authed={true} path="/admin/role/add" component={RolesAdd} />
//                             <PrivateRoute authed={true} path="/admin/role/edit" component={RolesEdit} />
//                             <PrivateRoute authed={true} path="/admin/role/assign" component={AssignRolePage} />
//                             <PrivateRoute authed={true} path="/admin/attributes/page=:id" component={AttributesPage} />
//                             <PrivateRoute authed={true} path="/admin/attribute/create" component={CreateAttributePage} />
//                             <PrivateRoute authed={true} path="/admin/attribute/edit" component={EditAttributePage} />
//                             <PrivateRoute authed={true} path="/admin/permissions" component={PermissionPage} />
//                             <PrivateRoute authed={true} path="/admin/permission/edit" component={EditPermissionPage} />
//                             <PrivateRoute authed={true} path="/admin/permission/create" component={AddPermissionPage} />
//                             <PrivateRoute authed={true} path="/admin/leave-requests/page=:id" component={LeavesPage} />
//                             <PrivateRoute authed={true} path="/admin/leave-requests" component={LeavesPage} />
//                             <PrivateRoute authed={true} path="/admin/overtime-requests/page=:id" component={OvertimesPage} />
//                             <PrivateRoute authed={true} path="/admin/overtime-requests" component={OvertimesPage} />
//                             <PrivateRoute authed={true} path="/admin/overtime-request/update/:page/:id" component={UpdateOvertimePage} />
//                             <PrivateRoute authed={true} path="/admin/today/page=:id" component={TodayPage} />
//                             <PrivateRoute authed={true} path="/admin/leave-type/create" component={CreateLeaveType} />
//                             <PrivateRoute authed={true} exact path="/admin/leave-type/overview" component={LeaveTypesOverview} />
//                             <PrivateRoute authed={true} exact path="/admin/leave-type/overview/edit/:id" component={EditLeaveTypePage} />
//                             <PrivateRoute authed={true} path="/admin/leave-request/update/:page/:id" component={ViewLeaveRequest} />
//                             <PrivateRoute authed={true} path="/admin/leave-credit/create" component={AddLeaveCreditsPage} />
//                             <PrivateRoute authed={true} exact path="/admin/leave-credits-overview" component={CreditsOverviewPage} />
//                             <PrivateRoute authed={true} exact path="/admin/leave-credits-overview/:page" component={CreditsOverviewPage} />
//                             <PrivateRoute authed={true} exact path="/admin/leave-credits-overview/manage/:id" component={ManageLeaveCredit} />
//                             <PrivateRoute authed={true} path="/admin/timekeeping/reports" component={ReportsPage} />
//                             <PrivateRoute authed={true} path="/admin/timekeeping/timesheet/:user" component={UserTimesheetPage} />

//                             <PrivateRoute authed={true} path="/admin/coe-requests/page=:id" component={COEPage} />
//                             <PrivateRoute authed={true} path="/admin/coe-requests/" component={COEPage} />
//                             {/* <PrivateRoute authed={true} path="/admin/hr-concerns/page=:id" component={HRPage} /> */}
//                             {/* <PrivateRoute authed={true} path="/admin/hr-concerns/" component={HRPage} /> */}

//                             <PrivateRoute authed={true} path="/quests" component={QuestIndex} />
//                             <PrivateRoute authed={true} path="/quest/view" component={QuestPage} />

//                             <PrivateRoute authed={true} path="/people" component={PeoplesPage} />
//                             <PrivateRoute authed={true} path="/peoples/view" component={PeoplesView} />
//                             <PrivateRoute authed={true} path="/peoples/profile" component={PeopleDetails} />
//                             <PrivateRoute authed={true} path="/offices" component={OfficesPage} />
//                             <PrivateRoute authed={true} path="/employees-per-office" component={OfficeEmployeesPage} />

//                             <PrivateRoute authed={true} exact path="/user/self-service" component={SelfServicePage} />
//                             <PrivateRoute authed={true} exact path="/user/leave" component={LeavePage} />
//                             <PrivateRoute authed={true} exact path="/user/leave/request" component={RequestTimeOff} />
//                             <PrivateRoute authed={true} exact path="/user/overtime/request" component={SendRequestOvertime} />
//                             <PrivateRoute authed={true} exact path="/user/overtime" component={UserOvertimePage} />
//                             <PrivateRoute authed={true} exact path="/user/send/coe" component={RequestCoePage} />
//                             <PrivateRoute authed={true} exact path="/user/send/support-ticket" component={SendSupportTicket} />
//                             <PrivateRoute authed={true} exact path="/user/send/hr-concern" component={SubmitHrConcern} />

//                             <PrivateRoute authed={true} path="/leaderboards" component={Leaderboards} />

//                             <PrivateRoute authed={true} exact path="/challenge" component={UserChallengesPage} />
//                             <PrivateRoute authed={true} path="/challenge/create" component={UserCreateChallengePage} />
//                             <PrivateRoute authed={true} path="/templates/create-quest-page" component={CreateQuestPage} />
//                             <PrivateRoute authed={true} exact path="/templates" component={TemplatesIndex} />

//                             <PrivateRoute authed={true} path="/templates/homepage" component={NewHomepage} />
//                             <PrivateRoute authed={true} path="/templates/challenges-page" component={Challengespage} />
//                             <PrivateRoute authed={true} path="/templates/create-challenge-page" component={CreateChallengePage} />
//                             <PrivateRoute authed={true} path="/templates/feed-page" component={Feedpage} />
//                             <PrivateRoute authed={true} path="/templates/Quest-management" component={QuestManagement} />
//                             <PrivateRoute authed={true} path="/templates/quest-mission" component={QuestMission} />
//                             <PrivateRoute authed={true} path="/templates/user-editor-page" component={NewUserEditor} />
//                             <PrivateRoute authed={true} path="/templates/location" component={Location} />
//                             <PrivateRoute authed={true} path="/templates/quest-mission-landing-page" component={QuestMissionLandingPage} />
//                             <PrivateRoute authed={true} path="/templates/quest-completed-landing-page" component={QuestCompletedLandingPage} />
//                             <PrivateRoute authed={true} path="/templates/challenges-completed-landing-page" component={ChallengesCompletedLandingPage} />
//                             <PrivateRoute authed={true} path="/templates/leaderboards-page" component={LeaderboardsPage} />
//                             <PrivateRoute authed={true} path="/templates/email-template" component={EmailTemplate} />
//                             <PrivateRoute authed={true} path="/templates/rewards-management-page" component={RewardsManagement} />
//                             <PrivateRoute authed={true} path="/templates/reward-redemption-management-page" component={RewardRedemptionManagement} />

//                             <Route path="*" component={NotFound} />
//                         </Switch>
//                     </React.Fragment>
//                 </Router>
//             );
//         } else if (roleSelected === null) {
//             return (
//                 <Router history={history}>
//                     <Switch>
//                         {/* <PrivateRoute authed={true} exact path="/dashboard" component={UserProfilePage} /> */}
//                         <Route exact path="/" component={LoginPage} />
//                         <Route path="/login" component={LoginPage} />
//                         <Route path="/register" component={RegisterPage} />
//                         <Route path='/pragmanila' component={() => { window.location.href = 'https://cloud.pragmahr.com/register'; return null; }} />

//                         <PrivateRoute authed={true} path="/resetpassword" component={ResetPassPage} />
//                         <PrivateRoute authed={true} exact path="/dashboard" component={UserProfilePage} />
//                         <PrivateRoute authed={true} exact path="/profile" component={UserProfileDetail} />
//                         <PrivateRoute authed={true} path="/profile/edit" component={UserEditorProfile} />
//                         <PrivateRoute authed={true} path="/profile/edit2" component={UserProfileEditor} />
//                         <PrivateRoute authed={true} path="/timesheet" component={TimesheetPage} />
//                         <PrivateRoute authed={true} exact path="/changepassword" component={ChangePassPage} />

//                         <PrivateRoute authed={true} path="/quests" component={QuestIndex} />

//                         <PrivateRoute authed={true} path="/quest/view" component={QuestPage} />

//                         <PrivateRoute authed={true} path="/people" component={PeoplesPage} />
//                         <PrivateRoute authed={true} path="/peoples/view" component={PeoplesView} />
//                         <PrivateRoute authed={true} path="/peoples/profile" component={PeopleDetails} />
//                         <PrivateRoute authed={true} path="/offices" component={OfficesPage} />
//                         <PrivateRoute authed={true} path="/employees-per-office" component={OfficeEmployeesPage} />

//                         <PrivateRoute authed={true} exact path="/user/self-service" component={SelfServicePage} />
//                         <PrivateRoute authed={true} exact path="/user/leave" component={LeavePage} />
//                         <PrivateRoute authed={true} exact path="/user/leave/request" component={RequestTimeOff} />
//                         <PrivateRoute authed={true} exact path="/user/overtime/request" component={SendRequestOvertime} />
//                         <PrivateRoute authed={true} exact path="/user/overtime" component={UserOvertimePage} />
//                         <PrivateRoute authed={true} exact path="/user/send/coe" component={RequestCoePage} />
//                         <PrivateRoute authed={true} exact path="/user/send/support-ticket" component={SendSupportTicket} />
//                         <PrivateRoute authed={true} exact path="/user/send/hr-concern" component={SubmitHrConcern} />

//                         <PrivateRoute authed={true} path="/leaderboards" component={Leaderboards} />

//                         <PrivateRoute authed={true} exact path="/challenge" component={UserChallengesPage} />
//                         <PrivateRoute authed={true} path="/challenge/create" component={UserCreateChallengePage} />
//                         <PrivateRoute authed={true} path="/templates/create-quest-page" component={CreateQuestPage} />
//                         <PrivateRoute authed={true} exact path="/templates" component={TemplatesIndex} />

//                         <PrivateRoute authed={true} path="/templates/homepage" component={NewHomepage} />
//                         <PrivateRoute authed={true} path="/templates/challenges-page" component={Challengespage} />
//                         <PrivateRoute authed={true} path="/templates/create-challenge-page" component={CreateChallengePage} />
//                         <PrivateRoute authed={true} path="/templates/feed-page" component={Feedpage} />
//                         <PrivateRoute authed={true} path="/templates/Quest-management" component={QuestManagement} />
//                         <PrivateRoute authed={true} path="/templates/quest-mission" component={QuestMission} />
//                         <PrivateRoute authed={true} path="/templates/user-editor-page" component={NewUserEditor} />
//                         <PrivateRoute authed={true} path="/templates/location" component={Location} />
//                         <PrivateRoute authed={true} path="/templates/quest-mission-landing-page" component={QuestMissionLandingPage} />
//                         <PrivateRoute authed={true} path="/templates/quest-completed-landing-page" component={QuestCompletedLandingPage} />
//                         <PrivateRoute authed={true} path="/templates/challenges-completed-landing-page" component={ChallengesCompletedLandingPage} />
//                         <PrivateRoute authed={true} path="/templates/leaderboards-page" component={LeaderboardsPage} />
//                         <PrivateRoute authed={true} path="/templates/email-template" component={EmailTemplate} />
//                         <PrivateRoute authed={true} path="/templates/rewards-management-page" component={RewardsManagement} />
//                         <PrivateRoute authed={true} path="/templates/reward-redemption-management-page" component={RewardRedemptionManagement} />
//                         <Route path="*" component={NotFound} />
//                     </Switch>
//                 </Router>

//             );
//         }
//         else {
//             /*
//             return (
//                 <Router history={history}>
//                     <>
//                         <NavigationBar/>
//                         <Switch>
//                             <Route exact path="/" component={LoginPage} />
//                             <Route path="/login" component={LoginPage} />
//                             <Route path="/register" component={RegisterPage} />
//                             <Route path='/pragmanila' component={() => { window.location.href = 'https://cloud.pragmahr.com/register'; return null; }} />

//                             <PrivateRoute authed={true} path="/resetpassword" component={ResetPassPage} />
//                             <PrivateRoute authed={true} exact path="/dashboard" component={UserProfilePage} />
//                             <PrivateRoute authed={true} exact path="/profile" component={UserProfileDetail} />
//                             <PrivateRoute authed={true} path="/profile/edit" component={UserEditorProfile} />
//                             <PrivateRoute authed={true} path="/profile/edit2" component={UserProfileEditor} />
//                             <PrivateRoute authed={true} path="/timesheet" component={TimesheetPage} />
//                             <PrivateRoute authed={true} exact path="/changepassword" component={ChangePassPage} />

//                             <PrivateRoute authed={true} path="/quests" component={QuestIndex} />

//                             <PrivateRoute authed={true} path="/quest/view" component={QuestPage} />

//                             <PrivateRoute authed={true} path="/people" component={PeoplesPage} />
//                             <PrivateRoute authed={true} path="/peoples/view" component={PeoplesView} />
//                             <PrivateRoute authed={true} path="/peoples/profile" component={PeopleDetails} />
//                             <PrivateRoute authed={true} path="/offices" component={OfficesPage} />
//                             <PrivateRoute authed={true} path="/employees-per-office" component={OfficeEmployeesPage} />

//                             <PrivateRoute authed={true} exact path="/user/self-service" component={SelfServicePage} />
//                             <PrivateRoute authed={true} exact path="/user/leave" component={LeavePage} />
//                             <PrivateRoute authed={true} exact path="/user/leave/request" component={RequestTimeOff} />
//                             <PrivateRoute authed={true} exact path="/user/overtime/request" component={SendRequestOvertime} />
//                             <PrivateRoute authed={true} exact path="/user/overtime" component={UserOvertimePage} />
//                             <PrivateRoute authed={true} exact path="/user/send/coe" component={RequestCoePage} />
//                             <PrivateRoute authed={true} exact path="/user/send/support-ticket" component={SendSupportTicket} />
//                             <PrivateRoute authed={true} exact path="/user/send/hr-concern" component={SubmitHrConcern} />

//                             <PrivateRoute authed={true} path="/leaderboards" component={Leaderboards} />

//                             <PrivateRoute authed={true} exact path="/challenge" component={UserChallengesPage} />
//                             <PrivateRoute authed={true} path="/challenge/create" component={UserCreateChallengePage} />
//                             <PrivateRoute authed={true} path="/templates/create-quest-page" component={CreateQuestPage} />
//                             <PrivateRoute authed={true} exact path="/templates" component={TemplatesIndex} />

//                             <PrivateRoute authed={true} path="/templates/homepage" component={NewHomepage} />
//                             <PrivateRoute authed={true} path="/templates/challenges-page" component={Challengespage} />
//                             <PrivateRoute authed={true} path="/templates/create-challenge-page" component={CreateChallengePage} />
//                             <PrivateRoute authed={true} path="/templates/feed-page" component={Feedpage} />
//                             <PrivateRoute authed={true} path="/templates/Quest-management" component={QuestManagement} />
//                             <PrivateRoute authed={true} path="/templates/quest-mission" component={QuestMission} />
//                             <PrivateRoute authed={true} path="/templates/user-editor-page" component={NewUserEditor} />
//                             <PrivateRoute authed={true} path="/templates/location" component={Location} />
//                             <PrivateRoute authed={true} path="/templates/quest-mission-landing-page" component={QuestMissionLandingPage} />
//                             <PrivateRoute authed={true} path="/templates/quest-completed-landing-page" component={QuestCompletedLandingPage} />
//                             <PrivateRoute authed={true} path="/templates/challenges-completed-landing-page" component={ChallengesCompletedLandingPage} />
//                             <PrivateRoute authed={true} path="/templates/leaderboards-page" component={LeaderboardsPage} />
//                             <PrivateRoute authed={true} path="/templates/email-template" component={EmailTemplate} />
//                             <PrivateRoute authed={true} path="/templates/rewards-management-page" component={RewardsManagement} />
//                             <PrivateRoute authed={true} path="/templates/reward-redemption-management-page" component={RewardRedemptionManagement} />
//                             <Route path="*" component={NotFound} />
//                         </Switch>
//                     </>
//                 </Router>
//             );
//             */

//             return (
//                 <Router history={history}>
//                     <Layout />
//                 </Router>
//             )
//         }
//     }
// }

const mapStateToProps = (state) => ({
    authentication: {
        ...state.authentication,
        loggedIn: state.authentication?.loggedIn ?? false,
    },
    isAdmin: (
        // state.roles?.role?.[0]?.roleAll === 1 ? true :
        //     JSON.parse(localStorage.getItem('userRole'))?.[0]?.roleAll === 1 ??
        //     false

        state.roles?.role?.[0]?.roleAll === 1 ? true : JSON.parse(localStorage.getItem('userRole'))?.[0]?.roleAll === 0 && JSON.parse(localStorage.getItem('userRole'))?.[0]?.permissions.lenght !== 0 ? true :  JSON.parse(localStorage.getItem('userRole'))?.[0]?.roleAll === 1 && JSON.parse(localStorage.getItem('userRole'))?.[0]?.permissions.lenght !== 0 ? true : false
    )
});

const AdministratorDashboard = connect(mapStateToProps)((props) => {
    const pageTitle = React.useMemo(() => {
        return props.location.pathname.split('/').slice(2, 3).join(' ')
            .toLowerCase().split('-')
            .map(word => word.charAt(0).toUpperCase() + word.substring(1))
            .join(' ')
    }, [props.location.pathname]);

    const viewPermission = JSON.parse(localStorage.getItem('viewPermission'));
    const addPermission = JSON.parse(localStorage.getItem('addPermission'));
    const editPermission = JSON.parse(localStorage.getItem('editPermission'));
    const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;


    const pagePermission = (window.location.pathname.includes('create')) || (window.location.pathname.includes('add')) || (roleAllTrue === 1) ? addPermission : (window.location.pathname.includes('/edit')) || (window.location.pathname.includes('review')) || (roleAllTrue === 1)  ?  editPermission : viewPermission;


    return (
        <DocumentTitle title={[pageTitle || 'Administrator', process.env.REACT_APP_DOCUMENT_TITLE].join(' | ')}>
            <>
                {/* <SideMenu {...props} /> */}
                <Switch>
                    <PrivateRoute
                        exact path="/admin/old/dashboard/"
                        component={Dashboard}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    />
                      <PrivateRoute
                        exact path="/admin/dashboard"
                        component={DashboardPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        // userPermissions={pagePermission}
                    />
                      {/* <PrivateRoute
                        path="/admin/announcement/create"
                        component={AnnouncementPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    /> */}
                    <PrivateRoute
                        path="/admin/users"
                        component={UserManagementDarkPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    {/* <PrivateRoute
                        path="/admin/user/create"
                        component={AddUser}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    />
                    <PrivateRoute
                        path="/admin/user/edit"
                        component={UserEditor}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    /> */}
                    <PrivateRoute
                        path="/admin/user/add/attributes"
                        component={AddUserAttribute}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    <PrivateRoute
                        path="/admin/roles"
                        component={RolesPageDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    <PrivateRoute
                        path="/admin/achievements"
                        component={AchievementsPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    {/* <PrivateRoute
                        path="/admin/role/add"
                        component={RolesActionDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    /> */}
                    {/* <PrivateRoute
                        path="/admin/role/edit"
                        component={RolesActionDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    /> */}
                    <PrivateRoute
                        path="/admin/role/assign"
                        component={AssignRolePage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    />
                    <PrivateRoute
                        path="/admin/attributes"
                        component={AttributeDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    {/* <PrivateRoute
                        path="/admin/attribute/create"
                        component={CreateAttributeDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    <PrivateRoute
                        path="/admin/attribute/edit"
                        component={EditAttributeDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    /> */}
                    <PrivateRoute
                        path="/admin/permissions"
                        component={PermissionsPageDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    {/* <PrivateRoute
                        path="/admin/permission/edit"
                        component={EditPermissionDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    <PrivateRoute
                        path="/admin/permission/create"
                        component={AddPermissionDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    /> */}
                    {/* <PrivateRoute
                        path="/admin/leave-requests/page=:id"
                        component={LeavesPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    /> */}
                    <PrivateRoute
                        path="/admin/leave-requests"
                        component={LeavePageDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    {/* <PrivateRoute
                        path="/admin/overtime-requests/page=:id"
                        component={OvertimesPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    /> */}
                    <PrivateRoute
                        path="/admin/overtime-requests"
                        component={OvertimeDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    <PrivateRoute
                        path="/admin/undertime-requests"
                        component={UnderTime}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    {/* <PrivateRoute
                        path="/admin/overtime-request/update/:page/:id"
                        component={UpdateOvertimeDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    /> */}
                    <PrivateRoute
                        path="/admin/today"
                        component={TodayPageDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    {/* <PrivateRoute
                        path="/admin/leave-type/create"
                        component={CreateLeaveType}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    /> */}
                    <PrivateRoute
                        path="/admin/leave-type/overview"
                        component={LeaveTypesPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    {/* <PrivateRoute
                        exact path="/admin/leave-type/overview/edit/:id"
                        component={EditLeaveTypePage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    /> */}
                    {/* <PrivateRoute
                        path="/admin/leave-request/update/:page/:id"
                        component={ViewLeaveRequest}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        mode="create"
                    /> */}
                    {/* <PrivateRoute
                        path="/admin/leave-credit/create"
                        component={AddCreditsPageDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    /> */}
                    <PrivateRoute
                        path="/admin/leave-credits-overview"
                        component={LeaveCreditsDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    {/* <PrivateRoute
                        exact path="/admin/leave-credits-overview/:page"
                        component={CreditsOverviewPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    /> */}
                    {/* <PrivateRoute
                        exact path="/admin/leave-credits-overview/manage/:id"
                        component={ManageLeaveCredit}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    /> */}
                    <PrivateRoute
                        path="/admin/timekeeping/reports"
                        component={ReportsPageDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                      <PrivateRoute
                        path="/admin/page-management"
                        component={PageManagementPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                  
                     <PrivateRoute
                        path="/admin/announcement"
                        component={ListAnnouncementPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    <PrivateRoute
                        path="/admin/timekeeping/timesheet/:user"
                        component={UserTimesheetPageDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    />
                    <PrivateRoute
                        path="/admin/coe-requests"
                        component={COEPageDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    {/* <PrivateRoute
                        path="/admin/coe-requests/page=:id"
                        component={COEPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    /> */}
                    <PrivateRoute
                        path="/admin/hr-concerns"
                        component={HRPageDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    {/* <PrivateRoute
                        path="/admin/hr-concerns/page=:id"
                        component={HRPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    /> */}
                    {/* <PrivateRoute
                        path="/admin/coe-request/update/:page/:id"
                        component={UpdateCOEDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    /> */}
                    {/* <PrivateRoute
                        path="/admin/hr-concern/update/:page/:id"
                        component={UpdateHRPageDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    /> */}
                    <PrivateRoute
                        path="/admin/technical-supports"
                        component={TechnicalSupportPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    {/* <PrivateRoute
                        path="/admin/technical-supports/page=:id"
                        component={TechnicalSupportPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    /> */}
                    {/* <PrivateRoute
                        path="/admin/technical-support/update/:page/:id"
                        component={UpdateTechnicalSupportPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    /> */}
                    <PrivateRoute
                        path="/admin/team-management"
                        component={TeamManagementPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    <PrivateRoute
                        path="/admin/page-management"
                        component={PageManagementPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                    />
                     <PrivateRoute
                        path="/admin/evaluations"
                        component={EvaluationPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                     <PrivateRoute
                        path="/admin/questionnaires"
                        component={QuestionnairePage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    
                     <PrivateRoute
                        path="/admin/tenures"
                        component={TenuresPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                     <PrivateRoute
                        path="/admin/quests"
                        component={QuestsPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    <PrivateRoute
                        path="/admin/challenges"
                        component={ChallengesPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                     <PrivateRoute
                        path="/admin/badges"
                        component={BadgesPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                     <PrivateRoute
                        path="/admin/credits"
                        component={Credits}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                     <PrivateRoute
                        path="/admin/rewards"
                        component={RewardsPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                     <PrivateRoute
                        path="/admin/settings"
                        component={SettingsDark}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    <PrivateRoute
                        path="/admin/feedback"
                        component={Feedback}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    <PrivateRoute
                        path="/admin/reset-energy"
                        component={ResetEnergyPage}
                        isAdmin={props.isAdmin}
                        isLoggedIn={props.authentication.loggedIn}
                        routeType="admin"
                        userPermissions={pagePermission}
                    />
                    <Route path="*" component={NotFound} />
                    <Route path="*" component={NoPermission} />
                </Switch>
            </>
        </DocumentTitle>
    );
});
AdministratorDashboard.WrappedComponent.displayName = 'AdministratorDashboard';

const publicRoutes = ['/', '/login', '/register'];

const PageScrollSetter = (props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [props.location.pathname]);

    return null;
}

const RouteChanger = (props) => {
    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));

        if (publicRoutes.includes(props.location.pathname) && (isEmpty(user) === false)) {
            props.history.push('/dashboard')
        }
    }, [props.location.pathname, props.history])
    return null;
}

const App = (props) => {
    const dispatch = useDispatch();

    const checkTokenValidity = React.useCallback(() => {
        let refToken;

        let timer = setInterval(function () {
            if (!localStorage.getItem("userCredentials")) {
                clearInterval(timer)
                dispatch(userActions.logout());
            } else {
                if (!localStorage.getItem('token_expire')) {
                    clearInterval(timer)
                    dispatch(userActions.logout());
                } else {
                    const tokenTime = new Date(parseInt(localStorage.getItem('token_expire'))).getTime();
                    const currentTime = new Date().getTime();
                    if (moment(currentTime).isSameOrAfter(moment(tokenTime))) {
                        if (localStorage.getItem('refresh_token') && !refToken) {
                            refToken = JSON.stringify(localStorage.getItem('refresh_token'));
                            dispatch(userActions.refreshToken(refToken))
                        } else {
                            clearInterval(timer)

                            userService.userActivityListener.addListeners();
                            userService.userActivityListener.start();
                        }
                    }
                }
            }
        }, 1000);
    }, [dispatch]);

    // clear alert on location change
    React.useEffect(() => {
        history.listen(() => {
            const toast = M.Toast.getInstance(document.querySelector('.toast') || {});
            toast && toast.dismiss();

            dispatch(alertActions.clear());
        });
    }, [dispatch]);

    React.useEffect(() => {
        if (props.authentication.loggedIn === false) {
            return;
        }

        const user = JSON.parse(localStorage.getItem('userCredentials'));
        dispatch(roleActions.getOneRole(user.user.role));
    }, [dispatch, props.authentication.loggedIn]);

    useEffectWithPrevious(([previousAuthentication]) => {
        if (
            (previousAuthentication?.loggedIn !== props.authentication?.loggedIn) &&
            props.authentication?.loggedIn === true
        ) {
            checkTokenValidity();
        }
    }, [props.authentication]);

    return (
        <>
            <AppStoreRedirect/>
            <Router history={history}>
                <Route component={PageScrollSetter}/>
                <Route component={RouteChanger} />
                <Switch>
                    <Route
                        component={NewLogin}
                        exact
                        path={['/', '/login']}
                    />
                    <Route
                        component={RegisterPage}
                        exact
                        path="/register"
                    />
                    <Route
                        component={ResetPassPage}
                        exact
                        path="/resetpassword"
                    />
                    <Route
                        path='/pragmanila'
                        render={() => {
                            window.location.href = 'https://cloud.pragmahr.com/register'
                            return null;
                        }}
                    />
                    <PrivateRoute
                        component={UserDashboard}
                        isLoggedIn={props.authentication.loggedIn}
                        path={[
                            '/dashboard',
                            '/people',
                            '/profile',
                            '/engage',
                            '/rewards',
                            '/employee-self-service',
                            '/timekeeping',
                            '/page',
                            '/edit-profile',
                            '/search',
                            '/performance',
                            '/changepassword',
                        ]}
                    />

                    <PrivateRoute
                        component={TokenExpired}
                        exact
                        isLoggedIn={props.authentication.loggedIn}
                        path="/401"
                    />

                    <PrivateRoute
                        component={UserProfileDetail}
                        exact
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/profile"
                    />
                    <PrivateRoute
                        component={UserEditorProfile}
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/profile/edit"
                    />
                    <PrivateRoute
                        component={UserProfileEditor}
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/profile/edit2"
                    />
                    <PrivateRoute
                        component={TimesheetPage}
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/timesheet"
                    />
                    <PrivateRoute
                        component={ChangePassPage}
                        exact
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/changepassword"
                    />
                    <PrivateRoute
                        component={QuestIndex}
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/quests"
                    />
                    <PrivateRoute
                        component={QuestPage}
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/quest/view"
                    />
                    <PrivateRoute
                        component={PeoplesPage}
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/people"
                    />
                    <PrivateRoute
                        component={PeoplesView}
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/peoples/view"
                    />
                    <PrivateRoute
                        component={PeopleDetails}
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/peoples/profile"
                    />
                    <PrivateRoute
                        component={OfficesPage}
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/offices"
                    />
                    <PrivateRoute
                        component={OfficeEmployeesPage}
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/employees-per-office"
                    />
                    <PrivateRoute
                        exact
                        component={SelfServicePage}
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/user/self-service"
                    />
                    <PrivateRoute
                        component={LeavePageDark}
                        exact
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/user/leave"
                    />
                    <PrivateRoute
                        component={RequestTimeOff}
                        exact
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/user/leave/request"
                    />
                    <PrivateRoute
                        component={SendRequestOvertime}
                        exact
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/user/overtime/request"
                    />
                    <PrivateRoute
                        component={UserOvertimePage}
                        exact
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/user/overtime"
                    />
                    <PrivateRoute
                        component={RequestCoePage}
                        exact
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/user/send/coe"
                    />
                    <PrivateRoute
                        component={SendSupportTicket}
                        exact
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/user/send/support-ticket"
                    />
                    <PrivateRoute
                        component={SubmitHrConcern}
                        exact
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/user/send/hr-concern"
                    />
                    <PrivateRoute
                        component={Leaderboards}
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/leaderboards"
                    />
                    <PrivateRoute
                        component={UserChallengesPage}
                        exact
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/challenge"
                    />
                    <PrivateRoute
                        component={UserCreateChallengePage}
                        isLoggedIn={props.authentication.loggedIn}
                        path="/old/challenge/create"
                    />

                    <PrivateRoute
                        component={AdministratorDashboard}
                        isLoggedIn={props.authentication.loggedIn}
                        path="/admin"
                    />

                    <PrivateRoute isLoggedIn={props.authentication.loggedIn} path="/templates/create-quest-page" component={CreateQuestPage} />
                    <PrivateRoute isLoggedIn={props.authentication.loggedIn} exact path="/templates" component={TemplatesIndex} />

                    <PrivateRoute isLoggedIn={props.authentication.loggedIn} path="/templates/homepage" component={NewHomepage} />
                    <PrivateRoute isLoggedIn={props.authentication.loggedIn} path="/templates/challenges-page" component={Challengespage} />
                    <PrivateRoute isLoggedIn={props.authentication.loggedIn} path="/templates/create-challenge-page" component={CreateChallengePage} />
                    <PrivateRoute isLoggedIn={props.authentication.loggedIn} path="/templates/feed-page" component={Feedpage} />
                    <PrivateRoute isLoggedIn={props.authentication.loggedIn} path="/templates/Quest-management" component={QuestManagement} />
                    <PrivateRoute isLoggedIn={props.authentication.loggedIn} path="/templates/quest-mission" component={QuestMission} />
                    <PrivateRoute isLoggedIn={props.authentication.loggedIn} path="/templates/user-editor-page" component={NewUserEditor} />
                    <PrivateRoute isLoggedIn={props.authentication.loggedIn} path="/templates/location" component={Location} />
                    <PrivateRoute isLoggedIn={props.authentication.loggedIn} path="/templates/quest-mission-landing-page" component={QuestMissionLandingPage} />
                    <PrivateRoute isLoggedIn={props.authentication.loggedIn} path="/templates/quest-completed-landing-page" component={QuestCompletedLandingPage} />
                    <PrivateRoute isLoggedIn={props.authentication.loggedIn} path="/templates/challenges-completed-landing-page" component={ChallengesCompletedLandingPage} />
                    <PrivateRoute isLoggedIn={props.authentication.loggedIn} path="/templates/leaderboards-page" component={LeaderboardsPage} />
                    <PrivateRoute isLoggedIn={props.authentication.loggedIn} path="/templates/email-template" component={EmailTemplate} />
                    <PrivateRoute isLoggedIn={props.authentication.loggedIn} path="/templates/rewards-management-page" component={RewardsManagement} />
                    <PrivateRoute isLoggedIn={props.authentication.loggedIn} path="/templates/reward-redemption-management-page" component={RewardRedemptionManagement} />

                    <Route path="*" component={NotFound} />
                </Switch>
            </Router>
        </>
    );
}

export default connect(mapStateToProps)(App);

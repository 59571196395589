import React from "react";
import cc from "classcat";
import moment from "moment";
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";
import { checkSideNav } from "../../Components/checkSideMenu";
import { Route, Switch } from "react-router-dom";
import BodyBackgroundColor from "react-body-backgroundcolor";
import { Switch as SwitchCase, Case } from 'react-if';
import styled from "styled-components";
import LinesEllipsis from 'react-lines-ellipsis';
import { Link} from 'react-router-dom';
import {
  PageWrapper,
  ContentWrapper,
} from "../../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent, DynamicTableAndPaginationWrapper, DynamicEntriesOnly } from "../../Components/PageWrapper/DynamicWrapper";
import ReviewTech from './ReviewTech';
import queryString from 'query-string';


const AdminTechnSupportPage = styled.div`
  table {
    >thead>tr>th {
       &:last-child {
        text-align: inherit;
      }
    }

    >tbody>tr>td {
      &:nth-child(3), &:nth-child(4) {
        text-align: inherit;
        
        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          object-fit: cover;
        }

        @media (max-width: 993px){
          text-align: left;
        } 
      }
    }
  }

  .subjectTooltip {
    background-color: #121D38!important;
    width: auto;
    padding: 10px 10px;
    margin-top: 0;
    margin-left: 30px;

    color: #9BA3CA;
    font-size: 14px;
    font-weight: 400;
  }
`;

const TechSupportList = (props) => {

    const [entriesValues, setentriesValues] = React.useState(); 
    const [TechSupportList, setTech] = React.useState([]);

    const editPermission = JSON.parse(localStorage.getItem('editPermission'));
    const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;
  
    let tableHeader = (editPermission.includes("Support - Technical Support") === true) || (roleAllTrue === 1) ?  ["Reference Code", "Reason", "Submitted", "Date Approved",  "Status", "Actions"] :  ["Reference Code", "Reason", "Submitted", "Date Approved",  "Status", ""];

    const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

    React.useEffect(()=>{
        setTech(props.technical.items);
    },[props.technical])


    //showEntries
	const entriesValue = (entries) => {
		setentriesValues(entries);
	}

    const ActivePage = Number(queryString.parse(props.location.search).page) || 1

  const TechSupportData = () => {
    let data = TechSupportList ?? [];
    
    return (
      <>
        {props.technical.loading === false  ? (
          data.map((item, index) => (
            <tr key={index}>
                <td>{item.refCode}</td>
                <td style={{width: '500px'}}>
                    <LinesEllipsis
                        text={item?.description}
                        maxLine='1'
                        ellipsis='...'
                        basedOn='words' />
                </td>
                <td>{moment(item.createdAt).fromNow()}</td>
                <td>{item.updatedAt ? moment(item.updatedAt).fromNow() : 'Pending'}</td>
                <td className={item.status}>
                    {
						item.status === "new" ? "In Review" : item.status &&
						item.status === "approved" ? "Completed" : item.status &&
						item.status === "rejected" ? "Rejected" : item.status &&
						item.status === "cancelled" ? "Cancelled" : item.status &&
						item.status === "on hold" ? "In Progress" : item.status &&
						item.status === "completed" ? "Closed" : item.status
					}
                </td>

                {
                  (editPermission.includes("Support - Technical Support") === true) || (roleAllTrue === 1) ?
                    <td className={secretDetails !== null ? "disabled-link" : '' }>
                            
                      <Link to={`/admin/technical-supports/review/${ActivePage}/${item._id}`}>
                        <button className="btn waves-effect waves-light" id="review">Review</button>
                      </Link>  
                    </td> : false
                }
            </tr>
          ))
        ) : (
            <>
              <tr className="table-td-data">
                <td> 
                  <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                      <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </div>
                </td>
              </tr>
            </>
          )}
      </>
    );
  };

  return (
    <AdminTechnSupportPage>
      <DynamicEntriesOnly
        entriesValue={entriesValue}
        paginationPath={"/admin/technical-supports"}
        originPage="TechnicalSupport"
        location={props.location}
      />

      <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={TechSupportData()}
        originPage="TechnicalSupport"
        data={props.technical}
        location={props.location}
        paginationPath={"/admin/technical-supports"}
        entriesItem={entriesValues}
      />
    </AdminTechnSupportPage>
  );
};


const mapStateToProps = ({technical }) => ({
    technical
});

const TechnicalSupportPage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";

  const [breadCrumbs, setBreadCrumbs] = React.useState([])

  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          { label: "Technical Support", link: "/admin/technical-supports?page=1" },
        ]);
        break;
      case "review":
        setBreadCrumbs([
          { label: "Technical Support", link: "/admin/technical-supports?page=1" },
          { label: "Review", link: "#" },
        ]);
        break;
        default:
        // Error page
        break;
      }
  }

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);


  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={
          props.mode === "review"
            ? `Review Technical Support | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : props.mode === "list"
            ? `Support | Technical Support | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : ''
        }
      >
        <PageWrapper className={cc(["TechnicalSupportPage", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage="Support"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <SwitchCase>
            <Case condition={['list'].includes(props.mode)}>
              <ContentWrapper>
                <TechSupportList {...props} />
              </ContentWrapper>
            </Case>
            <Case condition={['review'].includes(props.mode)}>
              <ReviewTech {...props } />
            </Case>
          </SwitchCase>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});


TechnicalSupportPage.WrappedComponent.displayName = "TechnicalSupportPage";
const TechnicalSupportRoute = (props) => (
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <TechnicalSupportPage {...props} mode="list" />}
    />
    <Route
      exact
      path={`${props.match.path}/review/:page/:pageId`}
      render={(props) => <TechnicalSupportPage {...props} mode="review" />}
    />
  </Switch>
);
export default React.memo(TechnicalSupportRoute);
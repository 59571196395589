//npm packages
import React from 'react';
import { useDispatch, connect } from 'react-redux';
import moment from 'moment';
import sweetAlert from 'sweetalert2';
import Swal from 'sweetalert2';
//actions
// import { userChallengeActions } from '../_core/actions/userChallenge';
//services
import { userChallengeService } from '../_core/services/userChallenge';
//images
import avatar from '../_templates/man.png'
import imgNotFound from '../_templates/img-not-found.png';
import calendarIcon from '../_templates/calendar.png';

import { statsService } from '../_core/services/stats';
import { energyActions } from '../_core/actions/energy';
import  ChallengeUpdate from "../ChallengeWidget/ChallengeUpdate";
import { notifActions } from '../_core/actions/notification';
import { questService } from '../_core/services/quest';

const toast = sweetAlert.mixin({
	toast: true,
	width: 300,
	position: 'center',
	showConfirmButton: false,
	timer: 1500
});

const mapStateToProps = ({userChallenge}) =>({
    userChallenge
});

export const DisplayQuestChallenge = connect(mapStateToProps)((props) => {
    const [challenge, setChallenge] = React.useState(props.getChallenge);
    const [timeUp, setTimeUp] = React.useState(false);
    const [stats, setStats] = React.useState({});
    const [questTitle, setQuestTitle] = React.useState('');
    const dispatch = useDispatch();
    var users = props.getUsers;
    var questId = props.questId;
    //const [isOpen,setIsOpen] = React.useState(false);
    const [showAppModal, setAppModal] = React.useState(false);// eslint-disable-lin
    
    const user = JSON.parse(localStorage.getItem('userCredentials'));
    const secret = JSON.parse(localStorage.getItem('secretCredentials'));
    const currentUserId = user && user.user._id;

    const getPoster = () => {
        const posterId = challenge?.originalPoster;
        var setPoster = [];

        posterId && users?.length > 0 && users.filter((u)=> u._id === posterId).map((u)=>{
            setPoster.push({
                _id: posterId,
                name: [
                    u.details[0]?.firstName,
                    u.details[0]?.lastName,
                    ].join(' ').trim(),
                avatar: u.details[0]?.avatar
            })
            return u
        })

        return setPoster[0]
    }

    const today = moment().utc();
    const validExpDate = moment(challenge.expiresAt).isValid() ? challenge.expiresAt : false;
    const isAccepted = challenge.status.toLowerCase() === 'accepted' ? true : false;
    const isFinished = challenge.status.toLowerCase() === 'finished' ? true : false;
    const isCompleted = challenge.status.toLowerCase() === 'completed' ? true : false;
    // const isPending = challenge.status.toLowerCase() === 'pending' ? true : false;
    const acceptedDate = moment(challenge.acceptedAt).isValid() ? moment(challenge.acceptedAt).utc() : false;
    const expiredDate = moment(challenge.expiresAt).isValid() ? moment(challenge.expiresAt).utc() : false;
    const getTimeLimitString = challenge.timeLimit !== '00:00:00' || !challenge.timeLimit ? challenge.timeLimit : false;
    const [timer, setTimer] = React.useState(getTimeLimitString);
    
    const setEndDate = () => { //get accepted date + time limit
        var getTimer;
        if(getTimeLimitString){
            if(isAccepted && acceptedDate){
                const hms_split = getTimeLimitString.split(':');
                const getTimePerSec = (+hms_split[0]) * 60 * 60 + (+hms_split[1]) * 60 + (+hms_split[2]);
                const computedEndTime = moment(acceptedDate).add(getTimePerSec, 'seconds');
                getTimer = computedEndTime;
            }else{
                getTimer = getTimeLimitString;
            }
        }else{
            getTimer = false;
        }
        return getTimer
    }

    //check expiresAt date expiration, returns text
    const getExpDate = () => {
        const currentTime = Date.parse(today);
        const dateTime = Date.parse(expiredDate);

        let getDate = expiredDate ?
            moment(currentTime).isSameOrAfter(moment(dateTime)) ?
                moment(expiredDate).utc().format('MMMM D, YYYY - hh:mm:ss') + ' Expired'
                : 'Expires ' + moment(expiredDate).utc().format('MMMM D, YYYY - hh:mm:ss') + ' PHT'
            : 'No date';

        return getDate
    };

    //get challenge image else challenge title if no image
    const challengeImage = (challenge.images ?
        <div className="img-overlay">
        <div></div>
        <img className="challenger-avatar" onError={(e)=> e.target.src = avatar} src={getPoster()?.avatar || avatar} alt="..." />
        <img className="card-img-top challenge-img" onError={(e)=> e.target.src = imgNotFound} src={challenge.images || imgNotFound} alt="..." />
        {getTimeLimitString &&
        <p className={isAccepted ? "challenge-time eng-time-on" : "challenge-time"}>
            {timer}
        </p>
        }
        </div>
        : <div className="challenge-no-images">
            <img className="challenger-avatar-no-img" onError={(e)=> e.target.src = avatar} src={getPoster()?.avatar || avatar} alt="..." />
            <h6 className="challenge-no-image-title">{challenge.title || ''}</h6>
        </div>
    );
    
    //get challenge description, if no image include timelimit
    const displayTimeNoImage = () => {
        return(
            <p className="card-text">
                {getTimeLimitString && !challenge.images &&
                <span className={isAccepted ? 'no-img-time-on' : ''}>
                    {timer}
                </span>
                }
                {getTimeLimitString && !challenge.images && ' - '}
                {challenge.description || ''}
            </p>
        );
    };

    React.useEffect(()=>{
        try{
            statsService.getOneUser(currentUserId).then(res=>{
                setStats(res.success);
            }).catch(error=>{});
        }catch(err){
            console.log(err);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[setStats, currentUserId]);

    

    React.useEffect(() => {
        const fetchQuestTitle = async () => {
            try {
                const title = await questService.getById(challenge.questId);
                setQuestTitle(title.success?.[0]?.questTitle || '');
            } catch (error) {
                console.error('Error fetching quest title:', error);
                setQuestTitle(''); // Set quest title to empty string in case of error
            }
        };

        fetchQuestTitle();
    }, [challenge.questId]);

    const acceptChallenge = () =>{
        const challengeAccept = {
            questId: questId,
            challengeId: challenge._id,
            status: "accepted",
            acceptedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        }

        const userEnergy = Array.isArray(stats) && stats.map(name => name.tips);
        
        if(challenge.eneryValue <= userEnergy[0]) {

            const energyInfo = {
                add: true,
                userId: currentUserId,
                energyType: "challenge",
                value:  -challenge.eneryValue
            }

            const notifInfo = {
                "type": "questChallenge",
                "title": questTitle,
                "content": `/admin/quests/${challenge.questId}/user/${currentUserId}/challenges`
             }

            userChallengeService.postAcceptQuestChallenge(currentUserId, challengeAccept).then(res=> {
                if(res.success){
                    setChallenge({
                        ...challenge, 
                        status: "accepted",
                        acceptedAt: challengeAccept.acceptedAt,
                        user: currentUserId
                    });
                    
                    dispatch(energyActions.update(energyInfo));
                    dispatch(notifActions.createNotif(notifInfo))

                    toast.fire({
                        type: 'success',
                        title: `Challenge accepted`
                    });
                }else{
                    toast.fire({
                        type: 'error',
                        title: `Challenge not accepted`
                    });
                }
            })
        }else{
            Swal.fire({
                title: "Insufficient Energy",
                html:
                    "<br><b class='energy-text'>Required Energy: " + challenge.eneryValue + "</b>",
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#ff0000',
                cancelButtonColor: '#d33',
                background: '#ffff',
            })
        }
    
    }

    // const completeChallenge = () => {
    //     userChallengeService.finishQuestChallenge(currentUserId, challenge._id).then(res=> {
    //         if(res.success){
    //             setChallenge({
    //                 ...challenge, 
    //                 status: "finished",
    //             });

    //             toast.fire({
    //                 type: 'success',
    //                 title: `You've finished the challenge`
    //             });
    //         }else{
    //             toast.fire({
    //                 type: 'error',
    //                 title: `Request declined`
    //             });
    //         }
    //     })
    // }

    // const quitChallenge = () => {
    //     userChallengeService.quitQuestChallenge(currentUserId, challenge._id).then(res=> {
    //         if(res.success || res.message){
    //             setChallenge({
    //                 ...challenge, 
    //                 status: "pending",
    //             });

    //             toast.fire({
    //                 type: 'success',
    //                 title: `You've quit the challenge`
    //             });
    //         }else{
    //             toast.fire({
    //                 type: 'error',
    //                 title: `Request declined`
    //             });
    //         }
    //     })
    // }

    //const updateBtnDdown = React.useRef();
    
    // const toggleDropdown = (e)=>{
    //     if(updateBtnDdown.current && updateBtnDdown.current.contains(e.target)){
    //         setIsOpen(state => !state);
    //         return
    //     }else if(e.target.id === "quit-qc" || e.target.id === "complete-qc"){

    //     }else{
    //         setIsOpen(false);
    //     }
    // }
    
    // React.useEffect(() => {
    //     document.addEventListener("mousedown", toggleDropdown);
    //     return () => {
    //       document.removeEventListener("mousedown", toggleDropdown);
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    var isSetEndDate = setEndDate();
    var interval = React.useRef(false);

    React.useEffect(() => {
        if(getTimeLimitString){
            interval.current = setInterval(()=> {
                if (isAccepted) {
                    if(acceptedDate && currentUserId === challenge.user){
                        var now = moment().utc();
                        var hours = parseInt(moment.duration(moment(isSetEndDate).diff(now)).asHours());
                        var minutes = parseInt(moment.duration(moment(isSetEndDate).diff(now)).asMinutes()) % 60;
                        var seconds = parseInt(moment.duration(moment(isSetEndDate).diff(now)).asSeconds()) % 60;

                        const countdown = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');
                        setTimer(countdown)

                        if (moment(now).isSameOrAfter(moment(isSetEndDate))) {
                            setTimeUp(true)
                            setTimer('00:00:00');
                        }
                    }else{
                        if(expiredDate && moment(now).isSameOrAfter(moment(expiredDate))){
                            setTimer('00:00:00');
                        }else{
                            setTimer(getTimeLimitString);
                        }
                    }
                }else{
                    if(expiredDate && moment(now).isSameOrAfter(moment(expiredDate))){
                        setTimer('00:00:00');
                    }else{
                        setTimer(getTimeLimitString);
                    }
                }
            }, 1000)
        }
        return () => clearInterval(interval.current);
    }, [challenge, expiredDate, getTimeLimitString, isSetEndDate, acceptedDate, isAccepted, currentUserId]);

    const handleChallenge = () => {
        setAppModal(true);
    }
    const handleCloseModal =()=> {
        setAppModal(false)
    }
    const handleLoadData =(load)=> {
        setChallenge({
            ...challenge, 
            status: load[0].status
        })
    }


    // GET ASSIGNED BUTTON
    var displayButton = () => {
        if (isAccepted) {
            if (currentUserId === challenge.user) {
                if(moment(today).isSameOrAfter(moment(expiredDate)) || timeUp){
                    return (
                        <button className="eng-ongoing-btn eng-text-center">
                            <p><b>FAILED - CHALLENGE TIME LIMIT IS UP</b></p>
                        </button>
                    );
                }else{
                    return (
                        <div className="qc-update">
                             {/*<button className="btn eng-update-btn" ref={updateBtnDdown}> Update</button>
                            {isOpen &&
                            <div className="qc-update-opt">
                                <div className="qc-opt" id="complete-qc" onClick={()=> completeChallenge()}>Finish</div>
                                <div className="qc-opt" id="quit-qc" onClick={()=> quitChallenge()}>Quit</div>
                            </div>
                            } */}
                            <button className="btn eng-update-btn" onClick={handleChallenge}>Update</button>
                                <ChallengeUpdate
                                    isOpen={showAppModal} 
                                    onRequestClose={() => setAppModal(false)} 
                                    toggle={setAppModal}
                                    handleCloseModal={handleCloseModal}
                                    challengeId={challenge._id}
                                    challengeTitle={challenge.title}
                                    challengeType="questChallenge"
                                    challengerId={currentUserId}
                                    questTitle={questTitle}
                                    questId ={challenge.questId}
                                    loadQuest={handleLoadData}
                                />
                        </div>
                    )
                }
            }
        } else if(isFinished){
            return(
                <button className="btn eng-finish-btn" style={{backgroundColor:"#D2534D", cursor:'default'}}>
                    <p>Finished</p>
                </button>
            );
        
        // else if(isFinished){
        //     return(
        //         <button className="btn eng-finish-btn">
        //             <p>Finished</p>
        //         </button>
        //     );
        // }else if(isPending){
        //     return(
        //         <button className="btn eng-accept-btn" onClick={()=>acceptChallenge()}>
        //             <p>Restart</p>
        //         </button>
        //     );
        }else if(isCompleted){
            return(
                <button className="btn eng-finish-btn" style={{backgroundColor:"#006C0B", cursor:'default'}}>
                    <p>Completed</p>
                </button>
            );
        }else if (moment(today).isSameOrAfter(moment(expiredDate))){
            return(
                <button className="eng-ongoing-btn eng-text-center">
                    <p><b>CHALLENGE HAS ALREADY EXPIRED</b></p>
                </button>
            );
        } else if(secret !== null) {
            return (<button className="btn eng-accept-btn" style={{opacity:'0.7', pointerEvents:'none'}}>Accept</button>);
        }else{
            return (<button className="btn eng-accept-btn" onClick={()=>acceptChallenge()}>Accept</button>);
        }
    }

    const challengeDateCreated = moment(challenge.createdAt).utc().fromNow(true);
    const challengePosterName = getPoster()?.name ||' No name specified';
    
    //RETURN CARD DISPLAY
    return (
        <div key={challenge._id} 
            className={currentUserId === challenge.user && isAccepted ? 
                getExpDate().includes('Expired') ? 
                    "card qc-card eng-update-expd-card" 
                    :"card qc-card eng-update-card" 
                : "card qc-card"}>
            
            {challengeImage}
            <div className="card-body qc-details">
                {validExpDate &&
                <p className="qc-expires">
                    <img src={calendarIcon} alt="..." />F
                    <span className={getExpDate().includes('Expired') ? 'expired-date' : ''}>
                        {getExpDate()}
                    </span>
                </p>
                }
                {challenge.images ? <h6 className="card-title">{challenge.title || ''}</h6> : ''}
                {displayTimeNoImage()}
                {displayButton()}
                <br />
                <i><small>Posted <b>{challengeDateCreated}</b> ago by
                <b> {challengePosterName}</b></small></i>
            </div>
        </div>
    );
});
import React from 'react';
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js';
import ReactImageFallback from 'react-image-fallback';
import './SideMenu.sass';
// import { history } from '../../_core/helpers/history';
// import Loading from '../../Components/Ellipsis-1s-60px.svg';

import avatar from '../../_templates/man.png';
import pragmaLogo_admin from '../../_templates/One-liner.png';
import pragmaLogo2 from '../../_templates/Logo.png';

import burgerAdminLite from '../../_templates/burgerAdminLite.png';
import notifHeader from '../../_templates/notif-admin-header.png';
import dropdown_chevrondown from '../../_templates/dropdown_chevrondown.png';
import dropdown_chevronup from '../../_templates/dropdown_chevronup.png';

import AccessManagementDefault from '../../_templates/adminIcon/accessManagement.png';
import AdminAnnouncementIconDefault from '../../_templates/adminIcon/AdminAnnouncementIcon.png';
import dashboardDefault from '../../_templates/adminIcon/dashboardDefault.png';
import leaveManagementDefault from '../../_templates/adminIcon/leaveManagement.png';
import logoutDefault from '../../_templates/adminIcon/logout.png';
import pageManagementDefault from '../../_templates/adminIcon/pageManagement.png';
import requestManagementDefault from '../../_templates/adminIcon/requestManagement.png';
import settingsDefault from '../../_templates/adminIcon/settings.png';
import supportManagementDefault from '../../_templates/adminIcon/supportManagement.png';
import engagementManagementDefault from '../../_templates/adminIcon/engagementManagement.png';
import teamManagementDefault from '../../_templates/adminIcon/teamManagement.png';
import AppraisalDefault from '../../_templates/adminIcon/AppraisalDefault.png';
import timeKeepingDefault from '../../_templates/adminIcon/timeKeeping.png';
// import viewLogsDefault from '../../_templates/adminIcon/viewLogs.png';

import dashboardPressed from '../../_templates/adminIcon/pressed/dashboardPressed.png';
import announcementPressed from '../../_templates/adminIcon/pressed/announcementPressed.png';
import accessManagementPressed from '../../_templates/adminIcon/pressed/accessManagementPressed.png';
import timeKeepingPressed from '../../_templates/adminIcon/pressed/timeKeepingPressed.png';
import leaveManagementPressed from '../../_templates/adminIcon/pressed/leaveManagementPressed.png';
import requestManagementPressed from '../../_templates/adminIcon/pressed/requestManagementPressed.png';
import supportManagementPressed from '../../_templates/adminIcon/pressed/supportManagementPressed.png';
import engagementManagementPressed from '../../_templates/adminIcon/pressed/engagementManagementPressed.png';
import teamManagementPressed from '../../_templates/adminIcon/pressed/teamManagementPressed.png';
import AppraisalPressed from '../../_templates/adminIcon/pressed/AppraisalPressed.png';
import pageManagementPressed from '../../_templates/adminIcon/pressed/pageManagementPressed.png';
import settingsPressed from '../../_templates/adminIcon/pressed/settingsPressed.png';
// import viewLogsPressed from '../../_templates/adminIcon/pressed/viewLogsPressed.png';
import logoutPressed from '../../_templates/adminIcon/pressed/logoutPressed.png';

import { userActions } from '../../_core/actions/user';

import { permissions } from '../../_core/helpers/permissions';

// import { permissionActions } from '../../_core/actions/permission';

// import  { hasLeaveFilingsView,view }  from './checkRolePermission';

//Notification Socket.io
import io from 'socket.io-client';
import { notifService } from '../../_core/services/notification';
import moment from 'moment';



class SideMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      render: true,
      imageHash: Date.now(),
      navIsOpen: true,
      isShowPragmaIcon: false,
      isHover: false,
      isPressed: false,
      prevPressed: null,
      isDashboard: true,
      isChevronUp: false,
      menuIconList: [
        {
          iconName: 'dashboard_icon',
          imgPressed: dashboardPressed,
          imgDefault: dashboardDefault,
          labelsubMenu: 'dashboard_subMenu',
          labelMenu: 'dashboard_subMenu',
          isChevronUp: false,
        },
        {
          iconName: 'announcement_icon',
          imgPressed: announcementPressed,
          imgDefault: AdminAnnouncementIconDefault,
          labelsubMenu: 'announcement_subMenu',
          labelMenu: 'announcement_menu',
          isChevronUp: false,
        },
        {
          iconName: 'accessManagemement_icon',
          imgPressed: accessManagementPressed,
          imgDefault: AccessManagementDefault,
          label: 'accessManagement_subMenu',
          isChevronUp: false,
        },
        {
          iconName: 'timeKeeping_icon',
          imgPressed: timeKeepingPressed,
          imgDefault: timeKeepingDefault,
          labelsubMenu: 'timeKeeping_subMenu',
          isChevronUp: false,
        },
        {
          iconName: 'leaveManagement_icon',
          imgPressed: leaveManagementPressed,
          imgDefault: leaveManagementDefault,
          label: 'leaveManagement_subMenu',
          isChevronUp: false,
        },
        {
          iconName: 'requestManagement_icon',
          imgPressed: requestManagementPressed,
          imgDefault: requestManagementDefault,
          label: 'requestManagement_subMenu',
          isChevronUp: false,
        },
        {
          iconName: 'supportManagement_icon',
          imgPressed: supportManagementPressed,
          imgDefault: supportManagementDefault,
          label: 'supportManagement_subMenu',
          isChevronUp: false,
        },
        {
          iconName: 'engagementtManagement_icon',
          imgPressed: engagementManagementPressed,
          imgDefault: engagementManagementDefault,
          label: 'engagementManagement_subMenu',
          isChevronUp: false,
        },
        {
          iconName: 'appraisalManagement_icon',
          imgPressed: AppraisalPressed,
          imgDefault: AppraisalDefault,
          label: 'appraisal_subMenu',
          isChevronUp: false,
        },
        {
          iconName: 'teamManagementPage_icon',
          imgPressed: teamManagementPressed,
          imgDefault: teamManagementDefault,
          isChevronUp: false,
        },
        {
          iconName: 'pageManagement_icon',
          imgPressed: pageManagementPressed,
          imgDefault: pageManagementDefault,
          isChevronUp: false,
        },
        {
          iconName: 'settings_icon',
          imgPressed: settingsPressed,
          imgDefault: settingsDefault,
          isChevronUp: false,
        },
        // {
        //   iconName: 'viewLogs_icon',
        //   imgPressed: viewLogsPressed,
        //   imgDefault: viewLogsDefault,
        //   isChevronUp: false,
        // },
        {
          iconName: 'logOut_icon',
          imgPressed: logoutPressed,
          imgDefault: logoutDefault,
          isChevronUp: false,
        },
      ],
      notifications: [],
      showNotifications: false
      
    };
    this.socket = io(process.env.REACT_APP_PRAGMAHR_API);
    this.handleSlideMenu = this.handleSlideMenu.bind(this);
    this.getTriggerCollapse = this.getTriggerCollapse.bind(this);
    this.isHoverMenu = this.isHoverMenu.bind(this);
    // this.getPermissions = this.props.getPermissions.bind(this);
  }
  componentDidMount() {
    //dropdown menu
    var elem = document.querySelector('.navigationsList');
    elem = document.querySelector('.collapsible.expandable');
    M.Collapsible.init(elem, {
      accordion: true,
    });

    let elems = document.querySelectorAll('.dropdown-trigger');
    M.Dropdown.init(elems, { inDuration: 300, outDuration: 225 });

    window.addEventListener('resize', this.handleResize);

    // Show Menu in First Load
    let showMenu = true;
    let slideMenu = document.querySelector('.SideBarSlide');
    let menu = document.querySelector('.SideMenuComponents');
    let UserImageRight = document.querySelector('.UserImage');
    let y =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (menu) {
      if (menu.style.width === '260px') {
        localStorage.setItem(
          'navIsOpen',
          JSON.stringify(!this.state.navIsOpen)
        );
      } else {
        localStorage.setItem('navIsOpen', JSON.stringify(this.state.navIsOpen));
      }
    }
    if (showMenu) {
      if (y <= 768 && showMenu) {
        slideMenu.style.paddingLeft = '0px';
        slideMenu.style.transition = '0.4s';
        menu.style.transition = '0.4s';
        UserImageRight.style.paddingRight = '18px';
        menu.style.zIndex = '999';
        menu.style.position = 'absolute';
        menu.style.width = '260px';
        menu.style.paddingLeft = '0px';
        // menu.style.color = "#333";
        menu.style.opacity = '1';
      }
      if (y > 768 && showMenu) {
        menu.style.position = 'fixed';
        //UserImageRight.style.paddingRight = "268px";
        UserImageRight.style.paddingRight = '30px';
        slideMenu.style.paddingLeft = '260px';
        menu.style.width = '260px';
        menu.style.paddingLeft = '0px';
        menu.style.opacity = '1';
      }
      showMenu = false;
    }
    // End First Load
    // this.props.getPermissions();

    /****** Check socket connection *****/
    this.socket.on('connect', () => {
        console.log('Socket connected');
    });

    // Log errors
    this.socket.on('error', (error) => {
        console.error('Socket error:', error);
    });

    // Listen for 'customEvent' event
    this.socket.on('customEvent', (notification) => {
        console.log('Received new notification:', notification);
        // Update notifications state with the new notification
        this.setState((prevState) => ({
            notifications: [...prevState.notifications, notification]
        }));
    });
    this.fetchNotifications();
    this.pollForNotifications();
  }

  // check(){this.props.getPermissionsgit()}

  componentWillUnmount() {
    // Clean up event listener and disconnect socket when component unmounts
    this.socket.off('customEvent');
    this.socket.disconnect();
}

  handleSlideMenu(e) {
    // e.preventDefault();
    /* eslint-enable */
    localStorage.setItem('navIsOpen', JSON.stringify(!this.state.navIsOpen));
    this.setState(
      {
        navIsOpen: !this.state.navIsOpen,
        isShowPragmaIcon: !this.state.isShowPragmaIcon,
      },
      () => {
        localStorage.setItem('navIsOpen', JSON.stringify(this.state.navIsOpen));

        let slideMenu = document.querySelector('.SideBarSlide');
        let menu = document.querySelector('.SideMenuComponents');

        if (menu.style.width === '260px') {
          menu.style.position = 'fixed';
          slideMenu.style.paddingLeft = '80px';
          menu.style.paddingLeft = '-180px';
          menu.style.width = '80px';
        } else {
          menu.style.position = 'fixed';
          slideMenu.style.paddingLeft = '260px';
          menu.style.width = '260px';
          menu.style.paddingLeft = '0px';
        }
      }
    );
  }

  handlelogout() {
    userActions.logout();
  }

  handleImpersonate() {
    userActions.stopImpersonate();
  }

  showSubSideMenu(origin) {}

  hideSubSideMenu(origin) {}

  subMenuFilter(isHovered = false, origin = null) {
    let sideMenu =
      !!document.querySelector('.SideMenuComponents') &&
      document.querySelector('.SideMenuComponents');
    if (!!sideMenu) {
      let getStyle = getComputedStyle(
        document.querySelector('.SideMenuComponents')
      );
      if (getStyle.width === '80px') {
        this.showSubSideMenu(origin);
      }
      if (getStyle.width === '260px') {
        this.hideSubSideMenu(origin);
      }
    }
  }

  isHoverMenu(isEnter, iconName) {
    const { menuIconList, prevPressed } = this.state;

    if (isEnter === true) {
      //MouserHover
      menuIconList.map((menuIcon) => {
        if (menuIcon.iconName === iconName) {
          if (
            document.getElementById(menuIcon.iconName).src !==
            menuIcon.imgPressed
          ) {
            document.getElementById(menuIcon.iconName).src =
              menuIcon.imgPressed;
            this.state.isHover = true; //eslint-disable-line
            this.forceUpdate();
          }
        }
        return null;
      });

      this.subMenuFilter(true, iconName);
    } else {
      //Mouseleave
      menuIconList.map((menuIcon) => {
        if (menuIcon.iconName === iconName) {
          if (prevPressed !== menuIcon.iconName) {
            document.getElementById(menuIcon.iconName).src =
              menuIcon.imgDefault;
            this.state.isHover = false; //eslint-disable-line
            this.forceUpdate();
          }
        }
        return null;
      });

      this.subMenuFilter(false, iconName);
    }
  }

  getTriggerCollapse(selected) {
    
    const { menuIconList, prevPressed } = this.state;
    const elementsIndex = menuIconList.findIndex(
      (icon) => icon.iconName === selected
    );
    let newArray = [...menuIconList];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      isChevronUp: !newArray[elementsIndex].isChevronUp,
    };
    let elementsIndex2 = menuIconList.findIndex(
      (icon) => icon.isChevronUp === true
    );

    if (elementsIndex2 > -1) {
      // Check if has previous selected
      // Reset the chevRon of previous selected
      newArray[elementsIndex2] = {
        ...newArray[elementsIndex2],
        isChevronUp: false,
      };
    }

    menuIconList.map((menuIcon) => {
      if (menuIcon.iconName === selected) {
        if (document.getElementById(menuIcon.iconName) !== null) {
          this.setState(
            {
              prevPressed: menuIcon.iconName,
              isPressed:
                prevPressed === null || prevPressed === selected ? true : false,
              menuIconList: newArray,
            },
            () => {
              document.getElementById(menuIcon.iconName).src =
                menuIcon.imgPressed;
              this.forceUpdate();
            }
          );
        }
      } else {
        menuIconList.map((menuIcon) => {
          if (menuIcon.iconName !== selected) {
            document.getElementById(menuIcon.iconName).src =
              menuIcon.imgDefault;
            this.forceUpdate();
          }
          return null;
        });
      }
      return null;
    });
  }

  selectedOption(url, iconName) {
    const { menuIconList } = this.state;
    var urlCheck = window.location.pathname.toString();
    var selectedUrl = url;

    if (urlCheck.indexOf(selectedUrl) > -1) {
      menuIconList.map((menuIcon) => {
        if (menuIcon.iconName === iconName) {
          if (document.getElementById(menuIcon.iconName) !== null) {
            document.getElementById(menuIcon.iconName).src =
              menuIcon.imgPressed;
          }
        }
        return null;
      });
      return 'option-selected';
    } else {
      return '';
    }
  }

    fetchNotifications = () => {
        notifService.getNotif(1,9999)
            .then(data => {
                console.log('Fetched notifications from API:', data.items);
                // Update notifications state with the fetched notifications
                this.setState({ notifications: data.items });
            })
            .catch(error => {
                console.error('Error fetching notifications from API:', error);
            });
    }

    pollForNotifications = () => {
        // Periodically poll for new notifications
        setInterval(this.fetchNotifications, 5000); // Adjust interval as needed
    }

  toggleNotifications = () => {
    this.setState((prevState) => ({
        showNotifications: !prevState.showNotifications
    }));
}

formatDate = (date) => {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const lastWeek = moment().subtract(7, 'days').startOf('day');

    const formattedDate = moment(date);

    if (formattedDate.isSame(today, 'day')) {
        return 'Today, ' + formattedDate.format('LT');
    } else if (formattedDate.isSame(yesterday, 'day')) {
        return 'Yesterday, ' + formattedDate.format('LT');
    } else if (formattedDate.isAfter(lastWeek)) {
        return formattedDate.format('dddd, MMMM D, YYYY, LT');
    } else {
        return formattedDate.format('dddd, MMMM D, YYYY, LT');
    }
}

// Function to handle click on notification link
handleNotificationClick = (notificationId) => {
    // Update the isRead field in the backend or in your notification state
    // For example, you can send an API request to update the isRead field
    // Assuming you have a function to update the notification state
    this.updateNotificationReadStatus(notificationId);
}

// Function to update notification read status
updateNotificationReadStatus = (notificationId) => {
    // Update the notification's isRead field to 1
    // This can be done through an API call to your backend
    let body ={
         isRead: 1
    }
    notifService.updateNotif(notificationId, body)
        .then(data => {
            console.log('Fetched notifications from API:', data.items);
            // Update notifications state with the fetched notifications
        })
        .catch(error => {
            console.error('Error marking notification as read:', error);
        });
}

handleLinkClick = (event, url) => {
    const { location } = this.props;
    const currentUrl = location.pathname + location.search + location.hash; // Include query parameters and hash fragment

    if (currentUrl === url) {
        event.preventDefault();
        //console.log('Prevented default');
    } else {
        window.location.href = url;
        //console.log('Navigated to:', url);
    }
}

  render() {
    let user = JSON.parse(localStorage.getItem('user'));
    let secretDetails = JSON.parse(localStorage.getItem('secretDetails'));
    let unknown = secretDetails !== null ? secretDetails : user;

    const viewPermission = JSON.parse(localStorage.getItem('viewPermission'));
    const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

    const isPermitted =(url) => viewPermission.includes(permissions.filter(item => url === item.url)?.[0]?.permissionName) || (roleAllTrue === 1);

    const { notifications } = this.state;

    const { imageHash } = this.state;

    return (
      <div className='MainSideMenu SideBarSlide'>
        {/* {console.log(this.props.permissions.items)} */}
        {/* Navbar */}
        <nav className='navbar-fixed-custom' style={{ height: '50px' }}>
          <div>
            <Link
              to='#'
              onClick={this.handleSlideMenu}
              className='header-burger'
            >
              <img src={burgerAdminLite} alt='' height='18px' width='18px' />
            </Link>
            <div className='header-search'>
              <input className='adminSearch' type='text' placeholder='Search' />
            </div>

            <ul
              className='right valign-wrapper'
              style={{ minWidth: '210px', maxWidth: '400px' }}
            >
              <div className='valign-wrapper UserImage'>
              {secretDetails ? <i className="fa fa-user-secret" aria-hidden="true" style={{fontSize:'30px', color:'#F46B6B', display:'contents'}}></i> : ''}
                {/* <span className='settings-admin-header'>
                  <img
                    src={notifHeader}
                    className='notifHeader'
                    alt=''
                    style={{ cursor: 'pointer' }}
                  />
                </span> */}
                <div style={{ position: 'relative' }}>
                    <span className='dropdown-trigger settings-admin-header bellnotif' style={{ position: 'relative', overflow: 'inherit'}} data-target='dropdown2'>
                        <img
                            src={notifHeader}
                            className='notifHeader'
                            alt=''
                            style={{ cursor: 'pointer' }}
                            data-target='dropdown2'
                        />
                        {notifications.filter(notification => notification.isRead === 0).length > 0 && (
                            <div className="notification-badge">
                                <span className="badge-content">{notifications.filter(notification => notification.isRead === 0).length}</span>
                            </div>
                        )}
                    </span>
                    {/* {showNotifications && (
                        <div className="notification-dropdown">
                            <ul>
                                {notifications.map((notification, index) => (
                                    <li key={index}>{notification.title}</li>
                                ))}
                            </ul>
                        </div>
                    )} */}
                </div>
                <span style={{ marginTop: '10px' }}>
                  <ReactImageFallback
                    src={`${unknown.avatar}?${imageHash}`}
                    fallbackImage={avatar}
                    initialImage={avatar}
                    alt='user avatar'
                    className='dropdown-trigger profile-img-admin'
                    style={{ cursor: 'default' }}
                  />
                </span>
                <span
                  className='adminTitleHeader'
                  style={{ marginLeft: '10px', color: '' }}
                >
                  {unknown.firstName}
                </span>
                <span
                  className='dropdown-trigger settings-admin-header '
                  data-target='dropdown1'
                >
                  <img
                    src={settingsDefault}
                    height='20px'
                    width='20px'
                    data-target='dropdown1'
                    alt=''
                  />
                </span>
              </div>
            </ul>

            <ul
              id='dropdown1'
              className='dropdown-content dropdown-content-admin-dark'
            >
              <li className='admin-dark'>
                <Link to={`/profile/${unknown.user_id}`}>Profile</Link>
              </li>
              <li className={secretDetails ? 'disabled-link admin-dark' : 'admin-dark'}>
                <Link to={secretDetails ? '#' : "/edit-profile"}>Edit Profile</Link>
              </li>
              <li className={secretDetails ? 'disabled-link admin-dark' : 'admin-dark'}>
                <Link to={secretDetails ? '#' : "/changepassword"}>Change password</Link>
              </li>
              {
				secretDetails ?
				<li className='admin-dark'>
					<Link onClick={this.handleImpersonate} to="/">Stop Impersonate</Link>
				</li>
				:
				''
			  }
              <li className='admin-dark'>
                <Link to='/' onClick={this.handlelogout}>
                  {' '}
                  Logout
                </Link>
              </li>
            </ul>
            

            <ul
                id='dropdown2'
                className='dropdown-content dropdown-content-admin-dark notifDiv'
                style={{ height: 0, overflowY: 'auto', maxHeight: '300px', 'msOverflowStyle': 'none', 'scrollbarWidth': 'none' }} // Add scrollbar and set max height
            >
                {notifications.length === 0 ? (
                    <li className='admin-dark' style={{wordBreak:'break-word', color:'white', fontSize:'14px', padding:'10px', textAlign:'center'}}>No notifications</li>
                ) : (
                    notifications.map((notification, index) => (
                        <Link key={index} to='#' onClick={(event) => this.handleLinkClick(event, notification.content)}>
                            <li  className='admin-dark' onClick={() => this.handleNotificationClick(notification._id)}>
                                <div className="notification-item" style={{ display: 'block', padding: '10px' }}>
                                    <div style={{display:'flex'}}>
                                        <div className="notification-avatar" style={{ marginRight: '10px' }}>
                                            <ReactImageFallback
                                                src={notification?.userDetails?.[0]?.avatar}
                                                fallbackImage={avatar}
                                                initialImage={avatar}
                                                alt='user avatar'
                                                className='dropdown-trigger profile-img-admin'
                                                style={{ cursor: 'default', width: '35px', height: '35px', borderRadius: '50%', borderColor:'#ffffff', objectFit:'cover' }}
                                            />
                                        </div>
                                        <div className="notification-details" style={{ flex: 1 , wordBreak:'break-word', color:'white', fontSize:'14px'}}>
                                            {
                                                notification.type === 'vacation' ?
                                                    `${notification?.userDetails?.[0]?.firstName} ${notification?.userDetails?.[0]?.lastName} sent a request for Vacation Leave.`
                                                : notification.type === 'medical' ?
                                                    `${notification?.userDetails?.[0]?.firstName} ${notification?.userDetails?.[0]?.lastName} sent a request for Sick Leave.`
                                                : notification.type === 'certificate of employment' ?
                                                    `${notification?.userDetails?.[0]?.firstName} ${notification?.userDetails?.[0]?.lastName} sent a request for Certificate of Employment.`
                                                : notification.type === 'overtime' ?
                                                    `${notification?.userDetails?.[0]?.firstName} ${notification?.userDetails?.[0]?.lastName} sent a request for Overtime.`
                                                : notification.type === 'undertime' ?
                                                    `${notification?.userDetails?.[0]?.firstName} ${notification?.userDetails?.[0]?.lastName} sent a request for Undertime.`
                                                : notification.type === 'hr concern' ?
                                                    `${notification?.userDetails?.[0]?.firstName} ${notification?.userDetails?.[0]?.lastName} sent a request for Hr Concern.`      
                                                : notification.type === 'technical support' ?
                                                    `${notification?.userDetails?.[0]?.firstName} ${notification?.userDetails?.[0]?.lastName} sent a request for Technical Support.`    
                                                : notification.type === 'feedback' ?
                                                    `${notification?.userDetails?.[0]?.firstName} ${notification?.userDetails?.[0]?.lastName} sent a request for Feedback.`  
                                                : notification.type === 'questChallenge' ?
                                                    `${notification?.userDetails?.[0]?.firstName} ${notification?.userDetails?.[0]?.lastName} accepted the quest challenge for the quest title "${notification.title}".`   
                                                : notification.type === 'questChallengeFinish' ?
                                                    `${notification?.userDetails?.[0]?.firstName} ${notification?.userDetails?.[0]?.lastName} has finished the quest challenge for the quest titled "${notification.title}". Please review and mark it as completed or failed.`                                          
                                                : ''

                                                
                                            }

                                        </div>
                                    </div>

                                    <div style={{ color: '#737373', fontSize: '12px', marginTop: '0px', position:'relative', display:'flex', justifyContent:'center' }}>
                                        <p style={{marginRight:'10px'}}>{this.formatDate(notification.createdAt)}</p>
                                        <p>{notification.isRead ? 'Read' : 'Unread'}</p>
                                    </div>
                                    
                                    
                                </div>
                            </li>
                        </Link>
                    ))
                )}
            </ul>
          </div>
        </nav>
        {/* End Navbar */}

        {/* SideMenu  */}
        <div className='SideMenuComponents'>
          <div
            className='header-pragmahr-icon-container'
            style={{
              opacity: this.state.isShowPragmaIcon === true ? '1' : '0',
              display: this.state.isShowPragmaIcon === true ? 'flex' : 'none',
            }}
          >
            <a href='/admin/dashboard'>
              <img
                src={pragmaLogo2}
                alt=''
                height='25px'
                width='25px'
              ></img>
            </a>
          </div>

          <p className='sub-header'>
            <Link
              to='/admin/dashboard'
              style={{
                display:
                  this.state.isShowPragmaIcon === false ? 'block' : 'none',
              }}
            >
              <img
                src={pragmaLogo_admin}
                alt=''
                height='50px'
                width='50px'
                style={{ marginRight: '20px' }}
              ></img>
              {/* <span style={{ marginLeft: '20px' }}> PRAGMAHR</span>{' '} */}
            </Link>
          </p>

          <p
            className='sub-header-menu'
            style={{
              display: this.state.isShowPragmaIcon === false ? 'block' : 'none',
            }}
          >
            <Link to='/admin/dashboard'> MENU </Link>
          </p>

          <ul className='collapsible expandable navigationsList'>
            <li
              onMouseEnter={(e) => this.isHoverMenu(true, 'dashboard_icon')}
              onMouseLeave={(e) => this.isHoverMenu(false, 'dashboard_icon')}
              // style={{
              //   display:
              //   (viewPermission.includes("Dashboard") === true) || (roleAllTrue === 1) ? 'block' : 'none',
              // }}
            >
              <div className='custom-menu'>
                <Link
                  // to="#"
                  to='/admin/dashboard'
                  onClick={(e) => this.getTriggerCollapse('dashboard_icon')}
                >
                  <img id='dashboard_icon' src={dashboardDefault} alt=''></img>
                </Link>
                <p className='darkmode-font-color'>
                  <Link
                    to='/admin/dashboard'
                    onClick={(e) => this.getTriggerCollapse('dashboard_icon')}
                    className={this.selectedOption(
                      '/admin/dashboard',
                      'dashboard_icon'
                    )}
                  >
                    Dashboard
                  </Link>
                </p>
              </div>
            </li>
            <li
              onMouseEnter={(e) => this.isHoverMenu(true, 'announcement_icon')}
              onMouseLeave={(e) => this.isHoverMenu(false, 'announcement_icon')}
              style={{
                display:
                isPermitted("/admin/announcement") ? 'block' : 'none',
              }}
            >
              <div className='custom-menu'>
                <Link
                  to='/admin/announcement?page=1'
                  onClick={(e) => this.getTriggerCollapse('announcement_icon')}
                >
                  <img
                    id='announcement_icon'
                    src={AdminAnnouncementIconDefault}
                    alt=''
                  ></img>
                </Link>
                <p className='darkmode-font-color'>
                  <Link
                    onClick={(e) =>
                      this.getTriggerCollapse('announcement_icon')
                    }
                    to='/admin/announcement?page=1'
                    className={this.selectedOption(
                      '/admin/announcement',
                      'announcement_icon'
                    )}
                  >
                    Announcements
                  </Link>
                </p>
              </div>
            </li>
            {/* <li onMouseEnter={e => this.isHoverMenu(true, "announcement_icon")} onMouseLeave={e => this.isHoverMenu(false, "announcement_icon")}>
              <div className="collapsible-header" onClick={(e) => this.getTriggerCollapse("announcement_icon")}>

                <Link
                  // to="#"
                  to="/admin/announcement?page=1"
                  onClick={(e) => this.getTriggerCollapse("announcement_icon")}
                >
                  <img id="announcement_icon" src={AdminAnnouncementIconDefault} alt='' />
                </Link>
                <p id="admin-sideMenu-collapse-header">Announcement</p>
                <img src={this.state.menuIconList[1].isChevronUp === false ? dropdown_chevrondown : dropdown_chevronup} style={{ marginLeft: "55px", height: "14px", width: "14px" }} alt='' />
              </div> */}
            {/* <ul className="collapsible-body-sideMenu" id="announcement_subMenu">
                <li onClick={(e) => this.getTriggerCollapse("announcement_icon")}><Link to="/admin/announcement?page=1" className={this.selectedOption("/admin/announcement?page=1", "announcement_icon")} >List of Announcement</Link> </li>
                <li onClick={(e) => this.getTriggerCollapse("announcement_icon")}><Link to="/admin/announcement/create" className={this.selectedOption("/admin/announcement/create", "announcement_icon")} >Post announcement</Link></li>
              </ul>

              <ul className="collapsible-body">
                <li onClick={(e) => this.getTriggerCollapse("announcement_icon")}><Link to="/admin/announcement?page=1" className={this.selectedOption("/admin/announcement", "announcement_icon")} >List of Announcement</Link> </li>
                <li onClick={(e) => this.getTriggerCollapse("announcement_icon")}><Link to="/admin/announcement/create" className={this.selectedOption("/admin/announcement/create", "")} >Post announcement</Link></li>
              </ul>
            </li> */}
            <li
              onMouseEnter={(e) =>
                this.isHoverMenu(true, 'accessManagemement_icon')
              }
              onMouseLeave={(e) =>
                this.isHoverMenu(false, 'accessManagemement_icon')
              }
              style={{
                display:
                (viewPermission.includes("Access - Users") === true) || (viewPermission.includes("Access - Roles") === true) || (viewPermission.includes("Access - Permissions") === true) || (viewPermission.includes("Access - Attributes") === true)  || (roleAllTrue === 1) ? 'block' : 'none',
              }}
            >
              <div
                className='collapsible-header'
                onClick={(e) =>
                  this.getTriggerCollapse('accessManagemement_icon')
                }
              >
                <Link
                  to={'/admin/users?page=1'}
                  onClick={(e) =>
                    this.getTriggerCollapse('accessManagemement_icon')
                  }
                  // to="#"
                  className={this.selectedOption('/admin/user')}
                >
                  <img
                    id='accessManagemement_icon'
                    src={AccessManagementDefault}
                    alt=''
                  ></img>
                </Link>
                <p id='admin-sideMenu-collapse-header'>Access</p>
                <img
                  src={
                    this.state.menuIconList[2].isChevronUp === false
                      ? dropdown_chevrondown
                      : dropdown_chevronup
                  }
                  style={{ marginLeft: '104px', height: '14px', width: '14px' }}
                  alt=''
                />
              </div>
              <ul className='collapsible-body'>
                <li
                  onClick={(e) =>
                    this.getTriggerCollapse('accessManagemement_icon')
                  }
                  style={{
                    display:
                    isPermitted("/admin/users") ? 'block' : 'none',
                  }}
                >
                  <Link
                    to='/admin/users?page=1'
                    className={this.selectedOption(
                      '/admin/user',
                      'accessManagemement_icon'
                    )}
                  >
                    Users
                  </Link>
                </li>
                <li
                  onClick={(e) =>
                    this.getTriggerCollapse('accessManagemement_icon')
                  }
                  style={{
                    display:
                    (viewPermission.includes("Access - Roles") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                  }}
                >
                  <Link
                    to='/admin/roles?page=1'
                    className={this.selectedOption(
                      '/admin/role',
                      'accessManagemement_icon'
                    )}
                  >
                    Roles
                  </Link>
                </li>
                <li
                  onClick={(e) =>
                    this.getTriggerCollapse('accessManagemement_icon')
                  }
                  style={{
                    display:
                    (viewPermission.includes("Access - Permissions") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                  }}
                >
                  <Link
                    to='/admin/permissions?page=1'
                    className={this.selectedOption(
                      '/admin/permission',
                      'accessManagemement_icon'
                    )}
                  >
                    Permissions
                  </Link>
                </li>
                <li
                  onClick={(e) =>
                    this.getTriggerCollapse('accessManagemement_icon')
                  }
                  style={{
                    display:
                    (viewPermission.includes("Access - Attributes") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                  }}
                >
                  <Link
                    to='/admin/attributes?page=1'
                    className={this.selectedOption(
                      '/admin/attribute',
                      'accessManagemement_icon'
                    )}
                  >
                    Attributes
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li><Link to="#"> Location Management </Link></li> */}
            {/* <li><Link to="#"> Quest Management </Link></li> */}
            {/* <li><Link to="#"> Rewards </Link> </li>
            <li><Link to="#"> Achievements </Link></li> */}
            <li
              onMouseEnter={(e) => this.isHoverMenu(true, 'timeKeeping_icon')}
              onMouseLeave={(e) => this.isHoverMenu(false, 'timeKeeping_icon')}
              style={{
                display:
                (viewPermission.includes("Timekeeping - Today") === true) || (viewPermission.includes("Timekeeping - Reports") === true) || (roleAllTrue === 1) ? 'block' : 'none',
              }}
            >
              <div
                className='collapsible-header  '
                onClick={(e) => this.getTriggerCollapse('timeKeeping_icon')}
              >
                <Link
                  to={'/admin/today?page=1'}
                  onClick={(e) => this.getTriggerCollapse('timeKeeping_icon')}
                >
                  <img
                    id='timeKeeping_icon'
                    src={timeKeepingDefault}
                    alt=''
                  ></img>
                </Link>
                <p id='admin-sideMenu-collapse-header'>Timekeeping</p>
                <img
                  src={
                    this.state.menuIconList[3].isChevronUp === false
                      ? dropdown_chevrondown
                      : dropdown_chevronup
                  }
                  style={{ marginLeft: '65px', height: '14px', width: '14px' }}
                  alt=''
                />
              </div>
              <ul className='collapsible-body'>
                <li style={{
                  display:
                  (viewPermission.includes("Timekeeping - Today") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    onClick={(e) => this.getTriggerCollapse('timeKeeping_icon')}
                    to={'/admin/today?page=1'}
                    className={this.selectedOption(
                      '/admin/today',
                      'timeKeeping_icon'
                    )}
                  >
                    Today
                  </Link>
                </li>
                <li style={{
                  display:
                  (viewPermission.includes("Timekeeping - Reports") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    onClick={(e) => this.getTriggerCollapse('timeKeeping_icon')}
                    to='/admin/timekeeping/reports'
                    className={this.selectedOption(
                      '/admin/timekeeping',
                      'timeKeeping_icon'
                    )}
                  >
                    Reports
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li><Link to="#">Roster Management</Link></li> */}
            <li
              onMouseEnter={(e) =>
                this.isHoverMenu(true, 'leaveManagement_icon')
              }
              onMouseLeave={(e) =>
                this.isHoverMenu(false, 'leaveManagement_icon')
              }
              style={{
                display:
                (viewPermission.includes("Leaves - Leave Filings") === true) || (viewPermission.includes("Leaves - User Leave Overview") === true)  || (viewPermission.includes("Leaves - Leave Types Overview") === true) ||  (roleAllTrue === 1) ? 'block' : 'none',
              }}
            >
              <div
                className='collapsible-header'
                onClick={(e) => this.getTriggerCollapse('leaveManagement_icon')}
              >
                <Link
                  to='/admin/leave-requests?page=1'
                  onClick={(e) =>
                    this.getTriggerCollapse('leaveManagement_icon')
                  }
                >
                  <img
                    id='leaveManagement_icon'
                    src={leaveManagementDefault}
                    alt=''
                  />
                </Link>
                <p id='admin-sideMenu-collapse-header'>Leaves</p>
                <img
                  src={
                    this.state.menuIconList[4].isChevronUp === false
                      ? dropdown_chevrondown
                      : dropdown_chevronup
                  }
                  style={{ marginLeft: '104px', height: '14px', width: '14px' }}
                  alt=''
                />
              </div>
              <ul className='collapsible-body'>
              {/* {
                hasLeaveFilingsView(this.props.permissions.items) === 'true' || JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll ===  1 ?
                  : false
              } */}
               <li style={{
                  display:
                  (viewPermission.includes("Leaves - Leave Filings") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                    <Link
                      to='/admin/leave-requests?page=1'
                      onClick={(e) =>
                        this.getTriggerCollapse('leaveManagement_icon')
                      }
                      className={this.selectedOption(
                        '/admin/leave-request',
                        'leaveManagement_icon'
                      )}
                    >
                      Leave Filings
                    </Link>
                  </li>
                
                <li style={{
                  display:
                  (viewPermission.includes("Leaves - User Leave Overview") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to='/admin/leave-credits-overview?page=1'
                    onClick={(e) =>
                      this.getTriggerCollapse('leaveManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/leave-credits-overview',
                      'leaveManagement_icon'
                    )}
                  >
                    User Leave Overview
                  </Link>
                </li>
                {/* <li><Link to="/admin/leave-credits-overview/create" onClick={(e) => this.getTriggerCollapse("leaveManagement_icon")} className={this.selectedOption('/admin/leave-credits-overview/create', 'leaveManagement_icon')}>Add Leave Credits</Link></li> */}
                <li style={{
                  display:
                  (viewPermission.includes("Leaves - Leave Types Overview") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to='/admin/leave-type/overview?page=1'
                    onClick={(e) =>
                      this.getTriggerCollapse('leaveManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/leave-type/overview',
                      'leaveManagement_icon'
                    )}
                  >
                    Leave Types Overview
                  </Link>
                </li>
                {/* <li><Link to="/admin/leave-type/create" onClick={(e) => this.getTriggerCollapse("leaveManagement_icon")} className={this.selectedOption('/admin/leave-type/create', 'leaveManagement_icon')}>Create Leave Type</Link></li> */}
              </ul>
            </li>
            <li
              onMouseEnter={(e) =>
                this.isHoverMenu(true, 'requestManagement_icon')
              }
              onMouseLeave={(e) =>
                this.isHoverMenu(false, 'requestManagement_icon')
              }
              style={{
                display:
                (viewPermission.includes("Requests - COE Requests") === true) || (viewPermission.includes("Requests - Overtime Requests") === true) || (viewPermission.includes("Requests - Undertime Requests") === true) || (roleAllTrue === 1) ? 'block' : 'none',
              }}
            >
              <div
                className='collapsible-header'
                onClick={(e) =>
                  this.getTriggerCollapse('requestManagement_icon')
                }
              >
                <Link
                  to={'/admin/coe-requests?page=1'}
                  onClick={(e) =>
                    this.getTriggerCollapse('requestManagement_icon')
                  }
                >
                  <img
                    id='requestManagement_icon'
                    src={requestManagementDefault}
                    alt=''
                  ></img>
                </Link>
                <p id='admin-sideMenu-collapse-header'>Requests</p>
                <img
                  src={
                    this.state.menuIconList[5].isChevronUp === false
                      ? dropdown_chevrondown
                      : dropdown_chevronup
                  }
                  style={{ marginLeft: '90px', height: '14px', width: '14px' }}
                  alt=''
                />
              </div>
              <ul className='collapsible-body'>
                <li style={{
                  display:
                  (viewPermission.includes("Requests - COE Requests") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to={'/admin/coe-requests?page=1'}
                    onClick={(e) =>
                      this.getTriggerCollapse('requestManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/coe-request',
                      'requestManagement_icon'
                    )}
                  >
                    COE Requests
                  </Link>
                </li>
                <li style={{
                  display:
                  (viewPermission.includes("Requests - Overtime Requests") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to='/admin/overtime-requests?page=1'
                    onClick={(e) =>
                      this.getTriggerCollapse('requestManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/overtime-request',
                      'requestManagement_icon'
                    )}
                  >
                    Overtime Requests
                  </Link>
                </li>
                <li style={{
                  display:
                  (viewPermission.includes("Requests - Undertime Requests") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to='/admin/undertime-requests?page=1'
                    onClick={(e) =>
                      this.getTriggerCollapse('requestManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/undertime-request',
                      'requestManagement_icon'
                    )}
                  >
                    Undertime Requests
                  </Link>
                </li>
              </ul>
            </li>
            <li
              onMouseEnter={(e) =>
                this.isHoverMenu(true, 'supportManagement_icon')
              }
              onMouseLeave={(e) =>
                this.isHoverMenu(false, 'supportManagement_icon')
              }
              style={{
                display:
                (viewPermission.includes("Support - HR Concerns") === true) || (viewPermission.includes("Support - Technical Support") === true) || (viewPermission.includes("Support - Feedback") === true) ||  (roleAllTrue === 1) ? 'block' : 'none',
                }}
            >
              <div
                className='collapsible-header'
                onClick={(e) =>
                  this.getTriggerCollapse('supportManagement_icon')
                }
              >
                <Link
                  to={'/admin/hr-concerns?page=1'}
                  onClick={(e) =>
                    this.getTriggerCollapse('supportManagement_icon')
                  }
                >
                  <img
                    id='supportManagement_icon'
                    src={supportManagementDefault}
                    alt=''
                  ></img>
                </Link>
                <p id='admin-sideMenu-collapse-header'>Support</p>
                <img
                  src={
                    this.state.menuIconList[6].isChevronUp === false
                      ? dropdown_chevrondown
                      : dropdown_chevronup
                  }
                  style={{ marginLeft: '95px', height: '14px', width: '14px' }}
                  alt=''
                />
              </div>
              <ul className='collapsible-body'>
                <li style={{
                display:
                (viewPermission.includes("Support - HR Concerns") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to={'/admin/hr-concerns?page=1'}
                    onClick={(e) =>
                      this.getTriggerCollapse('supportManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/hr-concern',
                      'supportManagement_icon'
                    )}
                  >
                    HR Concerns
                  </Link>
                </li>
                <li style={{
                display:
                (viewPermission.includes("Support - Technical Support") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to='/admin/technical-supports?page=1'
                    onClick={(e) =>
                      this.getTriggerCollapse('supportManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/technical-supports',
                      'supportManagement_icon'
                    )}
                  >
                    Technical Support
                  </Link>
                </li>
                <li style={{
                display:
                (viewPermission.includes("Support - Feedback") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to='/admin/feedback?page=1'
                    onClick={(e) =>
                      this.getTriggerCollapse('supportManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/feedback',
                      'supportManagement_icon'
                    )}
                  >
                    Feedback
                  </Link>
                </li>
              </ul>
            </li>
            <li
              onMouseEnter={(e) =>
                this.isHoverMenu(true, 'engagementtManagement_icon')
              }
              onMouseLeave={(e) =>
                this.isHoverMenu(false, 'engagementtManagement_icon')
              }
              style={{
                display:
                (viewPermission.includes("Engagement - Quests") === true) || (viewPermission.includes("Engagement - Challenges") === true)  || (viewPermission.includes("Engagement - Badges") === true) || (viewPermission.includes("Engagement - Rewards") === true) || (viewPermission.includes("Engagement - Reset Values") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}
            >
              <div
                className='collapsible-header'
                onClick={(e) =>
                  this.getTriggerCollapse('engagementtManagement_icon')
                }
              >
                <Link
                  to={'/admin/hr-concerns?page=1'}
                  onClick={(e) =>
                    this.getTriggerCollapse('engagementtManagement_icon')
                  }
                >
                  <img
                    id='engagementtManagement_icon'
                    src={engagementManagementDefault}
                    alt=''
                  ></img>
                </Link>
                <p id='admin-sideMenu-collapse-header'>Engagement</p>
                <img
                  src={
                    this.state.menuIconList[7].isChevronUp === false
                      ? dropdown_chevrondown
                      : dropdown_chevronup
                  }
                  style={{ marginLeft: '67px', height: '14px', width: '14px' }}
                  alt=''
                />
              </div>
              <ul className='collapsible-body'>
                <li style={{
                display:
                (viewPermission.includes("Engagement - Quests") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to={'/admin/quests?page=1'}
                    onClick={(e) =>
                      this.getTriggerCollapse('engagementtManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/quests',
                      'engagementtManagement_icon'
                    )}
                  >
                    Quests
                  </Link>
                </li>
                <li style={{
                display:
                (viewPermission.includes("Engagement - Challenges") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to={'/admin/challenges?page=1'}
                    onClick={(e) =>
                      this.getTriggerCollapse('engagementtManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/challenges',
                      'engagementtManagement_icon'
                    )}
                  >
                    Challenges
                  </Link>
                </li>
                <li style={{
                    display:
                            (viewPermission.includes("Engagement - Badges") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to={'/admin/credits'}
                    onClick={(e) =>
                      this.getTriggerCollapse('engagementtManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/credits',
                      'engagementtManagement_icon'
                    )}
                  >
                    Credits
                  </Link>
                </li>
                <li style={{
                    display:
                        (viewPermission.includes("Engagement - Badges") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to={'/admin/badges?page=1'}
                    onClick={(e) =>
                      this.getTriggerCollapse('engagementtManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/badges',
                      'engagementtManagement_icon'
                    )}
                  >
                    Badges
                  </Link>
                </li>
                <li style={{
                    display:
                        (viewPermission.includes("Engagement - Badges") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to={'/admin/achievements?page=1'}
                    onClick={(e) =>
                      this.getTriggerCollapse('engagementtManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/achievements',
                      'engagementtManagement_icon'
                    )}
                  >
                    Achievements
                  </Link>
                </li>
                <li style={{
                display:
                (viewPermission.includes("Engagement - Rewards") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to={'/admin/rewards?page=1'}
                    onClick={(e) =>
                      this.getTriggerCollapse('engagementtManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/rewards',
                      'engagementtManagement_icon'
                    )}
                  >
                    Rewards
                  </Link>
                </li>
                <li style={{
                display:
                (viewPermission.includes("Engagement - Reset Values") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to={'/admin/reset-energy?page=1'}
                    onClick={(e) =>
                      this.getTriggerCollapse('engagementtManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/reset-energy',
                      'engagementtManagement_icon'
                    )}
                  >
                    Reset Values
                  </Link>
                </li>
              </ul>
            </li>
            <li
              onMouseEnter={(e) =>
                this.isHoverMenu(true, 'teamManagementPage_icon')
              }
              onMouseLeave={(e) =>
                this.isHoverMenu(false, 'teamManagementPage_icon')
              }
              style={{
                display:
                (viewPermission.includes("Teams") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}
            >
              <div className='custom-menu'>
                <Link
                  //  to="#"
                  to='/admin/team-management?page=1'
                  onClick={(e) =>
                    this.getTriggerCollapse('teamManagementPage_icon')
                  }
                >
                  <img
                    id='teamManagementPage_icon'
                    src={teamManagementDefault}
                    alt=''
                  ></img>
                </Link>
                <p className='darkmode-font-color'>
                  <Link
                    to='/admin/team-management?page=1'
                    onClick={(e) =>
                      this.getTriggerCollapse('teamManagementPage_icon')
                    }
                    className={this.selectedOption(
                      '/admin/team-management',
                      'teamManagementPage_icon'
                    )}
                  >
                    Teams
                  </Link>
                </p>
              </div>
            </li>

            <li
              onMouseEnter={(e) =>
                this.isHoverMenu(true, 'appraisalManagement_icon')
              }
              onMouseLeave={(e) =>
                this.isHoverMenu(false, 'appraisalManagement_icon')
              }
              style={{
                display:
                (viewPermission.includes("Appraisal - Evaluations") === true) || (viewPermission.includes("Appraisal - Tenures") === true) ||  (viewPermission.includes("Appraisal - Questionnaires ") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}
            >
              <div
                className='collapsible-header'
                onClick={(e) =>
                  this.getTriggerCollapse('appraisalManagement_icon')
                }
              >
                <Link
                  to={'/admin/evaluations?page=1'}
                  onClick={(e) =>
                    this.getTriggerCollapse('appraisalManagement_icon')
                  }
                >
                  <img
                    id='appraisalManagement_icon'
                    src={AppraisalDefault}
                    alt=''
                  ></img>
                </Link>
                <p id='admin-sideMenu-collapse-header'>Appraisal</p>
                <img
                  src={
                    this.state.menuIconList[8].isChevronUp === false
                      ? dropdown_chevrondown
                      : dropdown_chevronup
                  }
                  style={{ marginLeft: '88px', height: '14px', width: '14px' }}
                  alt=''
                />
              </div>
              <ul className='collapsible-body'>
                <li style={{
                display:
                (viewPermission.includes("Appraisal - Evaluations") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to={'/admin/evaluations?page=1'}
                    onClick={(e) =>
                      this.getTriggerCollapse('appraisalManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/evaluations',
                      'appraisalManagement_icon'
                    )}
                  >
                    Evaluations
                  </Link>
                </li>
                <li style={{
                display:
                (viewPermission.includes("Appraisal - Tenures") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to='/admin/tenures?page=1'
                    onClick={(e) =>
                      this.getTriggerCollapse('appraisalManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/tenures',
                      'appraisalManagement_icon'
                    )}
                  >
                    Tenures
                  </Link>
                </li>
                <li style={{
                display:
                (viewPermission.includes("Appraisal - Questionnaires ") === true) || (roleAllTrue === 1) ? 'block' : 'none',
                }}>
                  <Link
                    to='/admin/questionnaires?page=1'
                    onClick={(e) =>
                      this.getTriggerCollapse('appraisalManagement_icon')
                    }
                    className={this.selectedOption(
                      '/admin/questionnaires',
                      'appraisalManagement_icon'
                    )}
                  >
                    Questionnaires
                  </Link>
                </li>
              </ul>
            </li>

            <li
              onMouseEnter={(e) =>
                this.isHoverMenu(true, 'pageManagement_icon')
              }
              onMouseLeave={(e) =>
                this.isHoverMenu(false, 'pageManagement_icon')
              }
              style={{
                display:
                (viewPermission.includes("Pages") === true) || (roleAllTrue === 1) ? 'block' : 'none',
              }}
            >
              <div className='custom-menu'>
                <Link
                  to='/admin/page-management?page=1'
                  onClick={(e) =>
                    this.getTriggerCollapse('pageManagement_icon')
                  }
                >
                  <img
                    id='pageManagement_icon'
                    src={pageManagementDefault}
                    alt=''
                  ></img>
                </Link>
                <p className='darkmode-font-color'>
                  <Link
                    onClick={(e) =>
                      this.getTriggerCollapse('pageManagement_icon')
                    }
                    to='/admin/page-management?page=1'
                    className={this.selectedOption(
                      '/admin/page-management',
                      'pageManagement_icon'
                    )}
                  >
                    Pages
                  </Link>
                </p>
              </div>
            </li>
            <li
              onMouseEnter={(e) => this.isHoverMenu(true, 'settings_icon')}
              onMouseLeave={(e) => this.isHoverMenu(false, 'settings_icon')}
              className={secretDetails !== null ? "disabled-link-none" : ''}
              style={{
                display:
                (viewPermission.includes("Settings") === true) || (roleAllTrue === 1) ? 'block' : 'none',
              }}
            >
              <div className='custom-menu'>
                <Link
                  to='/admin/settings'
                  onClick={(e) => this.getTriggerCollapse("settings_icon")} 
                  className={secretDetails !== null ? "disabled-link" : ''}
                >
                  <img id='settings_icon' src={settingsDefault} alt=''></img>
                </Link>
                <p className='darkmode-font-color'>
                  <Link
                    to='/admin/settings'
                    onClick={(e) => this.getTriggerCollapse("settings_icon")} 
                    
                    className={secretDetails !== null ? "disabled-link" : this.selectedOption(
                        '/admin/settings',
                        'settings_icon'
                      )}
                    
                  >
                    Settings
                  </Link>
                </p>
              </div>
            </li>
            {/* <li
              onMouseEnter={(e) => this.isHoverMenu(true, 'viewLogs_icon')}
              onMouseLeave={(e) => this.isHoverMenu(false, 'viewLogs_icon')}
            >
              <div className='custom-menu'>
                <Link
                  to='#'
                  // onClick={(e) => this.getTriggerCollapse("viewLogs_icon")} // No link
                >
                  <img id='viewLogs_icon' src={viewLogsDefault} alt=''></img>
                </Link>
                <p className='darkmode-font-color'>
                  <Link
                    to='#'
                    className={this.selectedOption('/admin/view-logs')}
                  >
                    View Logs
                  </Link>
                </p>
              </div>
            </li> */}
            <li
              onMouseEnter={(e) => this.isHoverMenu(true, 'logOut_icon')}
              onMouseLeave={(e) => this.isHoverMenu(false, 'logOut_icon')}
            >
              <div className='custom-menu logOut-custom'>
                <Link to='/' onClick={this.handlelogout}>
                  <img id='logOut_icon' src={logoutDefault} alt=''></img>
                </Link>
                <p className='darkmode-font-color'>
                  <Link
                    to='/'
                    onClick={this.handlelogout}
                    className={this.selectedOption('/admin/logout')}
                  >
                    Logout
                  </Link>
                </p>
              </div>
            </li>
          </ul>
        </div>
        {/* End Side Menu */}
      </div>
    );
  }
}

// Permissions.propTypes = {
//   permissions: PropTypes.array.isRequired,
//   getPermissions: PropTypes.array.isRequired,
// }

// const mapStateToProps = (state) =>({
//   permissions: state.permissions.permissions
// })

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getPermissions: () => dispatch(permissionActions.getAll(1,1000))
//   }
// };

export default SideMenu;

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import breadCrumbs_chevron from "../../../_templates/breadCrumbs_chevron.png";
import { tenuresAction } from "../../../_core/actions/tenures";
import questionnaireActions from "../../../_core/actions/questionnaire";
import evaluationActions from "../../../_core/actions/evaluation";
import {announcementActions} from "../../../_core/actions/announcement";
import { leaveActions } from '../../../_core/actions/leave';
import { leaveCreditActions } from '../../../_core/actions/leaveCredit';
import { userActions } from '../../../_core/actions/user';
import { leaveTypeActions } from '../../../_core/actions/leaveType';
import { technicalActions } from '../../../_core/actions/technical';
import { history } from '../../../_core/helpers/history';
import { questActions } from '../../../_core/actions/quest';
import { hrConcernActions } from "../../../_core/actions/hrConcern";
import { coeActions } from "../../../_core/actions/coe";
import { overtimeActions } from "../../../_core/actions/overtime";
import { undertimeActions } from "../../../_core/actions/undertime";
import { challengeActions } from "../../../_core/actions/challenge";
import { badgesActions } from '../../../_core/actions/badges';
import { rewardActions } from '../../../_core/actions/reward';
import { timekeepingAction } from '../../../_core/actions/timekeeping';
import { feedbackActions } from '../../../_core/actions/feedback';
import { achievementActions } from '../../../_core/actions/achievement';
import Pagination from 'react-js-pagination';
import queryString from 'query-string';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import dropdownIndicator from '../../../_templates/dropdown_chevrondown.png';
import search from '../../../_templates/search_icon.png'

import Cookies from 'universal-cookie';
import { userChallengeActions } from "../../../_core/actions/userChallenge";

const Title = styled.h2`
  margin: 0px;
  padding: 0px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
`;

const BreadCrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;

  p > a {
    color: #9ba2ce;
    font-size: 14px !important;
    font-weight: 400;
  }
  p > span {
    color: #6a7287 !important;
    font-size: 14px !important;
    font-weight: bold;
  }

  p > span > img {
    position: relative;
    top: 3px;
  }
`;

const Elipsis = styled.p`
  display: inline-flex;
  cursor: pointer;
  color: #9ba2ce;
  font-size: 14px;
  font-weight: bold;
`;

export const DynamicHeaderContent = (props) => {
  const [showElipsis, setShowElipsis] = React.useState(false);

  const filterChevron = (count, total) => {
    if (count !== total - 1) {
      return <img src={breadCrumbs_chevron} alt="" />;
    }
  };
  const filterElipsis = (count, label, link, chevron, total) => {
    if (count === 2) {
      return "";
    }
    if (count <= total) {
      return (
        <>
          <Elipsis onClick={(e) => setShowElipsis(!showElipsis)}> ... </Elipsis>
          {chevron}
        </>
      );
    }
  };

  return (
    <HeaderWrapper>
      <div className="TitlePage">
        <Title>{props.TitlePage ?? " "}</Title>
      </div>

      {props.breadCrumbs === true && (
        <BreadCrumbsWrapper>
          {props.breadCrumbsPages !== undefined &&
            props.breadCrumbsPages !== "" && (
              <p>
                {props.breadCrumbsPages.length > 4 ? (
                  <>
                    {props.breadCrumbsPages.map((breadCrumbs, count) => (
                      <span key={count}>
                        {count >= 2 &&
                        count < props.breadCrumbsPages.length - 1 ? (
                          <>
                            {showElipsis === false ? (
                              <span key={count}>
                                {filterElipsis(
                                  count,
                                  breadCrumbs.label,
                                  breadCrumbs.link,
                                  filterChevron(
                                    count,
                                    props.breadCrumbsPages.length
                                  ),
                                  props.breadCrumbsPages.length
                                )}
                              </span>
                            ) : (
                              <span key={count}>
                                {props.breadCrumbsPages.length - 1 === count ? (
                                  <span>{breadCrumbs.label}</span>
                                ) : (
                                  <Link to={breadCrumbs.link ?? "#"}>
                                    {breadCrumbs.label}
                                  </Link>
                                )}
                                {filterChevron(
                                  count,
                                  props.breadCrumbsPages.length
                                )}
                              </span>
                            )}
                          </>
                        ) : (
                          <span key={count}>
                            {props.breadCrumbsPages.length - 1 === count ? (
                              <span>{breadCrumbs.label}</span>
                            ) : (
                              <Link to={breadCrumbs.link ?? "#"}>
                                {breadCrumbs.label}
                              </Link>
                            )}
                            {filterChevron(
                              count,
                              props.breadCrumbsPages.length
                            )}
                          </span>
                        )}
                      </span>
                    ))}
                  </>
                ) : (
                  <>
                    {props.breadCrumbsPages.map((breadCrumbs, count) => (
                      <span key={count}>
                        {props.breadCrumbsPages.length - 1 === count ? (
                          <span>{breadCrumbs.label}</span>
                        ) : (
                          <Link to={breadCrumbs.link ?? "#"}>
                            {breadCrumbs.label}
                          </Link>
                        )}

                        {filterChevron(count, props.breadCrumbsPages.length)}
                      </span>
                    ))}
                  </>
                )}
              </p>
            )}
        </BreadCrumbsWrapper>
      )}
    </HeaderWrapper>
  );
};

const TableWrapper = styled.div`
  // overflow-x: auto; // Responsive
`;
const CustomTable = styled.table`
  color: #9ba3ca;
  margin-top: 15px;
  > thead > tr > th {
    font-size: 16px;
    font-weight: 500;
  }

  > tbody > tr > td,
  > thead > tr > th {
    padding: 10px;
  }
  > tbody > tr > td {
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
  }
  > tbody > tr,
  > thead > tr {
    border-top: 1px solid #2e3549 !important;
    border-bottom: 1px solid #2e3549 !important;
  }
  > tbody > tr:hover {
    background-color: #2e3549 !important;
  }

  > thead > tr > th:last-child {
    text-align: center;
  }

  @media (max-width: 993px) {
    > tbody > tr > td {
      width: 100% !important;
    }
  }
`;

const PaginationWrapper = styled.div`
  color: #9ba2ce;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  > ul.pagination {
    width: auto !important;
  }

  > ul.pagination > li {
    border: 1px solid #323a4f !important ;
    color: #9ba3ca;
  }
  > ul > li.pagination_navigation_active {
    border: 1px solid #323a4f;
    color: #9ba3ca;
  }
  > ul > li > a.pagination_navigation_link {
    color: #9ba3ca;
  }
  > ul > li.pagination_navigation_active {
    background-color: #556ee6;
  }

  > ul > li.pagination_navigation_active a {
    color: #ffffff !important;
  }

  > div:nth-child(1) {
    font-size: 14px;
    font-weight: 500;
  }

  @media (max-width: 800px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > div > div.dataCount {
      display: block;
      margin-bottom: 20px;
    }
  }
`;

export const DynamicTableAndPaginationWrapper = (props) => {
  const dispatch = useDispatch();
  const [totalData, setTotalData] = React.useState(0);
  const [ActivePage, setActivePage] = React.useState(0);
  const [GetTotalPerpage, setGetTotalPerpage] = React.useState(10); // eslint-disable-line

  let TableHeaderData = props.tableHeader;
  let TableBodyData = props.tableBodyData;
  let totalPerPage = props.totalPerPage ?? GetTotalPerpage;

  React.useEffect(() => {
    setTotalData(props?.data?.totalPerPage ?? props?.data?.total ?? 0);
    setActivePage(props?.data?.currentPage ?? props?.data?.page ?? 0);

    setGetTotalPerpage(props.entriesItem || 10)
  }, [props])

  const UrlPage = React.useMemo(() => {
    return parseInt(queryString.parse(props.location.search).page);
  }, [props.location.search]);

  React.useEffect(() => {
    // If Url Page number Changed Directly in Url.
    FilterDispatch(props.originPage, UrlPage, totalPerPage, props.search,props.filter);
    // eslint-disable-next-line
  }, [props.search,props.filter,dispatch, UrlPage, totalPerPage, props.originPage]);

  const handlePageChange =(page) => {
    FilterDispatch(props.originPage,  page, totalPerPage)
    if(window.location.pathname === props.paginationPath ){
      history.push(`${props.paginationPath}?page=${Number(page) || 1}`)
    }
  };

  const FilterDispatch = (originPage, page, totalItemPerPage) => {
    switch (originPage) {
      case "Tenures":
        dispatch(tenuresAction.getAllTenures(page, totalItemPerPage));
        break;
      case "Evaluations":
        dispatch(evaluationActions.getAll(page, totalItemPerPage));
        break;
      case "Questionnaires":
        dispatch(questionnaireActions.getAll(page, totalItemPerPage));
        break;
      case "Announcement":
        dispatch(announcementActions.getAll(page, totalItemPerPage));
        break;
      case "Leaves":
        dispatch(leaveActions.getAllFilter(page,totalItemPerPage,props.search,props.filter));
        break;
      case "LeavesCredits":
        dispatch(leaveCreditActions.getAll());
        break;
      case "Users":
        dispatch(userActions.getAll(page, totalItemPerPage));
        break;
      case "LeaveTypes":
        dispatch(leaveTypeActions.getAllTypes(page, totalItemPerPage));
        break;
      case "TechnicalSupport":
        dispatch(technicalActions.getAll(page, totalItemPerPage));
        break;
      case "HRSupport":
        dispatch(hrConcernActions.getAll(page, totalItemPerPage));
        break;
      case "Quest":
		    dispatch(questActions.getAllFilter(page,totalItemPerPage,props.search,props.filter))
        break; 
      case "COERequests":
        dispatch(coeActions.getAll(page, totalItemPerPage));
        break;
      case "OvertimeRequests":
        dispatch(overtimeActions.getAll(page, totalItemPerPage));
        break;
      case "UndertimeRequests":
        dispatch(undertimeActions.getAll(page, totalItemPerPage));
        break;
      case "Challenge":
        dispatch(challengeActions.getAllAdmin(page, totalItemPerPage, props.search,props.filter));
        break;
      case "Badges":
		    dispatch(badgesActions.getAll(page,totalItemPerPage,props.search,props.filter));
        break; 
      case "Rewards":
		    dispatch(rewardActions.getAll(totalItemPerPage, page,props.search,props.filter));
        break; 
      case "Timekeeping":
        dispatch(timekeepingAction.getAll(page, totalItemPerPage));
        break; 
      case "Feedback":
        dispatch(feedbackActions.getAll(page, totalItemPerPage));
        break;
      case "Achievements":
        dispatch(achievementActions.getAll(page, totalItemPerPage));
        break;
      case "UserChallengeParticpants":
        dispatch(userChallengeActions.getQuestParticipants(props.pageId, page, totalItemPerPage));
        break; 
      case "UsersSearchFilter":
        dispatch(userActions.getAllSearchFilter(page, totalItemPerPage,props.search,props.filter));
        break;               
      default:
        break;
    }
  };

  let fromPage = (ActivePage - 1) * totalPerPage + 1;
  let toPage = Math.min(
    (ActivePage - 1) * totalPerPage + totalPerPage,
    totalData
  );

  return (
    <>
      <TableWrapper>
        <CustomTable className="responsive-table">
          <thead>
            <tr>
              {TableHeaderData.map((HeaderItem, index) => (
                <th
                  key={index}
                  className={
                    props.originPage === "Announcement" ? "announcement-th" : ""
                  }
                >
                  {HeaderItem}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{TableBodyData}</tbody>
        </CustomTable>
      </TableWrapper>

      <PaginationWrapper>
        <div>
          {totalData !== 0 && (
            <div className="dataCount">
              {" "}
              Showing {fromPage} to {toPage} of {totalData} entries{" "}
            </div>
          )}
        </div>
        <Pagination
          activeClass="pagination_navigation_active"
          itemClass="pagination_navigation"
          linkClass="pagination_navigation_link"
          hideDisabled
          hideFirstLastPages
          prevPageText="Previous"
          nextPageText="Next"
          pageRangeDisplayed={10}
          activePage={ActivePage}
          itemsCountPerPage={totalPerPage}
          totalItemsCount={totalData}
          onChange={(e) => handlePageChange(e)}
        />
      </PaginationWrapper>
    </>
  );
};

const ContentHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    > button {
      margin-bottom: 20px;
    }
  }
`;

const ButtonWrapper = styled.button`
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 20px;
  width: 196px;
  height: 36px;
  background-color: #556ee6;
  border: none !important;
  border-radius: 4px;
  box-shadow: none !important;
  cursor: pointer;

  :hover {
    background-color: #5555e6 !important;
  }
  :focus {
    background-color: #5555e6 !important;
  }
`;

const EntriesWrapper = styled.div`
  color: #9ba3ca;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > p {
    margin-right: 15px;
  }
`;

const EntriesIconsWrapper = styled.div`
  position relative;
  display: flex;
  flex-direction: column;
  text-align: right;
  bottom: 21px;
  right: 10px;
`;

const DropdownWrapper = styled.div`
  color: #9ba3ca;
  font-size: 14px;
  font-weight: 500;
  width: 90px;
  height: 26px;
  background-color: #2e3648;

  > p {
    position: relative;
    top: 3px;
    left: 10px;
  }
`;


const DivSearch = styled.div`
    display: inline-block;
    position: relative;
    margin-right: 25px;

    .search-dropdown {
        position: absolute;
        top: 50%;
        right: 7px;
        transform: translateY(-50%);
    }

    .input-search {
        background: url(${search}) no-repeat left center;
        background-position: 10px;
        background-size: 20px;
        display: inline-block;
        height: 40px !important;
        background-color: #2E3648 !important;
        border: 1px solid #323A4F !important;
        margin-top: 8px !important;
        padding: 0 10px 0 35px !important;
        font-size: 0.8125rem !important;
        width: 239px !important;
        color: #9BA4C7
    }

    .input-search::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #9BA4C7 ;
        opacity: 1
    } 
    
    .input-search:-ms-input-placeholder {
        color: #9BA4C7 
    }
    
    
    .input-search::-ms-input-placeholder {
        color: #9BA4C7 
    }


`;

//ENTRIES&BTNBUTTON
export const DynamicContentHeaderWrapper = (props) => {
    //  Content Header Wrapper
    const [Value, setValue] = React.useState(10);// eslint-disable-line
    const [update, setUpdate] =  React.useState(false);
    const cookies = new Cookies();
    const userCred = JSON.parse(localStorage.getItem('userCredentials'))
    const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));
    const addPermission = JSON.parse(localStorage.getItem('addPermission'));
    
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }
    
      React.useEffect(()=>{
          let newItems = cookies.get(props.originPage+":"+userCred.user._id)
         
          if(update !== false){
             cookies.set(props.originPage+":"+userCred.user._id, Value, { path: "/", sameSite:'lax' })
              setUpdate(false)
          }else{
              props.entriesValue(newItems ? newItems : Value);
          }
          
      },[props,Value,cookies,update,userCred]);
      
    /* ----------------Custom input type number ----------------*/
    const topArrowClicked = (e) => {
        if (Value < 50){
            if(Value === 20){
                setValue(prevState => prevState + 30);
                setUpdate(true)
            }else{
                setValue(prevState => prevState + 10);
                setUpdate(true)
            }
        }
    }
    const bottomArrowClicked = (e) => {
        if (Value > 10){

            if(Value === 50){
                setValue(prevState => prevState - 30);
                setUpdate(true)
            }else{
                setValue(prevState => prevState - 10);
                setUpdate(true)
            }
        }
    }
    /* --------------------------------------------------------*/
    let newvalue =  getCookie(props.originPage+":"+userCred.user._id);
    const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

    return (
      <ContentHeaderWrapper>
        <Link to={secretDetails ? '#' : props.btnPath || "/admin/dashboard"} className={secretDetails !== null ? "disabled-link" : '' }>
          {
            (addPermission?.includes(props.addPage) && props.originPage === "Announcement" === true) || (addPermission?.includes(props.addPage) && props.originPage === "LeaveCredits" === true)  || (addPermission?.includes(props.addPage) && props.originPage === "LeaveTypes" === true) || (addPermission?.includes(props.addPage) && props.originPage === "Evaluations" === true) || (addPermission?.includes(props.addPage) && props.originPage === "Questionnaires" === true) || (addPermission?.includes(props.addPage) && props.originPage === "Tenures" === true) || (addPermission?.includes(props.addPage) && props.originPage === "ManageCredits" === true) || (roleAllTrue === 1) ?
          <ButtonWrapper>{props.buttonLabel ?? "Add Label"}</ButtonWrapper> : false
          }
          
        </Link>
        <EntriesWrapper style={{display:'inline-flex'}}>
                <p>Show Entries</p>
                <DropdownWrapper value={newvalue ? newvalue : Value} pattern="[0-9]*">
                    <p> {newvalue ? newvalue : Value}</p>
                    <EntriesIconsWrapper>
                        <i className="fa fa-angle-up" aria-hidden="true" style={{cursor:'pointer'}} onClick={topArrowClicked}></i>
                        <i className="fa fa-angle-down" aria-hidden="true" style={{cursor:'pointer'}} onClick={bottomArrowClicked}></i>
                    </EntriesIconsWrapper>
                </DropdownWrapper>
            </EntriesWrapper>
      </ContentHeaderWrapper>
    );
  }


//SEARCH&ENTRIESWITHBUTTON
export const DynamicSearchWrapper = (props) => {
  //  Content Header Wrapper
  const [Value, setValue] = React.useState(10);
  const [search, setNewSearch] = React.useState("");
  const [keys, setKey] = React.useState("");
  const [update, setUpdate] =  React.useState(false);
  const cookies = new Cookies();

  const userCred = JSON.parse(localStorage.getItem('userCredentials'))
  const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));
  const addPermission = JSON.parse(localStorage.getItem('addPermission'));

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

    React.useEffect(()=>{
        let newItems = cookies.get(props.originPage+":"+userCred.user._id)
        if(update !== false){
            cookies.set(props.originPage+":"+userCred.user._id, Value, { path: "/", sameSite:'lax' })
            setUpdate(false)
        }else{
            props.entriesValue(newItems ? newItems : Value);
        }
        
    },[props,Value,cookies,update,userCred]);
    
	React.useEffect(()=>{
        props.searchs(search);
    },[props,search]);

    React.useEffect(()=>{
        if (search === ''){
            props.filters('');
        }else{
            props.filters(keys);
        }
    },[props,keys,search]);


    /* ----------------Custom input type number ----------------*/
    const topArrowClicked = (e) => {
        if (Value < 50){
            if(Value === 20){
                setValue(prevState => prevState + 30);
                setUpdate(true)
            }else{
                setValue(prevState => prevState + 10);
                setUpdate(true)
            }
        }
    }
    const bottomArrowClicked = (e) => {
        if (Value > 10){

            if(Value === 50){
                setValue(prevState => prevState - 30);
                setUpdate(true)
            }else{
                setValue(prevState => prevState - 10);
                setUpdate(true)
            }
        }
    }
    /* --------------------------------------------------------*/

    const onSelect = ({ key }) => {
        console.log(`${key} selected`);
    }
    const handleFilterName = ({ key }) => {
        setKey(key)
    };
    const handleSearchChange = (e) => {
      setNewSearch(e.target.value);
  	};
    
    let newvalue =  getCookie(props.originPage+":"+userCred.user._id)


  const MenuOption = (
    <Menu onSelect={onSelect} className="action-dropdown">
        <MenuItem disabled className="disabled-dropdown">Filters</MenuItem>
            {props.keys.map((keys, index) => (
                <MenuItem key={keys} onClick={handleFilterName} className="action-menu" >{keys === 'content' || keys === 'description' || keys === 'badgeContent' || keys === 'questContent' ? "Description" : "Title"}</MenuItem>
            ))}
        {/* <MenuItem key="title" onClick={handleFilterName} className="action-menu" >Title</MenuItem>
        <MenuItem key="content" onClick={handleFilterName} className="action-menu" >Description</MenuItem>       */}
    </Menu>
  );

  const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

  return (
    <ContentHeaderWrapper>

        <Link to={secretDetails ? '#' : props.btnPath || "/admin/dashboard"} className={secretDetails !== null ? "disabled-link" : '' } style={{ marginTop:'10px' }}>
          {
           ( addPermission?.includes(props.addPage) && props.originPage === "Quest" === true) ||  (addPermission?.includes(props.addPage) && props.originPage === "Challenge" === true) || (addPermission?.includes(props.addPage) && props.originPage === "Badges" === true) || (addPermission?.includes(props.addPage) && props.originPage === "Rewards" === true) || (roleAllTrue === 1) ?
            <ButtonWrapper>{props.buttonLabel ?? "Add Label"}</ButtonWrapper> : false
          }
        </Link>

        <div>
            <DivSearch>
                <input className='input-search' type='text' placeholder='Search' value={search} onChange={handleSearchChange}/>

                    {props.originPage !== 'LeavesCredits' ?
                    
                        <Dropdown
                            trigger={['click']}
                            overlay={MenuOption}
                            // onVisibleChange={onVisibleChange}
                            animation="slide-up"
                        >   
                        <div style={{width:'100%'}}><img src={dropdownIndicator} alt="Logo" className='search-dropdown'/></div>        
                        </Dropdown>
                        :
                        <></>
                    }

            </DivSearch>
            <EntriesWrapper style={{display:'inline-flex'}}>
                <p>Show Entries</p>
                <DropdownWrapper value={newvalue ? newvalue : Value} pattern="[0-9]*">
                    <p> {newvalue ? newvalue : Value}</p>
                    <EntriesIconsWrapper>
                        <i className="fa fa-angle-up" aria-hidden="true" style={{cursor:'pointer'}} onClick={topArrowClicked}></i>
                        <i className="fa fa-angle-down" aria-hidden="true" style={{cursor:'pointer'}} onClick={bottomArrowClicked}></i>
                    </EntriesIconsWrapper>
                </DropdownWrapper>
            </EntriesWrapper>
        </div>
    </ContentHeaderWrapper>
  );
};

//ENTRIES&SEARCH
export const DynamicSearchEntriesWrapper = (props) => {
	//  Content Header Wrapper
	const [Value, setValue] = React.useState(10);
	const [search, setNewSearch] = React.useState("");
	const [keys, setKey] = React.useState("");
	const [update, setUpdate] =  React.useState(false);
	const cookies = new Cookies();
  
	const userCred = JSON.parse(localStorage.getItem('userCredentials'))
  
	function getCookie(name) {
	  const value = `; ${document.cookie}`;
	  const parts = value.split(`; ${name}=`);
	  if (parts.length === 2) return parts.pop().split(';').shift();
	}
  
	  React.useEffect(()=>{
		  let newItems = cookies.get(props.originPage+":"+userCred.user._id)
		  if(update !== false){
			  cookies.set(props.originPage+":"+userCred.user._id, Value, { path: "/", sameSite:'lax' })
			  setUpdate(false)
		  }else{
			  props.entriesValue(newItems ? newItems : Value);
		  }
		  
	  },[props,Value,cookies,update,userCred]);
	  
	  React.useEffect(()=>{
		  props.searchs(search);
	  },[props,search]);
  
	  React.useEffect(()=>{
		  if (search === ''){
			  props.filters('');
		  }else{
			  props.filters(keys);
		  }
	  },[props,keys,search]);
  
  
	  /* ----------------Custom input type number ----------------*/
	  const topArrowClicked = (e) => {
		  if (Value < 50){
			  if(Value === 20){
				  setValue(prevState => prevState + 30);
				  setUpdate(true)
			  }else{
				  setValue(prevState => prevState + 10);
				  setUpdate(true)
			  }
		  }
	  }
	  const bottomArrowClicked = (e) => {
		  if (Value > 10){
  
			  if(Value === 50){
				  setValue(prevState => prevState - 30);
				  setUpdate(true)
			  }else{
				  setValue(prevState => prevState - 10);
				  setUpdate(true)
			  }
		  }
	  }
	  /* --------------------------------------------------------*/
  
	  const onSelect = ({ key }) => {
		  console.log(`${key} selected`);
	  }
	  const handleFilterName = ({ key }) => {
		  setKey(key)
	  };
	  const handleSearchChange = (e) => {
		setNewSearch(e.target.value);
		};
	  
	  let newvalue =  getCookie(props.originPage+":"+userCred.user._id)
  
  
  //LEAVEOPTIONS
  const searchMenuLeave = (
	  <Menu onSelect={onSelect} className="action-dropdown">
		  <MenuItem disabled className="disabled-dropdown">Filters</MenuItem>
		  <MenuItem key="refCode" onClick={handleFilterName} className="action-menu" >Reference Code</MenuItem>
		  <MenuItem key="description" onClick={handleFilterName} className="action-menu" >Reason</MenuItem>      
	  </Menu>
	);
  
	return (
	  <ContentHeaderWrapper style={{float:'right', marginBottom:'20px'}}>
		  <div >
			  <DivSearch>
				  <input className='input-search' type='text' placeholder='Search' value={search} onChange={handleSearchChange}/>
					  <Dropdown
						  trigger={['click']}
						  overlay={searchMenuLeave}
						  // onVisibleChange={onVisibleChange}
						  animation="slide-up"
					  >   
					  <div style={{width:'100%'}}><img src={dropdownIndicator} alt="Logo" className='search-dropdown'/></div>        
					  </Dropdown>
			  </DivSearch>
			  <EntriesWrapper style={{display:'inline-flex'}}>
				  <p>Show Entries</p>
				  <DropdownWrapper value={newvalue ? newvalue : Value} pattern="[0-9]*">
					  <p> {newvalue ? newvalue : Value}</p>
					  <EntriesIconsWrapper>
						  <i className="fa fa-angle-up" aria-hidden="true" style={{cursor:'pointer'}} onClick={topArrowClicked}></i>
						  <i className="fa fa-angle-down" aria-hidden="true" style={{cursor:'pointer'}} onClick={bottomArrowClicked}></i>
					  </EntriesIconsWrapper>
				  </DropdownWrapper>
			  </EntriesWrapper>
		  </div>
	  </ContentHeaderWrapper>
	);
  };



//ENTRIES
export const DynamicEntriesOnly = (props) => {
  //  Content Header Wrapper

  const [Value, setValue] = React.useState(10); // eslint-disable-line
  const [update, setUpdate] =  React.useState(false);
  const cookies = new Cookies();

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const userCred = JSON.parse(localStorage.getItem('userCredentials'))

  
    React.useEffect(()=>{
        let newItems = cookies.get(props.originPage+":"+userCred.user._id)
       
        if(update !== false){
            cookies.set(props.originPage+":"+userCred.user._id, Value, { path: "/", sameSite:'lax' })
            setUpdate(false)
        }else{
            props.entriesValue(newItems ? newItems : Value);
        }
        
    },[props,Value,cookies,update,userCred]);


    /* ----------------Custom input type number ----------------*/
    const topArrowClicked = (e) => {
        if (Value < 50){
            if(Value === 20){
                setValue(prevState => prevState + 30);
                setUpdate(true)
            }else{
                setValue(prevState => prevState + 10);
                setUpdate(true)
            }
        }
    }
    const bottomArrowClicked = (e) => {
        if (Value > 10){

            if(Value === 50){
                setValue(prevState => prevState - 30);
                setUpdate(true)
            }else{
                setValue(prevState => prevState - 10);
                setUpdate(true)
            }
        }
    }
    /* --------------------------------------------------------*/
    let newvalue =  getCookie(props.originPage+":"+userCred.user._id)


    return (
      <ContentHeaderWrapper>
        <div></div>
        <EntriesWrapper>
          <p>Show Entries</p>
            <DropdownWrapper value={newvalue ? newvalue : Value} pattern="[0-9]*">
                <p> {newvalue ? newvalue : Value}</p>
                <EntriesIconsWrapper>
                    <i className="fa fa-angle-up" aria-hidden="true" style={{cursor:'pointer'}} onClick={topArrowClicked}></i>
                    <i className="fa fa-angle-down" aria-hidden="true" style={{cursor:'pointer'}} onClick={bottomArrowClicked}></i>
                </EntriesIconsWrapper>
            </DropdownWrapper>
        </EntriesWrapper>
      </ContentHeaderWrapper>
    );
  }


//ENGAGEMENT HEADER
export const EngageSearchEntriesWrapper = (props) => {
	//  Content Header Wrapper
    const [status, setStatus] = React.useState(false);

	  React.useEffect(()=>{
            setStatus(props.challengesStatus)
	  },[props]);


	return (
    <>
        <div>
            {props.userName}
        </div>
        <ContentHeaderWrapper style={{float:'right', marginBottom:'20px'}}>
		  <div >

                <div className={status !==false ? "toggleSwitch" : "toggleSwitch disabled"}>
                    {props.completedStatus}
                </div>
		  </div>
	  </ContentHeaderWrapper>
    </>
	
	);
  };

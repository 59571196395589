import React from "react";
import cc from "classcat";
import { connect } from "react-redux";
import { checkSideNav } from "../../Components/checkSideMenu";
import { Route, Switch } from "react-router-dom";
import { If, Else, Then } from "react-if";
import { Link } from "react-router-dom";
import BodyBackgroundColor from "react-body-backgroundcolor";
import LinesEllipsis from "react-lines-ellipsis";
import moment from "moment";
import queryString from 'query-string';
import DocumentTitle from "react-document-title";
import { Switch as SwitchCase, Case } from "react-if";
import {
  PageWrapper,
  ContentWrapper,
} from "../../Components/PageWrapper/ContentWrapper";
import {
  DynamicHeaderContent,
  DynamicTableAndPaginationWrapper,
  DynamicEntriesOnly,
} from "../../Components/PageWrapper/DynamicWrapper";
import UpdateCOERequest from "./UpdateCOEDark";
import "../../Components/StatusStyle/status.scss";
import "./COEPageDark.scss";

const mapStateToProps = ({ coes }) => ({
    coes,
});

const COERequestList = (props) => {
  const [entriesValue, setentriesValue] = React.useState();

  const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

  const editPermission = JSON.parse(localStorage.getItem('editPermission'));
  const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

  const updateEntries = (entries) => {
    setentriesValue(entries);
  };

  let tableHeader = (editPermission.includes("Requests - COE Requests") === true) || (roleAllTrue === 1) ? [
    "Reference Code",
    "Reason",
    "Submitted",
    "Date Approved",
    "Status",
    "Actions",
  ] : [
    "Reference Code",
    "Reason",
    "Submitted",
    "Date Approved",
    "Status",
    "",
  ] ;

  const ActivePage = Number(queryString.parse(props.location.search).page) || 1;

  const COEData = () => {
    return (
      <If condition={props.coes.loading === false}>
        <Then>
          {props.coes.loading === false &&
            props.coes.coe.items.map((coeItem) => (
              <tr key={coeItem._id}>
                <td style={{ width: "12%" }}>
                  {coeItem.refCode ? coeItem.refCode : "No Code"}
                </td>
                <td style={{ width: "25%" }}>
                  <LinesEllipsis
                    text={
                      coeItem.description !== null
                        ? coeItem.description
                        : ""
                    }
                    maxLine="1"
                    ellipsis="..."
                    trimRight
                    basedOn="words"
                  />
                </td>
                <td style={{ width: "10%" }}>
                  {moment(coeItem.createdAt).fromNow()}
                </td>
                <td style={{ width: "10%" }}>
                  {coeItem.updatedAt
                    ? moment(coeItem.updatedAt).fromNow()
                    : "Pending"}
                </td>
                <td style={{ width: "10%" }} className={coeItem.status}>
                  {coeItem.status === "new"
                    ? "In review"
                    : coeItem.status &&
                      coeItem.status === "approved"
                    ? "Approved"
                    : coeItem.status &&
                      coeItem.status === "rejected"
                    ? "Rejected"
                    : coeItem.status &&
                      coeItem.status === "cancelled"
                    ? "Cancelled"
                    : coeItem.status && coeItem.status === "on hold"
                    ? "On hold"
                    : coeItem.status &&
                      coeItem.status === "completed"
                    ? "Completed"
                    : coeItem.status}
                </td>
                {(editPermission.includes("Requests - COE Requests") === true) || (roleAllTrue === 1) ?
                  <td
                    style={{ width: "10%", textAlign: "end" }}
                    className={secretDetails !== null ? "disabled-link" : '' }
                  >
                    <Link
                      to={`${props.match.path}/review/${ActivePage}/${coeItem._id}`}
                    >
                      <button id="review">
                        REVIEW
                      </button>
                    </Link>
                </td>  : false
                }
                
              </tr>
            ))}
        </Then>
        <Else>
          <tr className="table-td-data">
            <td> 
              <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                  <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </div>
            </td>
          </tr>
        </Else>
      </If>
    );
  };
  return (
    <div>
      <DynamicEntriesOnly 
        entriesValue={updateEntries} 
        originPage="COERequests"
      />
      <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={COEData()}
        originPage="COERequests"
        data={props.coes}
        location={props.location}
        paginationPath={`${props.match.path}?page=${props.match.params.page}`}
        entriesItem={entriesValue}
      />
    </div>
  );
};

const COEPage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";
  const [breadCrumbs, setBreadCrumbs] = React.useState([]);

  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          { label: "COE Requests", link: `/admin/coe-requests?page=1` },
        ]);
        break;
      case "review":
        setBreadCrumbs([{
            label: "COE Requests",
            link: `/admin/coe-requests?page=1`,
          },
          { label: "Review", link: "#" },
        ]);
        break;
      default:
        // Error page
        break;
    }
  };

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);

  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={
          props.mode === "review"
            ? `Review | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : props.mode === "list"
            ? `Requests | COE | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : ""
        }
      >
        <PageWrapper className={cc(["COEPage", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage="Requests"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <SwitchCase>
            <Case condition={["list"].includes(props.mode)}>
              <ContentWrapper>
                <COERequestList {...props} />
              </ContentWrapper>
            </Case>
            <Case condition={["review"].includes(props.mode)}>
              <UpdateCOERequest {...props} />
            </Case>
          </SwitchCase>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});
COEPage.WrappedComponent.displayName = "COEPage";
const COEPageRoute = (props) => (
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <COEPage {...props} mode="list" />}
    />
    <Route
      exact
      path={`${props.match.path}/review/:page/:requestId`}
      render={(props) => <COEPage {...props} mode="review" />}
    />
  </Switch>
);
export default React.memo(COEPageRoute);

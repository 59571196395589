//npm packages
import React from 'react';
import moment from 'moment';
import sweetAlert from 'sweetalert2';
import Swal from 'sweetalert2';
import { useDispatch} from "react-redux";
//services
import { challengeService } from '../_core/services/challenge';
//images
import avatar from '../_templates/man.png'
import imgNotFound from '../_templates/img-not-found.png';
import calendarIcon from '../_templates/calendar.png';
import { statsService } from '../_core/services/stats';
import { energyActions } from '../_core/actions/energy';
import { challengeActions } from "../_core/actions/challenge";
import  ChallengeUpdate from "./ChallengeUpdate";

const toast = sweetAlert.mixin({
	toast: true,
	width: 300,
	position: 'center',
	showConfirmButton: false,
	timer: 1500
});

export const DisplayChallenge = (props) => {
    const [challenge, setChallenge] = React.useState(props.getChallenge);
    const [timeUp, setTimeUp] = React.useState(false);
    const [stats, setStats] = React.useState({});
    const [showAppModal, setAppModal] = React.useState(false);// eslint-disable-lin
    const [update, setUpdate] = React.useState(false);
    const dispatch = useDispatch();

    // const [isOpen, setIsOpen] = React.useState(false);
    const user = JSON.parse(localStorage.getItem('userCredentials'));
    const secret = JSON.parse(localStorage.getItem('secretCredentials'));

    const currentUserId = user && user.user._id;

    const today = moment(new Date());
    const validExpDate = moment(challenge.expiresAt).isValid() ? challenge.expiresAt : false;
    const isAccepted = challenge.status.toLowerCase() === 'accepted' ? true : false;
    const isFinished = challenge.status.toLowerCase() === 'finished' ? true : false;
    const isCompleted = challenge.status.toLowerCase() === 'completed' ? true : false;
    const acceptedDate = moment(challenge.acceptedAt).isValid() ? moment(challenge.acceptedAt).utc() : false;
    const expiredDate = moment(challenge.expiresAt).isValid() ? moment(challenge.expiresAt).utc() : false;
    const getTimeLimitString = challenge.timeLimit !== '00:00:00' || !challenge.timeLimit ? challenge.timeLimit : false;
    
    const setEndDate = () => { //get accepted date + time limit
        var getTimer;
        if(getTimeLimitString){
            if(isAccepted && acceptedDate){
                const hms_split = getTimeLimitString.split(':');
                const getTimePerSec = (+hms_split[0]) * 60 * 60 + (+hms_split[1]) * 60 + (+hms_split[2]);
                const computedEndTime = moment(acceptedDate).add(getTimePerSec, 'seconds');
                getTimer = computedEndTime;
            }else{
                getTimer = getTimeLimitString;
            }
        }else{
            getTimer = false;
        }
        return getTimer
    }

    //check expiresAt date expiration, returns text
    const getExpDate = () => {
        const currentTime = moment.utc(today).valueOf();
        //const dateTime = moment.utc(expiredDate).valueOf();
        const dateTime = moment.utc(expiredDate, 'MMMM D, YYYY - hh:mm:ss').valueOf();
        
        let getDate = expiredDate ?
            moment(currentTime).startOf('day').isSameOrAfter(moment(dateTime).endOf('day')) ?
                moment(expiredDate).utc().format('MMMM D, YYYY - hh:mm:ss') + ' Expired'
                : 'Expires ' + moment(expiredDate).utc().format('MMMM D, YYYY - hh:mm:ss') + ' PHT'
            : 'No date';

        return getDate
    };


    //get challenge image else challenge title if no image
    const challengeImage = (challenge.images ?
        <div className="img-overlay">
        <div></div>
        <img className="challenger-avatar" onError={(e)=> e.target.src = avatar} src={challenge.posterDetails[0].avatar || avatar} alt="..." />
        <img className="card-img-top challenge-img" onError={(e)=> e.target.src = imgNotFound} src={challenge.images || imgNotFound} alt="..." />
        {getTimeLimitString &&
        <p className={isAccepted ? "challenge-time eng-time-on" : "challenge-time"} id={"ch_timer" + challenge._id}>
            {getTimeLimitString || ''}
        </p>
        }
        </div>
        : <div className="challenge-no-images">
            <img className="challenger-avatar-no-img" onError={(e)=> e.target.src = avatar} src={challenge.posterDetails[0].avatar || avatar} alt="..." />
            <h6 className="challenge-no-image-title">{challenge.title || ''}</h6>
        </div>
    );
    
    //get challenge description, if no image include timelimit
    const displayTimeNoImage = () => {
        var displayTime = '';
        if(getTimeLimitString){
            if(!challenge.images){
                displayTime = challenge.timeLimit;
            }
        };

        return(
            <p className="card-text">
                <span className={isAccepted ? 'no-img-time-on' : ''} id={"ch_timer" + challenge._id}>
                    {displayTime}
                </span>
                {getTimeLimitString && !challenge.images && ' - '}
                {challenge.description || ''}
            </p>
        );
    };

    // const updateBtnDdown = React.useRef();
    
    // const toggleDropdown = (e)=>{
    //     if(updateBtnDdown.current && updateBtnDdown.current.contains(e.target)){
    //         setIsOpen(state => !state);
    //         return
    //     }
    //     setIsOpen(state => false);
    // }
    
    // React.useEffect(() => {
    //     document.addEventListener("mousedown", toggleDropdown);
    //     return () => {
    //       document.removeEventListener("mousedown", toggleDropdown);
    //     };
    // }, []);

    const handleChallenge = () => {
        setAppModal(true);
    }
    const handleCloseModal =()=> {
        setAppModal(false)
    }


    React.useEffect(()=>{
        try{
            statsService.getOneUser(currentUserId).then(res=>{
                setStats(res.success);
            }).catch(error=>{});
        }catch(err){
            console.log(err);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[setStats, currentUserId]);


    var interval = React.useRef(false);
    var isSetEndDate = setEndDate();

    React.useEffect(() => {
        if(getTimeLimitString){
            interval.current = setInterval(()=> {
                if (isAccepted) {
                    if(acceptedDate && currentUserId === challenge.userDetails[0]._id){
                        var now = moment().utc();
                        var hours = parseInt(moment.duration(moment(isSetEndDate).diff(now)).asHours());
                        var minutes = parseInt(moment.duration(moment(isSetEndDate).diff(now)).asMinutes()) % 60;
                        var seconds = parseInt(moment.duration(moment(isSetEndDate).diff(now)).asSeconds()) % 60;

                        const countdown = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');
                        document.getElementById("ch_timer" + challenge._id).innerHTML = countdown

                        if (moment(now).isSameOrAfter(moment(isSetEndDate))) {
                            setTimeUp(true)
                            clearInterval(interval.current);
                            document.getElementById("ch_timer" + challenge._id).innerHTML = "00:00:00";
                        }
                    }else{
                        clearInterval(interval.current);
                        //moment(now).isSameOrAfter(moment(expiredDate))
                        if(expiredDate && moment(now).startOf('day').isSameOrAfter(moment(expiredDate))){
                            document.getElementById("ch_timer" + challenge._id).innerHTML = '00:00:00';
                        }else{
                            document.getElementById("ch_timer" + challenge._id).innerHTML = getTimeLimitString;
                        }
                    }
                }else{
                    clearInterval(interval.current);
                    //moment(now).isSameOrAfter(moment(expiredDate))
                    if(expiredDate && moment(now).startOf('day').isSameOrAfter(moment(expiredDate))){
                        document.getElementById("ch_timer" + challenge._id).innerHTML = '00:00:00';
                    }else{
                        document.getElementById("ch_timer" + challenge._id).innerHTML = getTimeLimitString;
                    }
                }
            }, 1000)
        }
        return () => clearInterval(interval.current);
    }, [challenge, expiredDate, getTimeLimitString, isSetEndDate, acceptedDate, isAccepted, currentUserId]);

    const acceptChallenge = () =>{
        const userEnergy = Array.isArray(stats) && stats.map(name => name.tips);

        if(currentUserId === challenge.originalPoster){
            Swal.fire({
                title: "Oops",
                html:
                    "<br><b class='energy-text'>You cannot accept your own challenge.</b>",
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#ff0000',
                cancelButtonColor: '#d33',
                background: '#ffff',
            })
        }
        
        if(challenge.eneryValue <= userEnergy[0]) {
            const energyInfo = {
                add: true,
                userId: currentUserId,
                energyType: "challenge",
                value:  -challenge.eneryValue
            }
            
            challengeService.acceptChallenge(challenge._id).then(res=> {

                if(res.success){
                    var details  = res.success.details;
                    setChallenge({...challenge, status: details.status, acceptedAt: details.acceptedAt, user: currentUserId});
                    dispatch(energyActions.update(energyInfo));
                    toast.fire({
                        type: 'success',
                        title: `challenge accepted`
                    });
                    setTimeout(function(){
                        dispatch(challengeActions.getAll(1, 100000));}
                    , 2000);
                    setUpdate(true)//if user accept the challenge
                }else{
                    toast.fire({
                        type: 'error',
                        title: `challenge not accepted`
                    });
                }
            
            })
        }else{
            Swal.fire({
                title: "Insufficient Energy",
                html:
                    "<br><b class='energy-text'>Required Energy:" + challenge.eneryValue + "</b>",
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#ff0000',
                cancelButtonColor: '#d33',
                background: '#ffff',
            })
        }  
    }

    // get assigned button
    var displayButton = () => {
        const challengerName = challenge.userDetails[0].firstName + ' ' + challenge.userDetails[0].lastName;
        if(getExpDate().includes('Expired')){
            if(isAccepted){
                if(currentUserId === challenge.userDetails[0]._id){
                    return (
                        <button className="eng-ongoing-btn eng-text-center">
                            <p><b>FAILED - CHALLENGE TIME LIMIT IS UP</b></p>
                        </button>
                    );
                }else{
                    return (
                        <button className="eng-ongoing-btn">
                            <div className="on-challenge-profile">
                                <div>
                                    <img className="on-challenge-avatar" src={challenge.userDetails[0].avatar || avatar} alt="..." />
                                </div>
                                <div className="on-challenge-name">
                                    <p className="challenger-text">Challenger:</p>
                                    <p className="on-challenge-name">{challengerName}</p>
                                </div>
                            </div>
                            <div className="challenger-active">
                                <div className="on-challenger-active"></div>
                            </div>
                        </button>
                    );
                }
            }else{
                return(
                    <button className="eng-ongoing-btn eng-text-center">
                        <p><b>CHALLENGE HAS ALREADY EXPIRED</b>{challenge.status === 'completed' ? " (COMPLETED)" : challenge.status === 'finished' ? " (FINISHED)" : ''}</p>
                    </button>
                );
            }
        }else{
            if (isAccepted) {

                if (currentUserId === challenge.userDetails[0]._id) {
                    if(moment(today).isSameOrAfter(moment(isSetEndDate)) || timeUp){
                        return (
                            <button className="eng-ongoing-btn eng-text-center">
                                <p><b>FAILED - CHALLENGE TIME LIMIT IS UP</b></p>
                            </button>
                        );
                    }else{
                        return (
                            <div className="ch-update">
                                <button className="btn eng-update-btn"onClick={handleChallenge}>Update</button>
                                    <ChallengeUpdate
                                        isOpen={showAppModal} 
                                        onRequestClose={() => setAppModal(false)} 
                                        toggle={setAppModal}
                                        handleCloseModal={handleCloseModal}
                                        challengeId={challenge._id}
                                        challengeTitle={challenge.title}
                                    />
                            </div>
                        )
                    }
                }else if(currentUserId !== challenge.userDetails[0]._id && update !== true){
                    return(
                        <button className="eng-ongoing-btn">
                            <div className="on-challenge-profile">
                                <div>
                                    <img className="on-challenge-avatar" src={challenge.userDetails[0].avatar || avatar} alt="..." />
                                </div>
                                <div className="on-challenge-name">
                                    <p className="challenger-text">Challenger:</p>
                                    <p className="on-challenge-name">{challengerName}</p>
                                </div>
                            </div>
                            <div className="challenger-active">
                                <div className="on-challenger-active"></div>
                            </div>
                        </button>
                    );
                }
            }  else if(challenge.userDetails[0]._id === currentUserId && isFinished){
                return(
                    <button className="btn eng-finish-btn" style={{backgroundColor:"#D2534D", cursor:'default'}}>
                        <p>Finished</p>
                    </button>
                );
            }else if(isCompleted){
                return(
                    <button className="btn eng-finish-btn" style={{backgroundColor:"#006C0B", cursor:'default'}}>
                        <p>Completed</p>
                    </button>
                );
            }else if(challenge.userDetails[0]._id !== currentUserId && isFinished){
                return(
                    <button className="eng-ongoing-btn">
                        <div className="on-challenge-profile">
                            <div>
                                <img className="on-challenge-avatar" src={challenge.userDetails[0].avatar || avatar} alt="..." />
                            </div>
                            <div className="on-challenge-name">
                                <p className="challenger-text">Challenger:</p>
                                <p className="on-challenge-name">{challengerName}</p>
                            </div>
                        </div>
                        <div className="challenger-active">
                            <div className="on-challenger-active"></div>
                        </div>
                    </button>
                );
            } else if(secret !== null){
                return (<button className="btn eng-challenge-btn" style={{opacity:'0.7',pointerEvents:'none'}}>Accept</button>);
            }else{
                return (<button className="btn eng-challenge-btn" onClick={()=>acceptChallenge()}>Accept</button>);
            }
        }
    }

    const challengeDateCreated = moment(challenge.createdAt).utc().fromNow().replace('ago', '');
    const challengePosterName = challenge.posterDetails[0].firstName ? challenge.posterDetails[0].firstName + ' ' + challenge.posterDetails[0].lastName : ' No name specified';

    //RETURN CARD DISPLAY
    return (
        <div key={challenge._id} 
            className={currentUserId === challenge.userDetails[0]._id && isAccepted ? 
                getExpDate().includes('Expired') ? 
                    "card challenge-card eng-update-expd-card" 
                    :"card challenge-card eng-update-card" 
                : "card challenge-card"}
            // id set as reference for global search
            id={"phrChallenge" + challenge._id}
        >
            
            {challengeImage}
            <div className="card-body challenge-details">
                {validExpDate &&
                <p className="challenge-expires">
                    <img src={calendarIcon} alt="..." />
                    <span className={getExpDate().includes('Expired') ? 'expired-date' : ''}>
                        {getExpDate()}
                    </span>
                </p>
                }
                {challenge.images ? <h6 className="card-title">{challenge.title || ''}</h6> : ''}
                {displayTimeNoImage()}
                {displayButton()}
                <br />
                <i><small>Posted <b>{challengeDateCreated}</b> ago by
                <b> {challengePosterName}</b></small></i>
            </div>
        </div>
    );
}
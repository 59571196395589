import { settingsConstants } from '../constants/settings';

let initialState = {
  items: [],
  loading: true,
  error: null,
  totalPerPage: 0,
  perPage: 0,
  currentPage: 0,
}

export function settings( state = initialState, action) {
  switch (action.type) {
    case settingsConstants.CREATE_REQUEST:
      return { creating: true };
    case settingsConstants.CREATE_SUCCESS:
      return {};
    case settingsConstants.CREATE_FAILURE:
      return {};
    default:
    return state
  }
}
import React, { useEffect, useState } from "react";
import cc from "classcat";
import DocumentTitle from "react-document-title";
import { connect, useDispatch} from "react-redux";
import { checkSideNav } from "../../Components/checkSideMenu";
import { Route, Switch, useParams } from "react-router-dom";
import BodyBackgroundColor from "react-body-backgroundcolor";
import moment from "moment";
import queryString from 'query-string';
import { Switch as SwitchCase, Case, Default } from 'react-if';
import { history } from "../../../_core/helpers/history"
import EditorComponent from '../../Components/Editor/EditorComponent';
import styled from "styled-components";
import { convertToRaw } from 'draft-js';

import { tenuresServices } from "../../../_core/services/tenures";
import { tenuresAction } from "../../../_core/actions/tenures";

import {
  PageWrapper,
  ContentWrapper,
  LabelCustom,
  SaveAndCancelButton,
  InputTextCustom,
} from "../../Components/PageWrapper/ContentWrapper"; 

import { StaticDropdownTenure } from '../../Components/DynamicDropdown/DynamicDropdown';
import {
  DynamicHeaderContent,
  DynamicContentHeaderWrapper,
  DynamicTableAndPaginationWrapper,
} from "../../Components/PageWrapper/DynamicWrapper";

import { DynamicTableAction } from "../../Components/tableActions/tableActions"

const PeriodDropdownWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;



const CreateAndEditTenures = (props) => {
  const dispatch = useDispatch();
  const [tenure, setTenure] = React.useState({
    name: "",
    description: "",
    period: 0,
  });
  const [dates] = useState([ 
    { value: 'Days', label: 'Days' },
    { value: 'Months', label: 'Months' },
    { value: 'Years', label: 'Years' }
  ])
  const [numberDates, setnumberDates] = useState([])

  const [datesValue, setdatesValue] = useState([{value: "Days", label: "Days"}])
  const [numberDatesValue, setnumberDatesValue] = useState({label: 1, value: 86400})

  const [isAdd, setIsAdd] = React.useState(true); 

  const [inputError, setInputError] = React.useState(false);

  const { pageId } = useParams(); //get id in url

  useEffect(() => {
    if (pageId) {
      setIsAdd(false);
      tenuresServices.getOne(pageId).then((res) => {
        if(res.success !== undefined) {
          let data = res.success[0];
      
          setTenure({
            ...tenure,
            name: data.name,
            description: data.description,
            period: filterPeriod(data.period),
          })
        }
      });
    }
    //eslint-disable-next-line
  }, [props, pageId]);



  const submitTenure = (e) => {
    if (!tenure.name || !tenure.description) {
      setInputError(true);
    } else {
      if (isAdd) {
        dispatch(tenuresAction.addTenure(tenure));
      } else {
        dispatch(tenuresAction.editTenure(pageId, tenure));
      }
    }
  }
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTenure({
      ...tenure,
      [name]: value,
    });
  };

  const getContent = (content) => {
    const isEditorStateEmpty = content ? !content.getCurrentContent().hasText() : true;

    setTenure({
      ...tenure,
      ["description"]: isEditorStateEmpty ? "" : JSON.stringify(convertToRaw(content.getCurrentContent())), // eslint-disable-line
    });
  };

  const handleChangeDropdownDate = (data) => {
    setdatesValue(data);
    filterNumberDates(data.value)
  } 
  const handleChangeDropdownDateNumber = (data) => {
    setnumberDatesValue(data);
  } 

  const filterValue = (date, indexValue) => {
    let value = 0;
    let daySeconds = 86400;
    let MonthSeconds30Days = 2592000;
    let yearSeconds = MonthSeconds30Days * 12; // Total: 32140800

    switch (date) {
      case "Days":
        value = daySeconds * indexValue;
        return value;
      case "Months":
        value = MonthSeconds30Days * indexValue;
        return value;
      case "Years":
        value = yearSeconds * indexValue;
        return value;
      default:
        break;
    }
  };
  const filterNumberDates = (date) => {
    let loopBy = 0;
    let dateNumber = [];
    switch (date) {
      case "Days":
        loopBy = 29;
        break;
      case "Months":
        loopBy = 11;
        break;
      case "Years":
        loopBy = 50;
        break;
      default:
        break;
    }
    let index = date === "Days" ? 0 : 1;
    for (index; index <= loopBy; index++) {
      dateNumber.push({ label: index, value: filterValue(date, index) }); // Ex { label: 1, value: 86400 }
    }
    setnumberDates(dateNumber);
  };

  const filterPeriod = (period) => {
    let daySeconds = 86400;
    let MonthSeconds30Days = 2592000;
    let yearSeconds = MonthSeconds30Days * 12; // Total: 32140800

    if(period >= daySeconds && MonthSeconds30Days > period) {
      let secToDays = Math.floor(period / daySeconds);
      let DayNumber =  Math.ceil(secToDays);
      setdatesValue({ value: "Days", label: "Days" });
      setnumberDatesValue({ label: DayNumber, value: period });
      filterNumberDates("Days");
      return period;
    }
    if(period > daySeconds && period >= MonthSeconds30Days && period < yearSeconds) {
      let secondToMonths = Math.floor(period / MonthSeconds30Days);
      let monthNumber = secondToMonths
      setdatesValue({ label: 'Months', value: "Months" })
      setnumberDatesValue({ label: monthNumber, value: period });
      filterNumberDates("Months");  
      return period;
    }
    if(period >= yearSeconds ){
      let secondToYear = Math.trunc(period / yearSeconds);
      let yearNumber = secondToYear
      setdatesValue({ label: 'Years', value: "Years" })
      setnumberDatesValue({ label: yearNumber, value: period });
      filterNumberDates("Years");
      return period;
    }
  }



  useEffect(() => {
    // handleDatesChange 
    let dateLabel = datesValue?.label;
    
    setnumberDatesValue({
      label: 1,
      value:
        dateLabel === "Days"
          ? 86400
          : dateLabel === "Months"
          ? 2592000
          : dateLabel === "Years"
          ? 32140800
          : 0,
    });
  }, [datesValue]);

  useEffect(() => {
    // handleChange period
    setTenure({
      ...tenure,
      ['period']: numberDatesValue.value, // eslint-disable-line
    })
      // eslint-disable-next-line
  }, [numberDatesValue])
 

  useEffect(() => {
    if (props.mode === "create") {
      // Prepopulate date number dropdown
      filterNumberDates("Days");
    }
    // eslint-disable-next-line
  }, []);


   return (
     <>
       <LabelCustom className="main-label">
         {isAdd ? "Create" : "Edit"} a Tenure
       </LabelCustom>
       <InputTextCustom
        placeholder={"Title"}
        name={"name"}
        value={tenure.name}
        inputOnChange={handleChange}
        isError={!tenure.name && inputError}
      />
       <LabelCustom className="sub-label" style={{marginBottom: "5px"}}>Description</LabelCustom>
       <EditorComponent
         returnContent={getContent}
         initialValue={tenure.description}
         isEdit={!isAdd}
         isError={!tenure.description && inputError}
       />
       <LabelCustom className="sub-label">Period</LabelCustom>
       <PeriodDropdownWrapper>
         <StaticDropdownTenure 
         handleChangeDropdownDatesNumber={(e) => handleChangeDropdownDateNumber(e)}  options1={numberDates} value1={numberDatesValue}  placeholder1="Select Number"  
         handleChangeDropdownDates={(e) => handleChangeDropdownDate(e)} options2={dates}  value2={datesValue} placeholder2="Select Date" 
         />
       </PeriodDropdownWrapper>

       <SaveAndCancelButton
         cancelFn={() => history.push("/admin/tenures?page=1")}
         saveFn={submitTenure}
       />
     </>
   );
}

const TenuresList = (props) => {
  const dispatch = useDispatch();
  const [entriesValues, setentriesValues] = useState(); // eslint-disable-line

  const UrlActivePage = React.useMemo(() => {
		return parseInt(queryString.parse(props.location.search).page);
	}, [props.location]);

  const editPermission = JSON.parse(localStorage.getItem('editPermission'));
  const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
  const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

  let tableHeader = (editPermission.includes("Appraisal - Tenures") === true) || (deletePermission.includes("Appraisal - Tenures") === true) || (roleAllTrue === 1) ?  ["Title", "Period", "Updated", "Actions"] : ["Title", "Period", "Updated", ""];

  const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

  //showEntries
  const entriesValue = (entries) => {
		setentriesValues(entries);
	}

  const computePeriod = (periodSeconds, startDate) => {
    let daySeconds = 86400;
    let MonthSeconds30Days = 2592000;
    let yearSeconds = MonthSeconds30Days * 12 ; // Total: 32140800

    if (periodSeconds < daySeconds) {
      // get Days or immediate
      return 0 + " Day";
    }
    if (periodSeconds >= daySeconds && MonthSeconds30Days > periodSeconds) {
      // get 1 to 29 days
      let secToDays = Math.floor(periodSeconds / daySeconds);
      return Math.ceil(secToDays) + " " + (Math.ceil(secToDays) === 1 ? "Day" : "Days");
    }

    if(periodSeconds > daySeconds && periodSeconds >= MonthSeconds30Days && periodSeconds < yearSeconds) {
      // get Months to 11 months
      let secondToMonths = Math.floor(periodSeconds / MonthSeconds30Days);
      return secondToMonths + " " + (secondToMonths === 1 ? "Month" : "Months");
    }
    if (periodSeconds >= yearSeconds) {
      // get Years
      let secondToYear = Math.trunc(periodSeconds / yearSeconds);
      return secondToYear + " " + (secondToYear === 1 ? "Year" : "Years");
    }
  };

  const editTenure =(item) => {
    history.push(`/admin/tenures/${item._id}/edit`);
  }

  const deleteTenure = (id) => {
    let totalPerPage = 10;
    dispatch(tenuresAction.removeTenure(id, UrlActivePage, totalPerPage));
  }

  const TenuresData = () => {
    let data = props.tenures.tenuresList ?? [];

    return (
      <>
        {data.length !== 0 ? (
          data.map((item) => (
            <tr key={item._id}>
              <td style={{ width: "700px" }}>{item.name}</td>
              <td>{computePeriod(item.period, item.createdAt)}</td>
              <td>{moment(item.updatedAt || item.createdAt ).fromNow()}</td>
              {(editPermission.includes("Appraisal - Tenures") === true) || (deletePermission.includes("Appraisal - Tenures") === true) || (roleAllTrue === 1) ?
                <td className={secretDetails !== null ? "disabled-link" : '' }>
                  <div>
                    <DynamicTableAction
                      originPage="Tenures"
                      iconSet="2"
                      editLabel="Edit Tenures"
                      deleteLabel="Delete Tenures"
                      onClickEdit={(e) => editTenure(item)}
                      onClickDelete={(e) => deleteTenure(item._id)}
                      deletePermission="Appraisal - Tenures"
                      editPermission="Appraisal - Tenures"
                    />
                  </div>
                </td> : false
              }
            </tr>
          ))
        ) : (
          <tr className="table-td-data">
            <td> 
              <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                  <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </div>
            </td>
          </tr>
        )}
      </>
    );
  };

  return (
    <>
      <DynamicContentHeaderWrapper
        buttonLabel="ADD TENURE"
        entriesValue={entriesValue}
        btnPath="/admin/tenures/create"
        originPage="Tenures"
        addPage="Appraisal - Tenures"
      />
      <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={TenuresData()}
        originPage="Tenures"
        data={props?.tenures}
        paginationPath={"/admin/tenures"}
        location={props?.location}
        entriesItem={entriesValues}
      />
    </>
  );
};

const mapStateToProps = ({ tenures }) => ({
  tenures,
});

const TenuresPage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";
  const [breadCrumbs, setBreadCrumbs] = React.useState([]);

  const filterBreadCrumbs = (mode) => {
    if (mode === "list") {
      setBreadCrumbs([
        { label: "Tenures", link: "/admin/tenures?page=1" },
      ]);
    } else {
      let createBreadCrumbs = [
        { label: "Tenures", link: "/admin/tenures?page=1" },
        { label: "Create Tenure", link: "/admin/tenures/create" },
      ]
      let editBreadCrumbs = [
        { label: "Tenures", link: "/admin/tenures?page=1" },
        { label: "Edit Tenure", link: "#" },
      ]
      setBreadCrumbs(mode === "create" ? createBreadCrumbs : editBreadCrumbs);
    }
  };

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);

  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={
          props.mode === "create"
            ? `Create Tenure | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : props.mode === "list"
            ? `Appraisal | Tenures | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : `Edit Tenures | ${process.env.REACT_APP_DOCUMENT_TITLE}`
        }
      >
        <PageWrapper className={cc(["TenuresPage", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage="Appraisal"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <ContentWrapper>
          <SwitchCase>
              <Case condition={['create', 'edit'].includes(props.mode)}>
                <CreateAndEditTenures {...props}/>
              </Case>
              <Default>
              <TenuresList {...props} />
              </Default>
            </SwitchCase>
            
          </ContentWrapper>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});

TenuresPage.WrappedComponent.displayName = "TenuresPage";
const TenuresPageRoute = (props) => (
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <TenuresPage {...props} mode="list" />}
    />
    <Route
      exact
      path={`${props.match.path}/create`}
      render={(props) => <TenuresPage {...props} mode="create" />}
    />
    <Route
      exact
      path={`${props.match.path}/:pageId/edit`}
      render={(props) => <TenuresPage {...props} mode="edit" />}
    />
  </Switch>
);
export default React.memo(TenuresPageRoute);

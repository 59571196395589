import { get, post, put, del } from '../helpers/api';
import { userService } from '../services/user';

export const challengeService = {
    create,
    getAll,
    getAllFilter,
    acceptChallenge,
    getById,
    getOne,
    addCredit,
    updateChallenge,
    createChallenge,
    quitChallenge,
    finishChallenge,
    completeChallenge,
    delete: _delete,
    resetChallenge,
    deletChallenge,
    publishChallenge,
    unpublishChallenge,
    getAllAdmin,
    revertChallenge,
    completeOneChallenge,
    getAllFilterSearch,
    getAlllAcceptedChallenge
};

function getAll(page, itemsPerPage) {
     return get(`/api/v1/challenge?page=${page || 1}&items-per-page=${itemsPerPage || 20}&filter=active:1`).then(handleResponse);
}

function getAllAdmin(page, itemsPerPage, search, filter = "") {
    let fields = `${filter ==='title' ? "title:" + search : filter === 'description' ?"description:" + search : ""}`
    return get(`/api/v1/challenge?page=${page || 1}&items-per-page=${itemsPerPage || 20}&search=${search}&filter=${fields}`).then(handleResponse);
}

function getAllFilter(page, itemsPerPage) {
    var user = JSON.parse(localStorage.getItem('userCredentials'));
    var secret = JSON.parse(localStorage.getItem('secretCredentials'));
    var id = secret ? secret._id : user.user._id;
    return get(`/api/v1/challenge?page=${page || 1}&items-per-page=${itemsPerPage || 20}&filter=originalPoster:${id}`).then(handleResponse);
}
function create(challenge){
    let userID = challenge.userId;
    return post(`/api/v1/user/${userID}/challenge`, JSON.stringify(challenge))
    .then(handleResponse)
}

function createChallenge(challenge) {

    function isFile(input) {
        if ('File' in window && input instanceof File)
           return true;
        else return false;
     }
    
     const imageFile = challenge.images;
     const fd = new FormData();
     fd.append('file',imageFile)

    // //checking if post images has a content file
    if(isFile(challenge.images) === true){
        challenge.images = null;
    }

    return post('/api/v1/challenge', JSON.stringify(challenge))
    .then(handleResponse)
    .then(response => {
        if(isFile(imageFile) === true){
            imageUploadChallenge(response._id,fd);
        }   
    });
}


function imageUploadChallenge(id , image){
    return post(`/api/v1/upload/${id}/challenge`, image).then(handleResponse);
}


function getById(challenge) {
    let userID = challenge.userId;
    return get(`/api/v1/user/${userID}/challenge`).then(handleResponse);
}

function getOne(id) {
    return get(`/api/v1/challenge/${id}`).then(handleResponse);
}

function updateChallenge(id, challenge){
    
    function isFile(input) {
        if ('File' in window && input instanceof File)
           return true;
        else return false;
     }
    
    const fd = new FormData();
    const imageFile = challenge.images;
    fd.append('file',imageFile)
    
    //checking if challenge images has a content file
    if(isFile(challenge.images) === true){
        challenge.images = null;
    }

    return put(`/api/v1/challenge/${id}`, JSON.stringify(challenge)).then(handleResponse)
    .then(response => {
        imageUploadChallenge(id,fd);
    });
}

function acceptChallenge(challenge) {
    let id = challenge
    return put(`/api/v1/challenge/${id}/accept`).then(handleResponse)
}

function quitChallenge(challenge) {
   let userID = challenge.user_id;
   let challengeid = challenge.challengeId
   return put(`/api/v1/user/${userID}/challenge/${challengeid}/quit`, JSON.stringify(challenge))
       .then(handleResponse)
}

function finishChallenge(challenge,files) {

    function isFile(input) {
        if ('File' in window && input instanceof File)
           return true;
        else return false;
     }
    
     let id = challenge
     const imageFile = files;
     const fd = new FormData();
     fd.append('file',imageFile)
    
    // //checking if post images has a content file
    if(isFile(files) === true){
        files = null;
    }

    return put(`/api/v1/challenge/${id}/finish`).then(handleResponse)
    .then(response => {
        if(isFile(imageFile) === true){
            finishUploadChallenge(id,fd);
        }
    });
 }

 function revertChallenge(challenge) {
    return put(`/api/v1/challenge/${challenge}/default`).then(handleResponse)
 }

 function completeChallenge(challenge) {
    let userID = challenge.user_id;
    let challengeid = challenge.challengeId
    return put(`/api/v1/user/${userID}/challenge/${challengeid}/complete`, JSON.stringify(challenge))
        .then(handleResponse)
 }

 function completeOneChallenge(challenge) {
    return put(`/api/v1/challenge/${challenge}/complete`).then(handleResponse)
 }


 function resetChallenge(challenge) {
    let userID = challenge.user_id;
    let challengeid = challenge.challengeId
    return put(`/api/v1/user/${userID}/challenge/${challengeid}/reset`, JSON.stringify(challenge))
        .then(handleResponse)
 }

 function deletChallenge(id){
    return del(`/api/v1/challenge/${id}`).then(handleResponse)
}

function addCredit(id, credit) {
    return post(`/api/v1/user/${id}/credit`, JSON.stringify(credit)).then(handleResponse);
}

function finishUploadChallenge(id , image){
    return post(`/api/v1/upload/${id}/finishChallenge`, image).then(handleResponse);
}

function getAllFilterSearch(page = 1, itemsPerPage = 20, search, filter = "") {
    let fields = `${filter ==='title' ? "content:" + search : filter === 'title' ?"content:" + search : ""}`

    return get(`/api/v1/challenge?page=${page}&items-per-page=${itemsPerPage}&search=${search}&filter=${fields}`).then(handleResponse);
}

function getAlllAcceptedChallenge(page, itemsPerPage) {
    var user = JSON.parse(localStorage.getItem('userCredentials'));
    var secret = JSON.parse(localStorage.getItem('secretCredentials'));
    var id = secret ? secret._id : user.user._id;
    return get(`/api/v1/challenge?page=${page || 1}&items-per-page=${itemsPerPage || 20}&filter=acceptedUser:${id}`).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function publishChallenge(id) {
    return put(`/api/v1/challenge/${id}/publish`).then(handleResponse);
}
function unpublishChallenge(id) {
    return put(`/api/v1/challenge/${id}/unpublish`).then(handleResponse);
}

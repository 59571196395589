import React from 'react'
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import DocumentTitle from "react-document-title";
import cc from "classcat";
import BodyBackgroundColor from "react-body-backgroundcolor";
import {
  PageWrapper,
  ContentWrapper,
  LabelCustom,
  SaveAndCancelButton,
} from "../../Components/PageWrapper/ContentWrapper";
import { checkSideNav } from "../../Components/checkSideMenu";
import { DynamicHeaderContent} from "../../Components/PageWrapper/DynamicWrapper";
import './settingsDark.scss';
import ImageUploadModal from './ImageUploadModal';
import { CustomInputText, CustomTextAreaEngage,} from "../../Components/FloatingTextBox/TextArea";
import avatarIcon from '../../../_templates/settings-profile.png';
import coverIcon from '../../../_templates/settings-cover.png';
import { history } from "../../../_core/helpers/history";
import { settingsActions } from '../../../_core/actions/settings';

export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;

const PhotoBorder = (props) => {
  return (
      <div className="dashed-border">
          <div><div><div><div>{props.children}</div></div></div></div>
      </div>
  )
}

const SettingsDark = () => {
  const ContentPageBackgroundColor = "#1C2233";
  const [isProfileImage, setIsProfileImage] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [avatarSrc, setAvatarSrc] = React.useState(null);
  const [coverSrc, setCoverSrc] = React.useState(null);
  const [avatarUpload, setAvatarUpload] = React.useState(null);
  const [coverUpload, setCoverUpload] = React.useState(null);
  // const [count, setCount] = React.useState(0);
  const [InputError, setInputError] = React.useState(false);// eslint-disable-line
  const dispatch = useDispatch();

  const [settingsData, setSettingData] = React.useState({
    org_name: '',
    org_slug: '',
    org_avatar: '',
    org_cover: '',
    org_description: '',
    org_address: ''
  })
  console.log(settingsData)

  const handleChangeInputName = (e) => {
    const { name, value } = e.target;

    setSettingData({
      ...settingsData,
      [name]: value,
      // org_slug: value.replace(/\s+/g,'-').toLowerCase()
      org_slug: value.replace(/[&\/\\#,+()$~%.'":*?<>{}^!@_`=|;\[\]]/g,'').toLowerCase().replace(/\s/g,'-')//eslint-disable-line
      
    });

  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    // if(name === "org_description"){
    //     setCount(value.length)
    // }

    setSettingData({
        ...settingsData,
        [name]: value,
      });

  }

  const toggleModal = (img) => {
    setIsProfileImage(img === 'profile' ? true : false);
    setOpenModal(!openModal);
  }

  const draftImageUpload = (src, file) => {

    console.log(src)
    if (isProfileImage) {
        setAvatarSrc(src);
        setAvatarUpload(file);

    } else {
        setCoverSrc(src);
        setCoverUpload(file);
    }
  }

  const cancelAnnouncement = () => {
    history.goBack()
    setInputError(false);
  }

  const submit = () => {
    if( !settingsData.org_name ||
        !settingsData.org_description ||
        !settingsData.org_address ){
      setInputError(true);
    }else {
        let newsettingsData = {
          org_name: settingsData.org_name,
          org_slug: settingsData.org_slug,
          org_avatar: settingsData.org_avatar,
          org_cover: settingsData.org_cover,
          org_description: settingsData.org_description,
          org_address: settingsData.org_address
        }
        dispatch(settingsActions.create(newsettingsData,avatarUpload,coverUpload));
        setTimeout(() => {
          window.location.reload(true);   
      }, 1500)
    }
  }

  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={ `Organization Settings`}
      >
        <PageWrapper className={cc(["BadgesPage", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage=" Settings"
            // breadCrumbs={false}
            // breadCrumbsPages={breadCrumbs}
          />
          <ContentWrapper>
            <LabelCustom className="main-label">
              Organization Settings
            </LabelCustom>

            <CustomInputText
              label={"Organization Name"}
              name="org_name"
              value={settingsData.org_name}
              inputOnChange={handleChangeInputName}
              isError={!settingsData.org_name && InputError}
            />
            {/* <div className="test">
              <div className="titleSwitch-edit">
                <p className="switch-one">{settingsData.org_slug ? settingsData.org_slug : "Organization Slug"} </p>
              </div>
            </div> */}
            <CustomInputText
              label={"Organization Slug"}
              name="org_slug"
              value={settingsData.org_slug}
              readOnly={true}
              className={"test"}
              isError={!settingsData.org_description && InputError}
            />
            <div className="settings-images-wrapper">
              <div>
                <p>Organization logo</p>
                <div className="settings-logo-wrapper">
                  <PhotoBorder>
                    <div className="image-wrapper">
                      {/* <img src={avatarSrc} alt="avatar" /> */}
                      <img src={avatarSrc || avatarIcon} alt="avatar" onError={(e) => e.target.src = avatarIcon}/>
                      
                    </div>
                  </PhotoBorder>
                </div>
                <button className="settings-add-profile-btn" onClick={() => toggleModal('profile')} >UPLOAD LOGO</button>
              </div>
              <div>
                <p>Organization cover</p>
                <div className="settings-cover-wrapper">
                  <PhotoBorder>
                    <div className="image-wrapper">
                      {/* <img src={avatarSrc} alt="avatar" /> */}
                      <img src={coverSrc || coverIcon} alt="avatar" onError={(e) => e.target.src = coverIcon} />
                    </div>
                  </PhotoBorder>
                </div>
                <button className="settings-add-cover-btn" onClick={() => toggleModal('cover')}>UPLOAD ORGANIZATION COVER</button>
              </div>
            </div>
            <ImageUploadModal
                isOpen={openModal}
                closeModal={() => setOpenModal(false)}
                modalTitle={isProfileImage ? "Change profile photo" : "Change Cover Photo"}
                savedImage={(imgSrc, imgFile) => draftImageUpload(imgSrc, imgFile)}
                isProfilePhoto={isProfileImage}
            />
            <CustomTextAreaEngage
              label={"Organization Description"}
              name={"org_description"}
              value={settingsData.org_description}
              inputOnChange={handleChangeInput}
              maxlength="250"
              // isError={!reward.content && InputError}
              isError={!settingsData.org_description && InputError}
            />
            <CustomInputText
              label={"Organization Address"}
              name="org_address"
              value={settingsData.org_address}
              inputOnChange={handleChangeInput}
              isError={!settingsData.org_address && InputError}
            />
            <SaveAndCancelButton
                cancelFn={() => cancelAnnouncement()}
                saveFn={() => submit()}
                submitLabel="SAVE"
            />
          </ContentWrapper>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  )
}

export default SettingsDark;
import React from "react";
import cc from "classcat";
import { connect } from "react-redux";
import { checkSideNav } from "../../Components/checkSideMenu";
import { Route, Switch } from "react-router-dom";
import { If, Else, Then, Switch as SwitchCase, Case } from "react-if";
import { Link } from "react-router-dom";
import BodyBackgroundColor from "react-body-backgroundcolor";
import LinesEllipsis from "react-lines-ellipsis";
import moment from "moment";
import queryString from "query-string";
import DocumentTitle from "react-document-title";
import {
  PageWrapper,
  ContentWrapper,
} from "../../Components/PageWrapper/ContentWrapper";
import {
  DynamicHeaderContent,
  DynamicTableAndPaginationWrapper,
  DynamicEntriesOnly,
} from "../../Components/PageWrapper/DynamicWrapper";
import OvertimeRequestUpdate from "./UpdateOvertimeDark";
import "./MainOvertimeDark.scss";

const mapStateToProps = ({ overtimes }) => ({
  overtimes,
});

const ListOvertime = (props) => {
  const [entriesValue, setentriesValue] = React.useState();

  const editPermission = JSON.parse(localStorage.getItem('editPermission'));
  const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

  const updateEntries = (entries) => {
    setentriesValue(entries);
  };

  let tableHeader = (editPermission.includes("Requests - Overtime Requests") === true) || (roleAllTrue === 1) ? [
    "Reference Code",
    "From",
    "To",
    "Reason",
    "Submitted",
    "Date Approved",
    "Status",
    "Actions"
  ] : [
    "Reference Code",
    "From",
    "To",
    "Reason",
    "Submitted",
    "Date Approved",
    "Status",
    ""
  ];

  const ActivePage = Number(queryString.parse(props.location.search).page) || 1
  
  const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));
  
  const OTRequestData = () => {
    return (
      <If condition={props.overtimes.loading === false}>
        <Then>
          {props.overtimes.loading === false &&
            props.overtimes.overtimes.items.map((overtime) => (
              <tr key={overtime._id}>
                <td style={{ width: "10%" }}>
                  {overtime.refCode ? overtime.refCode : "No Code"}
                </td>
                <td style={{ width: "10%" }}>
                  {moment(overtime.startedAt).utc().format("MMM DD, YYYY")}{" "}
                </td>
                <td style={{ width: "10%" }}>
                  {moment(overtime.endedAt).utc().format("MMM DD, YYYY")}{" "}
                </td>
                <td style={{ width: "20%" }}>
                  <LinesEllipsis
                    text={
                      overtime.description ? overtime.description : "no reason"
                    }
                    maxLine="1"
                    ellipsis="..."
                    trimRight
                    basedOn="words"
                  />
                </td>
                <td style={{ width: "10%" }}>
                  {moment(overtime.createdAt).fromNow()}
                </td>
                <td style={{ width: "10%" }}>
                  {overtime.updatedAt
                    ? moment(overtime.updatedAt).fromNow()
                    : "Pending"}
                </td>
                <td
                  style={{ width: "10%", textTransform: "capitalize" }}
                  className={
                    overtime.status === "on hold" ? "onHold" : overtime.status
                  }
                >
                  {overtime.status
                    ? overtime.status === "new"
                      ? "In review"
                      : overtime.status
                    : "none"}
                </td>
                {(editPermission.includes("Requests - Overtime Requests") === true) || (roleAllTrue === 1) ?
                <td
                  style={{ width: "10%", textAlign: "end" }}
                  className={secretDetails !== null ? "disabled-link action-tbl" : 'action-tbl' }
                >
                  <Link
                    to={`/admin/overtime-requests/review/${ActivePage}/${overtime._id}`}
                  >
                    <button id="review">
                      REVIEW
                    </button>
                  </Link>
                </td> : false 
                }
                
              </tr>
            ))}
        </Then>
        <Else>
          <tr className="table-td-data">
            <td> 
              <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                  <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </div>
            </td>
          </tr>
        </Else>
      </If>
    );
  };
  return (
    <div>
      <DynamicEntriesOnly 
        entriesValue={updateEntries} 
        originPage="OvertimeRequests"
      />
      <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={OTRequestData()}
        originPage="OvertimeRequests"
        data={props.overtimes}
        location={props.location}
        paginationPath={`${props.match.path}?page=${props.match.params.page}`}
        entriesItem={entriesValue}
      />
    </div>
  );
};

const ListOvertimePage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";
  const [breadCrumbs, setBreadCrumbs] = React.useState([]);

  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          {
            label: "Overtime Requests",
            link: `/admin/overtime-requests?page=1`,
          },
        ]);
        break;
      case "review":
        setBreadCrumbs([
          {
            label: "Overtime Requests",
            link: `/admin/overtime-requests?page=1`,
          },
          { label: "Review", link: "#" },
        ]);
        break;
      default:
        // Error page
        break;
    }
  };

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);

  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={
          props.mode === "review"
            ? `Review | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : props.mode === "list"
            ? `Requests | Overtime | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : ""
        }
      >
        <PageWrapper
          className={cc(["OvertimeRequestsPage", checkSideNav()?.trim()])}
        >
          <DynamicHeaderContent
            TitlePage="Requests"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <SwitchCase>
            <Case condition={["list"].includes(props.mode)}>
              <ContentWrapper>
                <ListOvertime {...props} />
              </ContentWrapper>
            </Case>
            <Case condition={["review"].includes(props.mode)}>
              <OvertimeRequestUpdate {...props} />
            </Case>
          </SwitchCase>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});
ListOvertimePage.WrappedComponent.displayName = "ListOvertimePage";
const ListOvertimePageRoute = (props) => (
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <ListOvertimePage {...props} mode="list" />}
    />
    <Route
      exact
      path={`${props.match.path}/review/:page/:requestId`}
      render={(props) => <ListOvertimePage {...props} mode="review" />}
    />
  </Switch>
);
export default React.memo(ListOvertimePageRoute);

import { settingsConstants } from '../constants/settings';
import { settingsService } from '../services/settings';
import Swal from 'sweetalert2';

export const settingsActions = {
    create,
};

function create(settings,logo,cover) {

    const ToastSuccess = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        position: 'center',
        showConfirmButton: false,
        timer: 1500
    });

    const Toast = Swal.mixin({
        toast: true,
        width: 300,
        
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });


    function failure(error) { return { type: settingsConstants.CREATE_FAILURE, error } }

    return async dispatch => {
        let isError = null;

        try {
            await settingsService.create(settings,logo,cover);
        } catch(error) {
            isError = error.toString();
            dispatch(failure(error.toString()));
            Toast.fire({
                type: 'error',
                title: `
                    <span>
                        Something went wrong11111
                    </span>
                `
            });

            return;
        }

        if(isError === null){
            await ToastSuccess.fire({
                type: 'success',
                title: '<span style="color:green">settings has been created! </span>'
            })
        }
    };
}
import React from 'react';
import moment from 'moment';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { If, Then, Else } from 'react-if';

import { statsService } from '../_core/services/stats';

import notAtWork from '../_templates/workstatus-icons/notatwork.png';
import atWork from '../_templates/workstatus-icons/atwork.png';
import onVacation from '../_templates/workstatus-icons/onvacation.png';
import medical from '../_templates/workstatus-icons/medical.png';
import restDay from '../_templates/workstatus-icons/onrestday.png';
import holiday from '../_templates/workstatus-icons/onholiday.png';

import './WorkStatusWidget.scss';


const WorkstatusWidget = (props) => {

   
    const [status, setWorkStatus] = React.useState({});
	const [loading, setLoading] = React.useState(true);

    React.useEffect(()=>{
		if(props.userId){
			try{
				setLoading(true);
				statsService.getWorkStatus(props.userId).then((res)=>{
					setWorkStatus(res)
				}).catch((error)=>{})
				.finally(() => {
					// Set loading to false after data is fetched
					setLoading(false);
				});
			}catch(err){
				console.log(err);
			}
		}
	},[props.userId]);

	
    const currentPragin = (status.data?.[0]?.prags?.filter((t,index) => index === 0)[0]) ?? {};

    return (
        <div className="status widget">
           <div className="header">
                <If condition={loading}>
                    <Then>
                        <SkeletonTheme color="rgb(225, 225, 225)" highlightColor="rgb(240, 240, 240)">
                            <Skeleton width={150} height={17} />
                        </SkeletonTheme>
                    </Then>
                    <Else>
                        <h6>Work Status</h6>
                    </Else>
                </If>
                <div style={{ height: 40 }} />
            </div>

            <div className="body card z-depth-0">
                <If condition = {status.data?.[0]?.status === "At work"}>
					<Then>
						<div className="people-status">
							<img alt="" src={atWork}/>

								<p>{currentPragin.pragOutAt !== null? "Went Off From Work At : "  : "At work Since : "} 
									<span>
										{ currentPragin.pragOutAt !== null ?
											moment(currentPragin.pragOutAt).format('hh:mm A') :
											moment(currentPragin.pragInAt).format('hh:mm A')
										}
									</span>
								</p>
							    
						</div>
					</Then>
				</If>
                <If condition={status.status === "Not at work."}>
						<Then>
                            <div className="people-status">
								<img alt="" src={notAtWork}/>
								<p>Not at work</p>
							</div>
						</Then>
					</If>

					<If condition = {status.status === "Not registered." || status.status === "No pragger username."}>
						<Then>
                            <div className="people-status">
								<img alt="" src={notAtWork}/>
								<p>Not registered on Pragger</p>
							</div>
						</Then>
					</If>

					<If condition = {status.status === "On Vacation Leave."}>
						<Then>
                            <div className="people-status">
								<img alt="" src={onVacation}/>
								<p>On Vacation Leave</p>
							</div>
						</Then>
					</If>

					<If condition = {status.status === "Medical Leave."}>
						<Then>
                            <div className="people-status">
								<img alt="" src={medical}/>
								<p>On Medical Leave</p>
							</div>
						</Then>
					</If>

					<If condition = {status.status === "On a Rest Day."}>
						<Then>
                            <div className="people-status">
								<img alt="" src={restDay}/>
								<p>On a Rest Day</p>
							</div>
						</Then>
					</If>

					<If condition = {status.status === "On Holiday."}>
						<Then>
                            <div className="people-status">
								<img alt="" src={holiday}/>
								<p>On Holiday</p>
							</div>
						</Then>
					</If>
            </div>
        </div>
    )
};

export default WorkstatusWidget;

import { get, post, put } from '../helpers/api';
import { userService } from '../services/user';

export const notifService = {
    getNotif,
    getNotifByPage, 
    createNotif,
    updateNotif 
};


function getNotif(){
    return get(`/api/v1/notification`).then(handleResponse)
}

function getNotifByPage(page){
    const pg = page ? page : 1;
    return get(`/api/v1/notification?page=${pg}&items-per-page=20`).then(handleResponse)
}

function createNotif(notification){
    return post(`/api/v1/notification`, JSON.stringify(notification)).then(handleResponse);
}

function updateNotif(id, notification){
    return put(`/api/v1/notification/${id}`, JSON.stringify(notification)).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}
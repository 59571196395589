import { post } from '../helpers/api';
import { userService } from '../services/user';

export const settingsService = {
    create,
    uploadAvatar,
    uploadCover

};


function create(createSettings,logo,cover){

    return post(`/api/v1/settings`, JSON.stringify(createSettings))
    .then(handleResponse)
    .then(response => {
        if(logo !== null){
            uploadAvatar(logo,response._id);
        }
        if(cover !== null){
            uploadCover(cover,response._id);
        }
       
    });
}

function uploadAvatar(avatar, id){
  return post(`/api/v1/upload/${id}/orgAvatar`, avatar).then(handleResponse);
}
function uploadCover(cover, id){
  return post(`/api/v1/upload/${id}/orgCover`, cover).then(handleResponse);
}


function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
          if (response.status === 401) {
              // auto logout if 401 response returned from api
              userService.logout();
              //location.reload(true);
          }
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);

      }
      return data;
  });
}
